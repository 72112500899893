import { Box, Icon } from "@chakra-ui/react";
import { MdOutlineClose } from "react-icons/md";

export const ButtonIcon = ({ iconAs = MdOutlineClose, ...props }) => (
  <Box
    as="button"
    borderRadius="full"
    boxSize="8"
    transition="background-color 0.1s ease"
    _hover={{ bg: "themegray.200" }}
    _focus={{
      outline: "solid 2px",
      outlineColor: "themegray.1500",
      outlineOffset: 3,
      bg: "themegray.200",
    }}
    color="primary.base"
    d="flex"
    alignItems="center"
    justifyContent="center"
    fontSize="3xl"
    {...props}
  >
    <Icon as={iconAs} />
  </Box>
);
