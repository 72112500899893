import { Heading, Stack, Text } from "@chakra-ui/react";
import { IllustrationEmptyStateContributionsSaved } from "../../1_Atoms";

export const EmptyStateContributionsSaved = () => (
  <>
    <IllustrationEmptyStateContributionsSaved
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        Save important contributions
      </Heading>
      <Text fontSize={{ base: "0.9rem", md: "1rem" }}>
        You haven&#8217;t saved any contributions, don&#8217;t delay!
      </Text>
    </Stack>
  </>
);
