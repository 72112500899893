import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { wrapperVariants } from "./framerVariants";

export const FormSuccessWrapper = ({ children }) => (
  <Box
    d="flex"
    flexDir="column"
    alignItems="center"
    gap={6}
    as={motion.div}
    variants={wrapperVariants}
    initial="initial"
    animate="enter"
    exit="exit"
  >
    {children}
  </Box>
);
