import { Box } from "@chakra-ui/react";

export const PageSignInHeaderWrapper = ({ children }) => (
  <Box
    d="flex"
    w="full"
    maxW="container.xl"
    flexDir={{ base: "column-reverse", md: "row" }}
    alignItems="center"
    gap={4}
    justifyContent={{ md: "space-between" }}
    p={4}
  >
    {children}
  </Box>
);
