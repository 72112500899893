import { config, validationDictionary } from "..";

export const isValidDob = ({ dobDay, dobMonth, dobYear }) => {
  if (!dobDay || !dobMonth || !dobYear)
    return validationDictionary.DOB_REQUIRED;

  const age = getAge(new Date(dobYear, dobMonth - 1, dobDay));

  if (age < config.minimumAge) return validationDictionary.NOT_OLD_ENOUGH;

  return true;
};

const getAge = (date) => {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const month = today.getMonth() - date.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
    age--;
  }

  return age;
};
