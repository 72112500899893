import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import {
  LoaderSection,
  PageTitle,
  useProfile,
  useGenderList,
} from "../../";
import { FormControlSelect } from "../../../ui";

export const PageAccountGender = () => {
  const { data, error, updateProfile, patchStatus } = useProfile();
  const genders = useGenderList();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  useEffect(() => {
    if (data) {
      const { genderId } = data;
      reset({
        genderId: genderId.toString(),
      });
    }
  }, [data, genders.data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Gender" />
      <LoaderSection
        loading={!data || !genders.data}
        error={error || genders.error}
      >
        <Box p="1.5rem">
          {data && data?.genderId && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing="2rem">
                <Stack>
                  <FormControlSelect
                    {...register("genderId", {
                      required: "Gender is required",
                    })}
                    loading={genders.isLoading}
                    error={errors.genderId?.message}
                    isInvalid={errors.genderId?.message}
                    label="Gender"
                    defaultValue={data.genderId}
                  >
                    <option value="" disabled>
                      Select gender
                    </option>
                    {genders.data?.map(({ id, title }, i) => (
                      <option key={i} value={id}>
                        {title}
                      </option>
                    ))}
                  </FormControlSelect>
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || patchStatus.isLoading}
                >
                  {patchStatus.isLoading && "Loading"}
                  {!isDirty && !patchStatus.isLoading && "Up to date"}
                  {isDirty && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
