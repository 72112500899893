import { Box } from "@chakra-ui/react";

export const HeaderContainer = ({ children, height }) => (
  <Box
    maxW="container.xl"
    mx="auto"
    d="flex"
    justifyContent="space-between"
    alignItems="center"
    px={6}
    {...{ height }}
  >
    {children}
  </Box>
);
