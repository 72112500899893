import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonBaseSpinnerWrapper = ({ key, children }) => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate="show"
    initial="hide"
    exit="exit"
    position="absolute"
    d="flex"
    alignItems="center"
    justifyContent="center"
    {...{ children, key }}
  />
);
