import { Box, Image, Link } from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { ButtonIcon, LogoPrimaryWithTagline } from "../../1_Atoms";
import { HeaderContainer, HeaderWrapper } from "./ui";
import { Link as RLink } from "react-router-dom";

export const Header = ({ height, handleOpenDrawerClick, partners }) => (
  <HeaderWrapper>
    <HeaderContainer {...{ height }}>
      <RLink to="/">
        <LogoPrimaryWithTagline w={{ base: "230px", lg: "270px" }} />
      </RLink>
      <ButtonIcon
        fontSize="2xl"
        iconAs={GiHamburgerMenu}
        onClick={handleOpenDrawerClick}
      />
    </HeaderContainer>
    <Box
      pos="absolute"
      top="0"
      justifyContent="center"
      alignItems="center"
      flexGrow="1"
      inset="0"
      pointerEvents="none"
      d={{ base: "none", md: "flex" }}
    >
      {partners?.data?.map((item) => {
        return (
          <Link key={item?.URL} pointerEvents="visible" target="blank" href={item?.URL}>
            <Image w="180px" alt={item?.title} src={item?.logoURL} />
          </Link>
        );
      })}
    </Box>
  </HeaderWrapper>
);
