import { HStack, Text } from "@chakra-ui/react";
import { Avatar, NotificationBase } from "../../1_Atoms";

export const NotificationAuthor = ({
  message,
  avatarName,
  avatarSrc,
}) => (
  <NotificationBase>
    <HStack>
      <Avatar size='sm' name={avatarName} src={avatarSrc} />
      <Text>{message}</Text>
    </HStack>
  </NotificationBase>
);
