import { Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import {
  NavDrawerButtonDescription,
  NavDrawerButtonName,
  NavDrawerButtonWrapper,
} from "./ui";

const TYPES = { internalLink: NavLink, externalLink: "a", button: "button" };

export const NavDrawerButton = ({ name, type, description, ...props }) => {
  return (
    <Box w="full" as={TYPES[type]} {...props}>
      <NavDrawerButtonWrapper>
        <NavDrawerButtonName {...{ name }} />
        <NavDrawerButtonDescription {...{ description }} />
      </NavDrawerButtonWrapper>
    </Box>
  );
};
