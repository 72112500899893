import { FilterDropdownsWrapper } from "./ui";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { FaCheckCircle, FaFilter } from "react-icons/fa";

export const FilterDropdowns = ({ filterDropdowns, onChange }) => (
  <FilterDropdownsWrapper>
    <Menu closeOnSelect={false}>
      <MenuButton
        bg="white"
        size="sm"
        border="solid 1px"
        borderColor="themegray.400"
        as={Button}
        leftIcon={<FaFilter fontSize="1rem" />}
      >
        Filter
      </MenuButton>
      <MenuList maxH="325px" overflowY="scroll">
        {filterDropdowns?.map((item) => (
          <MenuOptionGroup
            key={item?.name}
            value={item?.value}
            defaultValue={item.value}
            title={item.label}
            type="radio"
          >
            {item?.options.map((option) => (
              <MenuItemOption
                key={option.value}
                icon={<FaCheckCircle fontSize="1.3rem" />}
                onClick={() =>
                  onChange({ name: item.name, value: option.value })
                }
                value={option.value}
              >
                {option.name}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        ))}
      </MenuList>
    </Menu>
  </FilterDropdownsWrapper>
);
