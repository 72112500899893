import { Box } from "@chakra-ui/react";

export const TextBlock = ({ children }) => (
  <Box
    sx={{
      "* + *": { mt: 4 },
      h1: {
        fontSize: { base: "2xl", md: "3xl" },
        lineHeight: 1.3,
        mb: 6,
        fontWeight: "heading.bold",
        fontFamily: "heading",
      },
      h2: {
        fontSize: "xl",
        mt: 8,
        fontWeight: "heading.bold",
        fontFamily: "heading",
      },
      h3: {
        fontSize: "lg",
        mt: 6,
        fontWeight: "heading.bold",
        fontFamily: "heading",
      },
      p: { mt: 2, mb: 4 },
      a: {
        color: "primary.base",
        textDecor: "underline",
        fontWeight: "body.bold",
      },
      ul: { pl: "1.5em" },
    }}
  >
    {children}
  </Box>
);
