import { Box, Icon } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { BsBookmarkHeart } from "react-icons/bs";
import {
  ButtonSavePostSave,
  ButtonSavePostSaved,
  ButtonSavePostWrapper,
} from "./ui";

export const ButtonSavePost = ({ isSaved, isLoading, ...props }) => (
  <ButtonSavePostWrapper {...{ isSaved, isLoading }} {...props}>
    <Icon
      fontSize="xl"
      color={isSaved ? "white" : "gray.600"}
      as={BsBookmarkHeart}
    />
    <Box pos="relative">
      <ButtonSavePostSaved {...{ isSaved }} />
      {!isSaved && <ButtonSavePostSave />}
    </Box>
  </ButtonSavePostWrapper>
);

ButtonSavePost.propTypes = {
  isSaved: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
