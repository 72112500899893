import { MenuList } from "@chakra-ui/react";

export const MediaSourceDropdownMenuList = ({ children }) => (
  <MenuList
    bg="rgba(0,0,0,0.8)"
    color="white"
    borderColor="black"
    {...{ children }}
  />
);
