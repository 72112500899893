import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonFollowFollow = () => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate="show"
    initial="hide"
    exit="exit"
    position="absolute"
    d="flex"
    color='primary.base'
  >
    Follow
  </Box>
);