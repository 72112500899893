import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { PageTitle, AccountAvatarUpload, LoaderSection } from "../..";
import { useProfile } from "../../_Hooks";

export const PageAccountAvatar = () => {
  const profile = useProfile();
  const variant = useBreakpointValue({
    lg: "desktop",
  });

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle title="Avatar" goBack={variant !== "desktop"} />
      <LoaderSection loading={profile.isLoading} error={profile.error}>
        <AccountAvatarUpload />
      </LoaderSection>
    </Box>
  );
};
