import { Text } from "@chakra-ui/react";

export const FormError = ({ error, ...props }) => (
  <Text
    as="span"
    pl="0.2rem"
    textTransform="uppercase"
    fontSize="0.7rem"
    fontWeight="body.bold"
    lineHeight="1"
    color="red.500"
    {...props}
  >
    {error}
  </Text>
);
