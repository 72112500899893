import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { CgMoreVerticalAlt } from "react-icons/cg";

export const CardContributionMenu = ({ menuItems }) => (
  <Menu>
    <MenuButton
      as={Button}
      boxSize={8}
      borderRadius="full"
      p={0}
      minW="unset"
      bg="none"
      _hover={{ bg: "themegray.300" }}
    >
      <Box d="flex" alignItems="center" justifyContent="center">
        <CgMoreVerticalAlt fontSize={24} />
      </Box>
    </MenuButton>
    <MenuList>
      {menuItems?.map((item) => (
        <MenuItem key={item.name} onClick={item.handle}>{item.name}</MenuItem>
      ))}
    </MenuList>
  </Menu>
);
