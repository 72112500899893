import { Box } from "@chakra-ui/react";
import { FaMicrophone } from "react-icons/fa";
import { MediaSourceDropdown } from "../../../2_Molecules";

export const DictaphoneMicSelect = ({
  micOptions,
  onMicSelectChange,
  micValue,
}) => (
  <Box pos="absolute" zIndex="2" right={4} top={4}>
    <MediaSourceDropdown
      options={micOptions}
      onChange={onMicSelectChange}
      value={micValue}
      iconButton={<FaMicrophone />}
    />
  </Box>
);
