import { Box, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Card } from "../../../../../1_Atoms";
import { CardAuthor } from "../../../../../2_Molecules";
import { CardTitle } from "./ui";

export const CardContributionNestedCall = ({
  title,
  author,
  handleVerifiedClicked,
  children,
  to,
}) => (
  <Card maxW="500px" w="100%" p={4}>
    <Stack spacing={4}>
      <CardAuthor {...author} {...{ handleVerifiedClicked }} />
      <Link to={to}>
        <CardTitle {...{ title }} />
      </Link>
      <Box>{children}</Box>
    </Stack>
  </Card>
);
