import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import { CardBody, CardCallToAction } from "../../../../1_Atoms";

export const PageContactTechHelp = () => (
  <CardCallToAction>
    <CardBody>
      <Stack spacing="1rem">
        <Heading fontSize="1.2rem">Technical help</Heading>
        <Text>
          If you have any technical questions or need any help or support with
          the Colloquy platform, please email us at:{" "}
          <Link fontWeight="body.bold" href="mailto:info@colloquy.health">
            info@colloquy.health
          </Link>
        </Text>
      </Stack>
    </CardBody>
  </CardCallToAction>
);
