import React from "react";
import { Box } from "@chakra-ui/react";

export const IllustrationThumbsUp = (props) => (
  <Box as="svg" viewBox="0 0 56.9 56.9" {...props}>
    <g id="b">
      <g id="c">
        <g>
          <rect className="d" fill="none" width="56.9" height="56.9" />
          <g>
            <path
              className="g"
              fill="#c68261"
              d="M51.84,27.55c0-1.74-1.41-3.16-3.16-3.16h-13.07c2.04-3.03,3.55-8.93,3.1-12.61-.12-.98-.76-1.59-1.8-1.88,0,0-1.66-.36-2.57,1.69-1.46,3.32-3.26,6.41-7.11,8.9-2.59,1.68-6.69,4.76-8.24,6.96-.22,.31-2.27,.61-2.37,.88v16.88c.33,.09,2.55,.18,2.9,.27,2.84,.71,6.37,1.21,9.77,1.27,.17,0,.34,0,.5,0l14.76,.25c1.85,0,3.36-1.5,3.36-3.36,0-.79-.27-1.51-.73-2.08,1.54-.31,2.7-1.66,2.7-3.29,0-.93-.38-1.77-.99-2.38,1.1-.55,1.85-1.68,1.85-3,0-.94-.39-1.79-1.02-2.4,1.22-.44,2.09-1.6,2.09-2.97"
            />
            <path
              className="f"
              fill="#8e5c36"
              d="M44.58,47.92l-14.78-.25c-.12,.02-.34,0-.52,0-3.3-.06-6.94-.54-9.96-1.3-.14-.03-.8-.07-1.25-.11-1.01-.07-1.44-.11-1.68-.17-.39-.11-.65-.46-.65-.87V28.34c0-.11,.02-.22,.06-.32,.22-.57,.79-.72,1.74-.97,.25-.07,.65-.17,.84-.25,1.8-2.45,6.09-5.58,8.39-7.06,3.51-2.27,5.25-5.04,6.78-8.5,.95-2.16,2.76-2.39,3.59-2.21,1.47,.41,2.34,1.35,2.5,2.65,.4,3.3-.7,8.4-2.43,11.82h11.5c2.24,0,4.06,1.82,4.06,4.06,0,1.3-.63,2.5-1.64,3.25,.37,.64,.56,1.36,.56,2.12,0,1.25-.54,2.41-1.46,3.2,.39,.65,.59,1.39,.59,2.17,0,1.61-.91,3.05-2.29,3.77,.21,.51,.31,1.05,.31,1.6,0,2.35-1.91,4.26-4.26,4.26Zm-27.04-3.51c.22,.02,.46,.03,.65,.05,.93,.07,1.33,.1,1.56,.16,2.9,.73,6.39,1.18,9.57,1.25,.17,0,.33,0,.49,0l14.78,.25c1.34,0,2.44-1.1,2.44-2.45,0-.56-.18-1.08-.53-1.52-.2-.25-.25-.58-.14-.87,.11-.29,.36-.51,.67-.57,1.14-.23,1.97-1.24,1.97-2.41,0-.66-.26-1.27-.72-1.74-.21-.21-.3-.5-.25-.78s.23-.53,.48-.66c.83-.42,1.35-1.26,1.35-2.19,0-.67-.26-1.29-.74-1.76-.22-.22-.32-.53-.25-.84,.07-.3,.28-.55,.58-.66,.89-.32,1.49-1.17,1.49-2.12,0-1.24-1.01-2.25-2.25-2.25h-13.07c-.33,0-.64-.18-.8-.48-.16-.29-.14-.65,.05-.93,1.96-2.91,3.37-8.62,2.96-12-.05-.38-.22-.86-1.14-1.12-.09,0-.94-.08-1.5,1.19-1.43,3.25-3.33,6.63-7.45,9.29-2.98,1.92-6.68,4.85-7.99,6.72-.29,.41-.8,.56-1.75,.81-.14,.04-.3,.08-.45,.12v15.5Z"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M4.15,44.84V27.39c0-1.59,1.29-2.88,2.88-2.88H15.64c1.59,0,2.88,1.29,2.88,2.88v17.45c0,1.59-1.29,2.88-2.88,2.88H7.03c-1.59,0-2.88-1.29-2.88-2.88"
            />
          </g>
        </g>
      </g>
    </g>
  </Box>
);
