import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import {
  LoaderSection,
  PageTitle,
  useCountryList,
  useProfile,
  useStateList,
} from "../../";
import { FormControlSelect } from "../../../ui";

export const PageAccountLocation = () => {
  const { data, error, updateProfile, patchStatus } = useProfile();
  const countryList = useCountryList();
  const stateList = useStateList();
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  const countryId = watch("countryId");

  useEffect(() => {
    if (data) {
      const { countryId } = data;
      reset({
        countryId: countryId.toString(),
      });
    }
  }, [data, countryList.data, reset]);

  useEffect(() => {
    if (countryId && countryId !== "187") setValue("stateId", "");
  }, [countryId, setValue]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Location" />
      <LoaderSection
        loading={!data || !countryList.data || !stateList.data}
        error={error || countryList.error || stateList.error}
      >
        <Box p="1.5rem">
          {data && data?.countryId && countryList.data && stateList.data && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing="2rem">
                <Stack>
                  <FormControlSelect
                    {...register("countryId", {
                      required: "Country is required",
                    })}
                    loading={countryList.loading}
                    error={errors.countryId?.message}
                    isInvalid={errors?.countryId?.message}
                    label="Country"
                    defaultValue={data.countryId}
                  >
                    {countryList.data?.map(({ id, title }, i) => (
                      <option key={i} value={id}>
                        {title}
                      </option>
                    ))}
                  </FormControlSelect>
                  {countryId === "187" && (
                    <FormControlSelect
                      {...register("stateId", {
                        required: "State is required",
                      })}
                      loading={stateList.loading}
                      error={errors.stateId?.message}
                      isInvalid={errors.stateId?.message}
                      label="State"
                      defaultValue={data.stateId}
                    >
                      <option value="" disabled>
                        Select state
                      </option>
                      {stateList.data?.map(({ id, state }, i) => (
                        <option key={i} value={id}>
                          {state}
                        </option>
                      ))}
                    </FormControlSelect>
                  )}
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || patchStatus.isLoading}
                >
                  {patchStatus.isLoading && "Loading"}
                  {!isDirty && !patchStatus.isLoading && "Up to date"}
                  {isDirty && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
