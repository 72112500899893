import { Box } from "@chakra-ui/react";
import { Badge } from "../../../1_Atoms";
import { AudioPlayer } from "../../AudioPlayer/AudioPlayer";

const COLORS = { call: "accentone.base", contribution: "accenttwo.base" };

export const CardAudioBlock = ({ variant = "call", audio }) => {
  if (!audio) return null;
  return (
    <Box pos="relative">
      <AudioPlayer {...audio} />
      <Badge
        bg={COLORS[variant]}
        pos="absolute"
        top="2"
        left="2"
        pointerEvents="none"
        zIndex='3'
      >
        {variant}
      </Badge>
    </Box>
  );
};
