import { FormSuccessTitle, FormSuccessMessage, FormSuccessWrapper } from "./ui";
import { ForgotPasswordMailIcon } from "./components";
import { Stack } from "@chakra-ui/react";

export const ForgotPasswordFormSuccess = ({ handleTryAgainClick }) => (
  <FormSuccessWrapper>
    <Stack alignItems="center">
      <ForgotPasswordMailIcon />
      <FormSuccessTitle />
    </Stack>
    <FormSuccessMessage {...{ handleTryAgainClick }} />
  </FormSuccessWrapper>
);
