import { LoaderSection, PageTitle } from "../../2_Molecules";
import { useContactDetails } from "../../_Hooks";
import {
  PageContactContentWrapper,
  PageContactText,
  PageContactWrapper,
} from "./ui";
import { PageContactMedicalHelp, PageContactTechHelp } from "./components";

export const PageContact = () => {
  const contactdetails = useContactDetails();
  return (
    <PageContactWrapper>
      <PageTitle title="Contact Us" />
      <LoaderSection loading={contactdetails.isLoading}>
        <PageContactContentWrapper>
          <PageContactText />
          <PageContactTechHelp />
          <PageContactMedicalHelp />
        </PageContactContentWrapper>
      </LoaderSection>
    </PageContactWrapper>
  );
};
