import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { wrapperVariant } from "./framerVariants";

export const AudioContributionPlaybackWrapper = ({ children, ...props }) => (
  <Box
    as={motion.div}
    variants={wrapperVariant}
    initial="hide"
    animate="show"
    exit="exit"
    flexGrow="1"
    pos="relative"
    borderRadius="lg"
    overflow="hidden"
    maxW="600px"
    mx="auto"
    {...{ children }}
    {...props}
  />
);
