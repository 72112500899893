import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  ListCardContributions,
  EmptyStateContributionsProfile,
  ListWithEmptyStatePrimary,
  ListClientInfiniteScroll,
} from "../../";

export const PageProfileContributions = () => {
  const { profile } = useOutletContext();
  return (
    <ListWithEmptyStatePrimary
      data={profile?.data?.myContribution}
      isLoading={profile?.isLoading}
      EmptyState={EmptyStateContributionsProfile}
    >
      <ListClientInfiniteScroll
        count={6}
        fullList={profile?.data?.myContributions}
      >
        {({ list }) => <ListCardContributions data={list} />}
      </ListClientInfiniteScroll>
    </ListWithEmptyStatePrimary>
  );
};
