import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import {
  useAudioSources,
  useAudioSteamCapture,
  useMakeMediaContribution,
} from "../../../../../_Hooks";
import {
  AudioContributionIntro,
  AudioContributionPlayback,
  AudioContributionRecord,
} from "./components";
import { AudioSourcePermission } from "./components/AudioContributionRecord/components/AudioSourcePermission/AudioSourcePermission";
import { AudioContributionWrapper } from "./ui";

const steps = {
  introduction: "INTRODUCTION",
  record: "RECORD",
  playback: "PLAYBACK",
};

export const AudioContribution = ({ closeModal, call }) => {
  const [step, setStep] = useState(steps.introduction);
  return (
    <AudioContributionWrapper>
      <AnimatePresence exitBeforeEnter>
        {step === steps.introduction && (
          <AudioContributionIntro
            key={steps.introduction}
            handleNextClick={() => setStep(steps.record)}
          />
        )}
        {step === steps.record && (
          <AudioRecordAndPlayback {...{ closeModal, call }} />
        )}
      </AnimatePresence>
    </AudioContributionWrapper>
  );
};

const AudioRecordAndPlayback = ({ closeModal, call }) => {
  const [step, setStep] = useState(steps.record);
  const audioCapture = useAudioSteamCapture();
  const audioSources = useAudioSources();
  const mediaContribution = useMakeMediaContribution();

  useEffect(() => {
    if (mediaContribution.contributionComplete) {
      closeModal();
    }
  }, [mediaContribution.contributionComplete]);

  return (
    <AudioSourcePermission>
      <AnimatePresence exitBeforeEnter>
        {step === steps.record && (
          <AudioContributionRecord
            key={steps.record}
            handleStopRecordingClick={() => {
              setStep(steps.playback);
              audioCapture.onStopClick();
            }}
            audioCapture={audioCapture}
            audioSources={audioSources}
          />
        )}
        {step === steps.playback && (
          <AudioContributionPlayback
            key={steps.playback}
            url={audioCapture?.url}
            type={audioCapture?.type}
            handleRedoRecordingClick={() => {
              setStep(steps.record);
              audioCapture.reset();
            }}
            isLoading={mediaContribution.contributionLoading}
            handleSaveRecordingClick={() => {
              mediaContribution.createAudioContribution(
                audioCapture.blob[0],
                audioCapture.type.filetype,
                call
              );
              // closeModal();
            }}
          />
        )}
      </AnimatePresence>
    </AudioSourcePermission>
  );
};
