import { getUserFromSessionOrLocalStorage } from "../../_Utils";
import { postloginBaseParams } from "./config";

export const postTextContribution = {
  query: (values) => ({
    url: "Contribution",
    params: postloginBaseParams,
    body: {
      ...values,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "POST",
  }),
  invalidatesTags: ["Profile", "Timeline", "Call"],
};

export const postMediaContribution = {
  query: (values) => ({
    url: "Contribution",
    params: postloginBaseParams,
    body: {
      ...values,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "POST",
  }),
  // invalidatesTags: ["Call"],
};

export const deleteContribution = {
  query: (contribution) => ({
    url: "Contribution",
    params: {
      ...postloginBaseParams,
      userId: getUserFromSessionOrLocalStorage().id,
      id: contribution.id,
    },
    method: "DELETE",
  }),
  invalidatesTags: ["Profile", "Timeline", "Call"],
};

export const reportContribution = {
  query: (contribution) => ({
    url: `Contribution/report/${contribution.id}`,
    params: {
      ...postloginBaseParams,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "PATCH",
  }),
};
