import { useCalls } from "../../_Hooks/useCalls";
import { LatestCallsSideBar as LatestCallsSideBarUi } from "../../../ui";

export const LatestCallsSideBar = () => {
  const calls = useCalls();
  const latestCalls = calls?.data?.slice(0, 3);
  return (
    <LatestCallsSideBarUi list={latestCalls} isLoading={calls?.isLoading} />
  );
};
