import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@chakra-ui/react";

export const ButtonRecord = ({ isRecording, ...props }) => {
  return (
    <Button
      variant="unstyled"
      boxSize="3.5rem"
      boxShadow="lg"
      border="solid 3px white"
      bg="rgba(0,0,0,0.1)"
      borderRadius="full"
      d="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box
        bg="red.500"
        boxSize={isRecording ? "40%" : "80%"}
        borderRadius={isRecording ? "0.2rem" : "50%"}
        transition="all 0.2s ease"
      />
    </Button>
  );
};

ButtonRecord.propTypes = {
  isRecording: PropTypes.bool,
};
