import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonSavePostSave = () => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate="show"
    // initial="hide"
    exit="exit"
    position="absolute"
    top='0'
  >
    Save
  </Box>
);
