import { memo, useCallback, useMemo } from "react";
import { HeroPerson } from "../../../../../../../ui";
import {
  useCountryList,
  useStateList,
  useUserFollowing,
  useVerifiedHandle,
} from "../../../../../../_Hooks";
import { getFormattedAddress } from "../../../../../../_Utils";

export const PagePersonHero = memo(({ author }) => {
  const countryList = useCountryList();
  const stateList = useStateList();
  const { handleVerifiedClicked } = useVerifiedHandle();
  const following = useUserFollowing();
  const isFollowing = following.isFollowing(author);

  const address = useMemo(
    () =>
      getFormattedAddress(
        stateList.getStateById(author?.stateId),
        countryList.getCountryById(author?.countryId)
      ),
    []
  );

  const handleFollowClick = useCallback(
    () => following.toggleFollow(author),
    [isFollowing]
  );

  if (countryList.isLoading || stateList.isLoading) return null;
  return (
    <HeroPerson
      {...author}
      {...{ address, isFollowing, handleFollowClick }}
      handleVerifiedClicked={(e) => {
        e.preventDefault();
        handleVerifiedClicked(author?.verified);
      }}
      followIsLoading={following.isLoading}
    />
  );
});
