import React from "react";
import { Stack } from "@chakra-ui/react";
import {
  LoaderSection,
  PageTitle,
  useAuthors,
  getAmbassadorsList,
  useGroup,
} from "../..";
import { AmbassadorCta, AmbassadorsList } from "./components";
import { PageAmbassadorsWrapper } from "./ui";

export const PageAmbassadors = () => {
  const authors = useAuthors();
  const group = useGroup();
  return (
    <PageAmbassadorsWrapper>
      <PageTitle title="Our Ambassadors" />
      <LoaderSection loading={authors.isLoading}>
        <Stack p="1.5rem" w="100%" mx="auto" spacing="2rem">
          <AmbassadorCta />
          <AmbassadorsList
            groupTitle={group?.data?.title}
            ambassadors={getAmbassadorsList(authors?.data)}
          />
        </Stack>
      </LoaderSection>
    </PageAmbassadorsWrapper>
  );
};
