import { Box } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { rgba } from "polished";
import { useEffect, useRef, useState } from "react";

export const VideoPreviewBar = ({
  recording,
  max = 0,
  maxReached = () => {},
}) => {
  const { colors } = useTheme();
  const [currentSecond, setCurrentSecond] = useState(0);
  const minutes = Math.floor(currentSecond / 60);
  const maxMinutes = Math.floor(max / 60);
  const maxSeconds = (max % 60).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  });

  const intervalRef = useRef();

  useEffect(() => {
    if (recording) {
      intervalRef.current = setInterval(() => {
        setCurrentSecond((prev) => prev + 1);
      }, 1000);
    }
    if (!recording) {
      clearInterval(intervalRef.current);
      setCurrentSecond(0);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [recording]);

  useEffect(() => {
    if (currentSecond >= max) {
      clearInterval(intervalRef.current);
      maxReached();
    }
  }, [currentSecond]);
  return (
    <Box
      bg={recording ? "red.500" : rgba(colors?.themegray?.base, 0.8)}
      color="white"
      w="115px"
      minH="31px"
      borderRadius="md"
      fontWeight="body.black"
      textTransform="uppercase"
      fontSize={recording ? "0.9rem" : "0.75rem"}
      d="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background-color 0.2s ease"
    >
      {recording
        ? `${minutes}:${(currentSecond % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
          })} / ${maxMinutes}:${maxSeconds}`
        : "Preview"}
    </Box>
  );
};
