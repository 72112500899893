import { Icon } from "@chakra-ui/react";
import { FaMicrophone } from "react-icons/fa";
import { ButtonContribute } from "../components";

export const ContributeButtonBarButtonAudio = ({
  handleAudioContributionClick,
}) => (
  <ButtonContribute
    flexGrow={1}
    onClick={handleAudioContributionClick}
    leftIcon={<Icon fontSize='xl' as={FaMicrophone} />}
  >
    Audio
  </ButtonContribute>
);
