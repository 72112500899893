import { Box } from "@chakra-ui/react";

export const NavDrawerButtonWrapper = ({ children }) => (
  <Box
    px={6}
    py={4}
    textAlign="left"
    _hover={{
      bg: "primary.200",
    }}
    _focus={{ bg: "primary.200" }}
    borderBottom="solid 1px"
    borderColor="gray.100"
  >
    {children}
  </Box>
);
