import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import {
  LayoutTwoColumn,
  PageTitle,
  AuthorSearch,
  useSavedContributions,
  ListCardContributions,
  ListWithEmptyStatePrimary,
  EmptyStateContributionsSaved,
  LatestCallsSideBar,
} from "..";

export const PageSaved = () => {
  const savedContributions = useSavedContributions();
  return (
    <LayoutTwoColumn>
      <Box w="100%" d="flex" flexDir="column">
        <PageTitle title="Saved Contributions" />
        <ListWithEmptyStatePrimary
          EmptyState={EmptyStateContributionsSaved}
          {...savedContributions}
        >
          <ListCardContributions {...savedContributions} />
        </ListWithEmptyStatePrimary>
      </Box>
      <Stack p="1.5rem" gap="6">
        <AuthorSearch />
        <LatestCallsSideBar />
      </Stack>
    </LayoutTwoColumn>
  );
};
