import { Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { MdVerified } from "react-icons/md";
import { ButtonDefault } from "../../../ui";

export const RepExplanation = ({ onClick, title }) => {
  return (
    <>
      <Icon
        fontSize="80px"
        mx="auto"
        mb="1rem"
        as={MdVerified}
        color="accenttwo.base"
      />
      <Stack spacing="1.5rem">
        <Stack spacing="1rem">
          <Heading fontSize="1.2rem" textAlign="center">
            {title}
          </Heading>
          <Text>
            The MDS Foundation (MDSF) is a global non-profit advocacy
            organization that for over 25 years has supported patients and their
            families as well as healthcare providers in the fields of MDS and
            its related diseases. Their vision is for every MDS patient to
            benefit from our initiatives and research as early as possible.
          </Text>
        </Stack>

        <ButtonDefault size="sm" mt="1rem" onClick={onClick}>
          Close
        </ButtonDefault>
      </Stack>
    </>
  );
};
