import { useEffect } from "react";
import { useNotifications } from "./useNotifications";

export const useBaseApi = ({ query, params = [] } = {}) => {
  const { data, error, ...rest } = query(...params);
  const { GENERIC_ERROR, addToast } = useNotifications();

  useEffect(() => {
    if (error) {
      addToast({
        action: GENERIC_ERROR,
        payload: error,
      });
    }
  }, [error, addToast, GENERIC_ERROR]);

  return { data, error, ...rest };
};
