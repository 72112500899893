import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ButtonTab } from "../../../../1_Atoms";
import { LoaderSection, PageTitle } from "../../../../2_Molecules";
import { useAuthor } from "../../../../_Hooks";
import { PagePersonHero } from "./components";

export const PagePersonMainSection = () => {
  const author = useAuthor();
  return (
    <LoaderSection loading={author.isFetching} error={author.error}>
      <PageTitle goBack={true} to="/people" title="User Profile" />
      <PagePersonHero author={author.data} />
      <Main {...{ author }} />
    </LoaderSection>
  );
};

const Main = ({ author }) => (
  <>
    <Flex>
      <ButtonTab to={author?.data?.to}>
        Contributions ({author.data?.contributionCount})
      </ButtonTab>
      <ButtonTab to="calls">Calls ({author.data?.callCount})</ButtonTab>
    </Flex>
    <Outlet context={{ author }} />
  </>
);
