import { id } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";

export const useAudioSteamCapture = () => {
  const mediaRecorder = useRef();
  const [recording, setRecording] = useState();
  const [chunks, setChunks] = useState([]);
  const [url, setUrl] = useState();
  const [stream, setStream] = useState();
  const [type, setType] = useState();
  const [deviceId, setDeviceId] = useState();
  const [blob, setBlob] = useState();

  useEffect(() => {
    const types = [
      { type: "audio/webm; codecs=opus", filetype: "webm" },
      { type: "video/mp4", filetype: "mp4" },
      { type: "audio/mp4", filetype: "mp4" },
    ];
    const t = types.filter(({ type }) => MediaRecorder.isTypeSupported(type));
    setType(t[0]);
  }, []);

  useEffect(() => {
    const setUp = async () => {
      try {
        const streamItem = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId },
        });
        mediaRecorder.current = new MediaRecorder(streamItem, {
          mimeType: type.type,
        });
        setStream(streamItem);
      } catch (error) {
        console.error(error);
      }
    };
    if (type) setUp();
  }, [deviceId, type]);

  const onStartClick = () => {
    setRecording(true);
    mediaRecorder.current.start();
    mediaRecorder.current.ondataavailable = function (e) {
      setChunks((prev) => [...prev, e.data]);
    };
  };

  const onStopClick = () => {
    setRecording(false);
    mediaRecorder.current.stop();
  };

  const reset = () => {
    setUrl();
    setBlob();
    setChunks([]);
  };

  useEffect(() => {
    if (chunks.length > 0) {
      setBlob(chunks);
      const blob = new Blob(chunks, { type: type.type });
      const url = window.URL.createObjectURL(blob);
      setChunks([]);
      setUrl(url);
    }
  }, [chunks]);

  return {
    onStartClick,
    onStopClick,
    recording,
    url,
    reset,
    stream,
    type,
    setDeviceId,
    chunks,
    blob,
  };
};
