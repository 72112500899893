import { Button } from "@chakra-ui/react";

export const ButtonPrimary = (props) => (
  <Button
    h="35px"
    textTransform="uppercase"
    fontSize="0.75rem"
    fontWeight="body.black"
    letterSpacing="0.1px"
    bg="themegray.200"
    _hover={{ bg: "themegray.400" }}
    {...props}
  />
);
