import React from "react";
import { NotificationAuthor } from "../../";

export const NotificationHelpedContribution = ({ contribution, helpful }) => {
  const { author } = contribution;
  const { displayName, firstName, avatarSrc } = author;
  return (
    <NotificationAuthor
      avatarName={displayName}
      avatarSrc={avatarSrc}
      message={`${firstName} was ${helpful?.title.toLowerCase()}`}
    />
  );
};
