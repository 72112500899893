import { Stack, Text } from "@chakra-ui/react";
import { Badge } from "../../../1_Atoms";

const COLORS = { call: "accentone.base", contribution: "accenttwo.base" };

export const CardTextBlock = ({ variant = "call", text }) => {
  if (!text) return null;
  return (
    <Stack bg="themegray.100" p={4} pos="relative">
      <Badge bg={COLORS[variant]} pointerEvents="none">
        {variant}
      </Badge>
      <Text sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
        {text}
      </Text>
    </Stack>
  );
};
