import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  ListCardCalls,
  EmptyStateCallsNotVerified,
  ListWithEmptyStatePrimary,
  EmptyStateCallsVerifiedProfile,
} from "../../";

export const PageProfileCalls = () => {
  const { profile } = useOutletContext();
  const EmptyState = profile.data?.verified
    ? EmptyStateCallsVerifiedProfile
    : EmptyStateCallsNotVerified;
  return (
    <ListWithEmptyStatePrimary
      data={profile?.data?.myCalls}
      isLoading={profile?.isLoading}
      EmptyState={EmptyState}
    >
      <ListCardCalls data={profile?.data?.myCalls} />
    </ListWithEmptyStatePrimary>
  );
};
