import { Box } from "@chakra-ui/react";
import { getFormattedCreatedAt } from "../../../../../../components";
import { CardAuthor } from "../../../../../2_Molecules";
import { CardContributionMenu } from "./components";

export const CardContributionHeader = ({
  author,
  menuItems,
  handleVerifiedClicked,
  updatedAt,
}) => (
  <Box p={4} d='flex' flexDir='column' gap={4}>
    <Box d="flex" justifyContent="space-between">
      <CardAuthor {...author} {...{ handleVerifiedClicked }} />
      <CardContributionMenu {...{ menuItems }} />
    </Box>
    <Box>{getFormattedCreatedAt(updatedAt)}</Box>
  </Box>
);
