import {
  AvatarUploadAvatar,
  AvatarUploadButtonChange,
  AvatarUploadButtonDelete,
  AvatarUploadButtonsWrapper,
  AvatarUploadWrapper,
} from "./ui";

export const AvatarPreviewAndButtons = ({
  src,
  name,
  isLoading,
  handleDeleteClick,
  handleFileChange,
  file,
}) => (
  <AvatarUploadWrapper>
    <AvatarUploadAvatar {...{ src, name }} />
    <AvatarUploadButtonsWrapper {...{ isLoading }}>
      <AvatarUploadButtonChange {...{ file, handleFileChange }} />
      <AvatarUploadButtonDelete {...{ handleDeleteClick, src }} />
    </AvatarUploadButtonsWrapper>
  </AvatarUploadWrapper>
);
