import { Heading, Stack } from "@chakra-ui/react";
import { IllustrationEmptyStateContributions } from "../../1_Atoms";

export const EmptyStateContributionsProfile = () => (
  <>
    <IllustrationEmptyStateContributions
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        You haven&#8217;t made a contribution
      </Heading>
    </Stack>
  </>
);
