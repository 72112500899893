import { Avatar } from "../../../../1_Atoms";

export const CardAuthorAvatar = ({ displayName, avatarSrc, children }) => (
  <Avatar
    name={displayName}
    src={avatarSrc}
    fontSize={{ base: "1.5rem" }}
    boxSize={{ base: "4rem", sm: "4.5rem", md: "5rem" }}
  >
    {children}
  </Avatar>
);
