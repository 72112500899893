import {
  getAuthorIsMe,
  getAuthorUrl,
  getAvatarSrc,
  getDisplayName,
  getFirstName,
  getIsAmbassador,
  getIsRep,
  getRole,
  getVerifedDetails,
} from "./getFunctions";

export const transformAuthor = (author) => {
  // console.log({ author });
  return {
    displayName: getDisplayName(author),
    roleTitle: getRole(author?.role)?.title,
    // isVerified: getIsAmbassador(author?.role),
    verified: getVerifedDetails(author?.role),
    // isRep: getIsRep(author?.role),
    avatarSrc: getAvatarSrc(author?.avatar),
    to: getAuthorUrl(author),
    followers: author?.followerCount,
    likes: author?.contributionHelpfulCount,
    roles: author?.role,
    firstName: getFirstName(author),
    id: author?.id,
    isCurrentUser: getAuthorIsMe(author),
    contributionCount: author?.contributionCount,
    callCount: author?.callCount,
    countryId: author?.countryId,
    stateId: author?.stateId,
    isCompany: getDisplayName(author) === getRole(author?.role)?.title,
  };
};

export const transformCall = (call) => {
  // console.log({ call });
  const author = transformAuthor(call?.author);
  return {
    title: call?.title,
    contributionCount: call?.contributionCount,
    thumbnail: call?.thumbnail || author?.avatarSrc,
    to: `/calls/${call?.id}`,
    author,
    id: call?.id,
    views: call?.viewCount,
    updatedAt: call?.updatedAt,
    text: call?.text,
    video: call?.interactionType?.id === 1 && {
      sources: [
        {
          src: `${call?.mediaURL}(format=m3u8-cmaf,audio-only=false)`,
          type: "application/x-mpegURL",
        },
      ],
      poster: call?.thumbnail,
    },
    contributions: call?.contributions?.map(transformContributionLight),
    topics: call?.topic,
  };
};

export const transformContributionLight = (contribution) => {
  // console.log(contribution, "----------");
  return {
    id: contribution?.id,
    typeId: contribution?.interactionType?.id,
    video: contribution?.interactionType?.id === 1 && {
      sources: [
        {
          src: `${contribution?.mediaURL}(format=m3u8-cmaf,audio-only=false)`,
          type: "application/x-mpegURL",
        },
      ],
      poster: contribution?.thumbnail,
    },
    text: contribution?.interactionType?.id === 2 && contribution?.text,
    audio: contribution?.interactionType?.id === 3 && {
      sources: [
        {
          src: `${contribution?.mediaURL}(format=m3u8-cmaf)`,
          type: "application/x-mpegURL",
        },
      ],
    },
    author: transformAuthor(contribution?.author),
    call: transformCall(contribution?.callDetail),
    updatedAt: contribution?.updatedAt,
  };
};
