import { useRef, useState } from "react";
import { ButtonDefault } from "../../../../../1_Atoms";

export const AvatarUploadButtonChange = ({ handleFileChange, file }) => {
  return (
    <>
      <input
        hidden
        id="avatar-upload-btn"
        type="file"
        accept="image/png, image/jpeg"
        onChange={(event) => {
          const fileObj = event.target.files && event.target.files[0];
          if (!fileObj) {
            return;
          }
          // 👇️ reset file input
          event.target.value = null;

          // 👇️ is now empty
          console.log(event.target.files, "test");

          // console.log("change");
          handleFileChange(fileObj);
        }}
      />
      <ButtonDefault
        cursor="pointer"
        as="label"
        htmlFor="avatar-upload-btn"
        size="sm"
      >
        Upload
      </ButtonDefault>
    </>
  );
};
