import React from "react";
import { Box } from "@chakra-ui/react";

export const IllustrationLightBulb = (props) => (
  <Box as="svg" viewBox="0 0 56.9 56.9" {...props}>
    <g id="b">
      <g id="c">
        <g>
          <g>
            <path
              className="g"
              fill="#ffc835"
              d="M28.45,12.32c-7.14,0-13.4,6.43-13.4,13.76,0,5.16,2.68,8.7,4.87,12.62,1.87,3.34,3.45,7.41,3.45,10.49,0,.74,.6,1.33,1.33,1.33h7.5c.74,0,1.33-.6,1.33-1.33,0-3.08,1.26-6.94,3.13-10.28,2.19-3.92,5.19-7.67,5.19-12.83,0-7.33-6.26-13.76-13.4-13.76"
            />
            <path
              className="f"
              fill="#e58d3e"
              d="M32.2,51.43h-7.5c-1.23,0-2.24-1-2.24-2.24,0-2.57-1.25-6.32-3.33-10.05-.35-.63-.72-1.26-1.09-1.88-1.92-3.25-3.89-6.61-3.89-11.17,0-7.81,6.68-14.67,14.3-14.67s14.3,6.85,14.3,14.67c0,4.43-2.04,7.8-4,11.06-.44,.74-.89,1.47-1.3,2.21-1.89,3.37-3.01,7.05-3.01,9.84,0,1.23-1,2.24-2.24,2.24Zm-3.75-38.21c-6.66,0-12.49,6.01-12.49,12.86,0,4.07,1.77,7.07,3.65,10.26,.37,.63,.75,1.27,1.11,1.92,1.07,1.91,3.56,6.81,3.56,10.93,0,.24,.19,.43,.43,.43h7.5c.24,0,.43-.19,.43-.43,0-3.08,1.21-7.09,3.24-10.72,.42-.75,.87-1.5,1.33-2.26,1.84-3.05,3.74-6.21,3.74-10.13,0-6.85-5.84-12.86-12.49-12.86Z"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M33.12,51.43h-9.35c-.75,0-1.36-.61-1.36-1.36s.61-1.36,1.36-1.36h9.35c.75,0,1.36,.61,1.36,1.36s-.61,1.36-1.36,1.36"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M33.12,55.54h-9.35c-.75,0-1.36-.61-1.36-1.36s.61-1.36,1.36-1.36h9.35c.75,0,1.36,.61,1.36,1.36s-.61,1.36-1.36,1.36"
            />
            <path
              className="f"
              fill="#e58d3e"
              d="M20.82,31.95c-.15,0-.3-.04-.44-.12-.43-.25-.59-.8-.34-1.23,3.23-5.7,5.31-7.72,7.18-7.02,1.38,.52,1.61,2.1,1.79,3.37,.06,.38,.14,.98,.25,1.23,.23-.08,.53-.44,.8-.77,.52-.63,1.22-1.5,2.36-1.44,1.06,.04,1.65,.92,2.08,1.56,.14,.21,.35,.52,.46,.59,.02,0,.05,0,.05,0,.04-.02,.2-.2,.32-.32,.14-.16,.3-.33,.5-.51,.36-.34,.94-.32,1.28,.05,.34,.37,.32,.94-.05,1.28-.16,.15-.29,.29-.4,.41-.43,.47-1.02,1.1-2.17,.83-.7-.16-1.11-.77-1.47-1.31-.15-.23-.51-.76-.66-.76h0c-.23,0-.58,.42-.89,.79-.55,.66-1.29,1.58-2.5,1.42-1.34-.16-1.55-1.61-1.72-2.78-.11-.76-.26-1.8-.65-1.95-.04-.02-1.23-.38-4.97,6.22-.17,.29-.47,.46-.79,.46Z"
            />
            <path
              className="f"
              fill="#e58d3e"
              d="M28.45,7.47c-.5,0-.9-.4-.9-.9V1.62c0-.5,.4-.9,.9-.9s.9,.4,.9,.9V6.56c0,.5-.4,.9-.9,.9Z"
            />
            <path
              className="f"
              fill="#e58d3e"
              d="M39,11.53c-.19,0-.38-.06-.55-.18-.4-.3-.47-.87-.17-1.26l2.85-3.75c.3-.4,.87-.47,1.27-.17,.4,.3,.47,.87,.17,1.26l-2.85,3.75c-.18,.23-.45,.36-.72,.36Z"
            />
            <path
              className="f"
              fill="#e58d3e"
              d="M17.9,11.53c-.27,0-.54-.12-.72-.36l-2.85-3.75c-.3-.4-.22-.96,.17-1.26s.96-.23,1.27,.17l2.85,3.75c.3,.4,.22,.96-.17,1.26-.16,.12-.36,.18-.55,.18Z"
            />
          </g>
          <rect className="d" fill="none" width="56.9" height="56.9" />
        </g>
      </g>
    </g>
  </Box>
);
