import { BsBook } from "react-icons/bs";
import { AudioContributionIntroIconAndText } from "../components";

export const AudioContributionIntroBlockTwo = () => (
    <AudioContributionIntroIconAndText
      as={BsBook}
      text="Help others to learn more about their condition."
      maxW="280px"
    />
);
