import { BsMegaphone } from "react-icons/bs";
import { AudioContributionIntroIconAndText } from "../components";

export const AudioContributionIntroBlockOne = () => (
    <AudioContributionIntroIconAndText
      as={BsMegaphone}
      text="Share your experience by recording an audio clip under 2 minutes."
      maxW="280px"
    />
);
