import { useSelector } from "react-redux";
import {
  useDeleteContributionMutation,
  useReportContributionMutation,
  useNotifications,
} from "../";

export const useContribution = () => {
  const [deleteItem, deleteStatus] = useDeleteContributionMutation();
  const [reportItem, reportStatus] = useReportContributionMutation();
  const { id } = useSelector((state) => state.auth);
  const isLoading = deleteStatus.isLoading || reportStatus.isLoading;

  const notifications = useNotifications();

  const deleteContribution = async (contribution) => {
    try {
      await deleteItem(contribution).unwrap();
      notifications.addToast({ action: notifications.CONTRIBUTION_DELETED });
    } catch (error) {
      notifications.addToast({
        action: notifications.GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const reportContribution = async (contribution) => {
    try {
      await reportItem(contribution).unwrap();
      notifications.addToast({ action: notifications.CONTRIBUTION_REPORTED });
    } catch (error) {
      notifications.addToast({
        action: notifications.GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const isMine = (contribution) =>
    parseInt(contribution?.author?.id) === parseInt(id);

  return {
    deleteContribution,
    reportContribution,
    isMine,
    isLoading,
    deleteStatus,
  };
};
