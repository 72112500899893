import { DrawerContent } from "@chakra-ui/react";

export const NavDrawerContent = ({ children }) => (
  <DrawerContent
    sx={{
      maxW: "420px",
      width: "95% !important",
      "&[role=dialog]": {
        maxH: "unset",
      },
    }}
    zIndex="1000"
    pos="relative"
    pt={4}
    pb={6}
  >
    {children}
  </DrawerContent>
);
