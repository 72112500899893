import { Box } from "@chakra-ui/react";
import { VideoPreviewBar } from "../../../../components";

export const DictaphonePreviewBar = ({ isRecording }) => (
  <Box pos="absolute" zIndex='2' top={4} left={4}>
    <VideoPreviewBar
      recording={isRecording}
      maxReached={() => {}}
      max={110}
    />
  </Box>
);
