import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { modalOpen } from "../_Redux/modalsSlice";

export const useAuthorHandles = (contribution) => {

  const dispatch = useDispatch();

  const handleDeleteContributionClick = useCallback(() => {
    dispatch(modalOpen({ modal: "modalContribution", contribution }));
  }, [contribution]);

  const handleReportAuthorClick = useCallback(() => {
    dispatch(modalOpen({ modal: "modalReportAuthor", contribution }));
  }, [contribution]);

  if (contribution?.author?.isCurrentUser) {
    return [
      {
        name: "Delete contribution",
        handle: handleDeleteContributionClick,
      },
    ];
  } else {
    return [
      {
        name: "Report contribution",
        handle: handleReportAuthorClick,
      },
    ];
  }
};
