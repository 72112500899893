import React from "react";
import { Avatar as AvatarChackra, useBreakpointValue } from "@chakra-ui/react";

export const Avatar = ({ size, ...props }) => {
  const SIZES = {
    sm: { base: "sm", md: "sm" },
    md: { base: "md", md: "md" },
    lg: { base: "lg", md: "lg", lg: "lg" },
  };
  const STYLES = {
    sm: { boxSize: { base: "35px" } },
    md: { boxSize: { base: "40px", md: "50px" } },
    lg: { boxSize: { base: "50px", md: "90px" } },
  };
  const style = STYLES[size] || STYLES.md;
  const avatarSize = useBreakpointValue(SIZES[size] || SIZES.md);
  return (
    <AvatarChackra
      bg="primary.300"
      color="primary.2500"
      fontWeight="heading.bold"
      size={avatarSize}
      {...style}
      {...props}
    />
  );
};
