import React from "react";
import { Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export const ButtonTab = ({ active, ...props }) => (
  <Button
    end
    as={NavLink}
    variant="unstyled"
    h="unset"
    d="flex"
    borderRadius="none"
    pb="0.5rem"
    pt="0.6rem"
    flexGrow="1"
    flexBasis="0%"
    flexShrink="0"
    borderBottom="solid 3px"
    borderColor="gray.200"
    bg="white"
    _hover={{ bg: "primary.100" }}
    sx={{ "&.active": { borderColor: "primary.base" } }}
    {...props}
  />
);
