import { forwardRef, useEffect } from "react";
import { FormSelect } from "../FormSelect/FormSelect";
import { MONTHS, YEARS, DAYS } from "./constants";
import { FormDobInputWrapper } from "./ui";

const generateDaysBasedOnMonth = (monthIndex) => {
  return Array.from({ length: DAYS[monthIndex] }, (_, i) => {
    return i + 1;
  });
};

export const FormDobInput = forwardRef(
  ({ value, onChange = () => {}, reverse, isInvalid }, ref) => {
    const dayMonthIndex = value?.dobMonth - 1 || 0;

    const handleChange = (e) =>
      onChange({ ...value, [e.target.name]: e.target.value });

    useEffect(() => {
      if (value?.dobDay > DAYS[dayMonthIndex]) {
        onChange({ ...value, dobDay: 0 });
      }
    }, [value]);

    return (
      <FormDobInputWrapper ref={ref}>
        <FormSelect
          defaultValue={0}
          name="dobDay"
          onChange={handleChange}
          value={value?.dobDay}
          {...{ reverse, isInvalid }}
        >
          <option disabled value={0}>
            Day
          </option>
          {generateDaysBasedOnMonth(dayMonthIndex).map((m, i) => (
            <option key={m} value={i + 1}>
              {m}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          defaultValue=""
          name="dobMonth"
          onChange={handleChange}
          value={value?.dobMonth}
          {...{ reverse, isInvalid }}
        >
          <option hidden disabled value="">
            Month
          </option>
          {MONTHS.map((m, i) => (
            <option key={m} value={i + 1}>
              {m}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          defaultValue=""
          name="dobYear"
          onChange={handleChange}
          value={value?.dobYear}
          {...{ reverse, isInvalid }}
        >
          <option hidden disabled value="">
            Year
          </option>
          {YEARS.map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </FormSelect>
      </FormDobInputWrapper>
    );
  }
);
