import { Badge } from "@chakra-ui/react";

export const BadgePrimary = (props) => (
  <Badge
    bg="accenttwo.base"
    color="white"
    borderRadius="5px"
    px="8px"
    py="4px"
    lineHeight="1"
    fontWeight='body.black'
    {...props}
  />
);
