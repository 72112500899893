import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import React, { forwardRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useRoleList, useSignUp } from "../../_Hooks";
import { ButtonBack } from "./ButtonBack";
import { ButtonNext } from "./ButtonNext";

export const SignUpStepOne = ({
  onBackToBoardingClick,
  onNextClicked,
  active,
}) => {
  const roleList = useRoleList();
  const signUp = useSignUp();

  const { reset, register, handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    signUp.updateValues(data);
    onNextClicked();
  };

  useEffect(() => {
    if (signUp.values) {
      const { group_roleId } = signUp.values;
      reset({
        group_roleId,
      });
    }
  }, [signUp.values, reset]);

  return (
    <Box color="white">
      <Heading mb="1rem">About you</Heading>
      <Text mb="2rem">
        Colloquy MDS is only for patients, caregivers and professionals impacted
        by MDS
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="group_roleId"
          render={({ field, fieldState: { error } }) => (
            <ButtonRoles
              disabled={!active}
              {...register("group_roleId", {
                required: "Role is required",
              })}
              {...field}
              options={roleList.data}
              error={error}
            />
          )}
        />
        <ButtonBack disabled={!active} onClick={onBackToBoardingClick} />
        <ButtonNext disabled={!active} type="submit" />
      </form>
    </Box>
  );
};

const ButtonRoles = forwardRef(
  ({ onChange, value, options, error, disabled }, ref) => {
    return (
      <Stack pos="relative" pb="2rem">
        {options?.map(({ id, title, text }) => (
          <Button
            key={id}
            w="100%"
            disabled={disabled}
            textAlign="left"
            p="1.5rem"
            py="1rem"
            border="solid 1px"
            borderColor="primary.3200"
            variant="unstyled"
            bg="primary.3000"
            h="unset"
            onClick={() => onChange([id])}
            d="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            whiteSpace="pre-wrap"
            ref={ref}
          >
            <Box
              mt="0.2rem"
              mr="0.8rem"
              boxSize="1.2rem"
              borderRadius="full"
              border="solid 2px"
              borderColor="accentone.base"
              flexShrink="0"
              bg={parseInt(value) === id ? "accentone.base" : "transparent"}
            />
            <Box>
              <Heading fontSize="1.2rem">{title}</Heading>
              <Text fontWeight="body.regular">{text}</Text>
            </Box>
          </Button>
        ))}
        <Text
          pl="0.2rem"
          color="accentone.1500"
          textTransform="uppercase"
          fontSize="0.7rem"
          fontWeight="body.bold"
          pos="absolute"
          bottom="10px"
          lineHeight="1"
        >
          {error?.message}
        </Text>
      </Stack>
    );
  }
);
