import { Select } from "@chakra-ui/react";
import { forwardRef } from "react";

export const FormSelect = forwardRef(({ reverse, ...props }, ref) => (
  <Select
    bg="white"
    color="gray.600"
    _placeholder={{ fontSize: "0.9rem" }}
    _invalid={{ borderColor: reverse ? "accentone.1500" : "red.500" }}
    {...{ ref }}
    {...props}
  />
));
