import { getUserFromSessionOrLocalStorage, transformCall } from "../../_Utils";
import { postloginBaseParams } from "./config";

export const getCallsTransformed = {
  query: () => ({
    url: "/Call",
    params: {
      ...postloginBaseParams,
      currentUserId: getUserFromSessionOrLocalStorage().id,
    },
  }),
  transformResponse: (res) => res.map(transformCall),
};

export const getCall = {
  query: (id) => ({
    url: `/Call/${id}`,
    params: {
      ...postloginBaseParams,
      currentUserId: getUserFromSessionOrLocalStorage().id,
    },
  }),
  transformResponse: (res) => transformCall(res),
  providesTags: (result) => {
    return result ? [{ ...result, type: "Call" }] : [{ type: "Call" }];
  },
};
