import { HStack, Stack } from "@chakra-ui/react";
import { CardAuthor } from "../../../2_Molecules";
import { CardCallThumbnail } from "./components";
import { CardContributionCount, CardTitle, CardWrapper } from "./ui";

export const CardCallWide = ({
  handleVerifiedClicked,
  title,
  contributionCount,
  thumbnail,
  author,
  to = "/",
}) => (
  <CardWrapper {...{ to }}>
    <HStack alignItems="space-between" spacing={4} flexGrow={1}>
      <Stack flexGrow={1}>
        <Stack flexGrow={1} spacing={4}>
          <CardAuthor {...{ handleVerifiedClicked }} {...author} />
          <CardTitle {...{ title }} />
        </Stack>
        <CardContributionCount {...{ contributionCount }} />
      </Stack>
      <CardCallThumbnail
        {...{ title, thumbnail }}
        isCompany={author?.isCompany}
      />
    </HStack>
  </CardWrapper>
);
