import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

export const ModalPrimary = ({
  isOpen,
  onClose,
  children,
  maxWidth,
  ...props
}) => (
  <Modal isOpen={isOpen} onClose={onClose} {...props}>
    <ModalOverlay />
    <ModalContent mx="1rem" {...{ maxWidth }}>
      <ModalBody
        d="flex"
        flexDir="column"
        justifyContent="center"
        px="2rem"
        py="3rem"
      >
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
);
