import { CardContribution } from "../../../../../../Cards";
import { useCall } from "../../../../../../_Hooks";
import { ListWrapper } from "./ui";

export const PageCallContributionList = () => {
  const { data, isLoading } = useCall();
  if (isLoading) return null;
  return (
    <ListWrapper>
      {data?.contributions?.map((item) => (
        <CardContribution key={item?.id} data={item} />
      ))}
    </ListWrapper>
  );
};
