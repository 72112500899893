import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    signupUpdateValues: (state, action) => {
      return { ...state, ...action.payload };
    },
    signupReset: () => initialState,
  },
});

export const { signupUpdateValues, signupReset } = signupSlice.actions;

export default signupSlice.reducer;
