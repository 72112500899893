import { Text } from "@chakra-ui/react";

export const PageContactText = () => (
  <Text>
    Colloquy is led by a team of ambassadors to ensure the patient perspective
    is brought to the forefront of everything we do. The team of ambassadors are
    made up of patients, loved ones, experts and nurses, who facilitate
    exchanges that support you along your journey.
  </Text>
);
