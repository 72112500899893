import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { wrapper } from "./framerVariants";

export const PageSignInFormWrapper = ({ children }) => (
  <Box
    flexGrow="1"
    w="full"
    maxW="sm"
    d="flex"
    justifyContent="center"
    flexDir="column"
    color="white"
    px={4}
    as={motion.div}
    variants={wrapper}
    initial="initial"
    animate="enter"
    exit="exit"
  >
    {children}
  </Box>
);
