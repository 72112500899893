import {
  PageSignInSignUpCtaWrapper,
  PageSignInSignUpCtaButton,
  PageSignInSignUpCtaMessage,
} from "./ui";

export const PageSignSignUpCta = () => (
  <PageSignInSignUpCtaWrapper>
    <PageSignInSignUpCtaMessage />
    <PageSignInSignUpCtaButton />
  </PageSignInSignUpCtaWrapper>
);
