import { BlobServiceClient } from "@azure/storage-blob";
import { normalizeErrorNotification } from "../_Utils";

const containerName = `colupload`;
const storageAccountName = "colcoqstor";

const uploadFileToBlob = async (file, sasToken, filename) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(filename);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    await blobClient.uploadData(file, options);
    return "ok";
  } catch (error) {
    throw normalizeErrorNotification(error);
  }
};
export const serviceAzureStorageBlob = { uploadFileToBlob };
