import { Box } from "@chakra-ui/react";

export const FooterWrapper = ({ children }) => (
  <Box
    as="footer"
    textAlign="center"
    color="white"
    fontWeight="body.bold"
    fontSize="sm"
    pt="4rem"
    pb="1rem"
    px="1.5rem"
  >
    {children}
  </Box>
);
