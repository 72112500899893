import React, { useEffect } from "react";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import { PageTitle, useProfile, formValidation, LoaderSection } from "../../";
import { useForm } from "react-hook-form";
import { FormControlInput } from "../../../ui";

export const PageAccountEmail = () => {
  const { data, isLoading, updateProfile, error, patchStatus } = useProfile();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (data) {
      const { username } = data;
      reset({ username });
    }
  }, [data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Change email address" />
      <LoaderSection loading={!data} error={error}>
        <Box p="1.5rem">
          {data && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing="2rem">
                <FormControlInput
                  {...register("username", {
                    required: "Email is required",
                    validate: formValidation.isValidEmail,
                  })}
                  error={errors.username?.message}
                  isInvalid={errors.username?.message ? true : false}
                  defaultValue={data.username}
                  placeholder="mail@website.com"
                  label="Email address"
                />
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || isLoading || patchStatus.isLoading}
                >
                  {(isLoading || patchStatus.isLoading) && "Loading"}
                  {!isDirty && "Up to date"}
                  {isDirty && !isLoading && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
