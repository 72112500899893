import { useCallback } from "react";
import { CardSelector } from "../../../ui";
import {
  useAuthorHandles,
  useHelpfulContributions,
  useSavedContributions,
  useVerifiedHandle,
} from "../../_Hooks";

export const CardContribution = ({ data, variant = "primary" }) => {
  const authorHandles = useAuthorHandles(data);
  const { handleVerifiedClicked } = useVerifiedHandle();
  const savedContribution = useSavedContributions();
  const helpful = useHelpfulContributions();
  const isSaved = savedContribution.contributionIsSaved(data);
  const helpfulType = helpful.getHelpfulTypeByContributionId(data?.id);

  const handleSavedClick = useCallback(() => {
    if (isSaved) {
      savedContribution.deleteContribution(data);
    } else {
      savedContribution.saveContribution(data);
    }
  }, [isSaved]);

  const handleHelpfulClick = useCallback(
    (helpfulType) => {
      helpful.toggleHelpfulContribution(data, helpfulType);
    },
    [helpfulType]
  );

  return (
    <CardSelector
      type="contribution"
      {...{ variant }}
      {...data}
      {...{
        handleSavedClick,
        isSaved,
        helpfulType,
        handleHelpfulClick,
      }}
      handleVerifiedClicked={() => {
        handleVerifiedClicked(data?.author?.verified);
      }}
      menuItems={authorHandles}
    />
  );
};
