import { memo, useMemo } from "react";
import { HeroPerson } from "../../../../../../../ui";
import {
  useCountryList,
  useStateList,
  useVerifiedHandle,
} from "../../../../../../_Hooks";
import { getFormattedAddress } from "../../../../../../_Utils";

export const PageProfileHero = memo(({ profile }) => {
  const countryList = useCountryList();
  const stateList = useStateList();
  const { handleVerifiedClicked } = useVerifiedHandle();

  const address = useMemo(
    () =>
      getFormattedAddress(
        stateList.getStateById(profile?.stateId),
        countryList.getCountryById(profile?.countryId)
      ),
    []
  );

  if (countryList.isLoading || stateList.isLoading) return null;
  return (
    <HeroPerson
      {...profile}
      {...{ address }}
      handleVerifiedClicked={(e) => {
        e.preventDefault();
        handleVerifiedClicked(profile?.verified);
      }}
    />
  );
});
