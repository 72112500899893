export const PrivacyText = () => (
  <>
    <h1>Privacy Policy</h1>
    <h3>Information you provide us</h3>
    <p>
      To use our services you need to have an account, and to create an account,
      you need to provide us certain information. This includes a display name;
      a username; a password; an email address; a date of birth; your display
      language; and third-party single sign-in information (if you choose this
      sign-in method). Your profile information, which includes your display
      name and username, is always public, but you can use either your real name
      or a pseudonym.
    </p>
    <p>
      When you use our services, we collect information about how you use these
      services. We use that information to provide you with services that are
      relevant to you.
    </p>
    <h2>Usage Information</h2>
    <p>We collect information about your activity on Colloquy, including:</p>
    <ul>
      <li>
        Content you post, your lists, bookmarks, and communities you are a part
        of.
      </li>
      <li>
        Your interactions with other users’ content, such as likes, shares and
        replies.
      </li>
      <li>
        How you interact with others on the platform, such as people you follow
        and people who follow you.
      </li>
      <li>
        If you communicate with us, such as through email, we will collect
        information about the communication and its content.
      </li>
      <li>
        We collect information on links you interact with across our services
        (including in our emails sent to you).
      </li>
    </ul>
    <h3>When you post content</h3>
    <p>
      You are directing us to disclose that information as broadly as possible.
      Colloquy content, including your profile information (e.g.,
      name/pseudonym, username, profile pictures), is available for viewing by
      the Colloquy community of users.
    </p>
    <h3>Sharing</h3>
    <p>
      Colloquy will share your interactions with other users, such as likes, and
      people you follow.
    </p>
    <h3>Affiliates</h3>
    <p>We may also share your information amongst our affiliates.</p>
    <h3>Partners</h3>
    <p>
      We provide certain third parties with information to help us offer or
      operate our services. This includes but is not limited to how you use the
      services and your participation in Colloquy conversations. If you join the
      Colloquy conversation that is part of a particular disease area, we share
      your information with the partnering organisations that supports that
      Colloquy service. This may include your name and email address. The
      purpose of this is to provide the opportunity for you to find out more
      about the partnering organisation and how it might assist you. We will not
      share your personal information with anyone other than the partnering
      organisation.
    </p>
    <h3>When required by law</h3>
    <p>
      We may preserve, use, share, or disclose your information if we believe
      that it is reasonably necessary to:
    </p>
    <ul>
      <li>
        comply with a law, regulation, legal process or government request;
      </li>
      <li>
        protect the safety of any person, protect the safety or integrity of our
        platform, including to help prevent spam, abuse, or malicious actors on
        our services;
      </li>
      <li>
        explain why we have removed content or accounts from our services{" "}
      </li>
      <li>address fraud, security, or technical issues; or</li>
      <li>
        protect our rights or property, or the rights or property of those who
        use our services.
      </li>
    </ul>

    <h3>As a result of a change in ownership</h3>
    <p>
      We may share, sell, or transfer information about you in connection with a
      merger, acquisition, reorganization, sale of assets, or bankruptcy. This
      Privacy Policy will apply to your personal information that is shared with
      (before and after the close of any transaction) or transferred to the new
      entity.
    </p>

    <p>
      <strong>Effective:</strong> 24 August 2022
    </p>
  </>
);
