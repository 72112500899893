import { CardThumbnail as CardThumbnailAtom } from "../../../../../../1_Atoms";

export const CardThumbnail = ({ title, thumbnail, isCompany }) => (
  <CardThumbnailAtom
    w={{ base: "90px", md: "160px" }}
    maxW="100%"
    flexShrink={0}
    src={thumbnail}
    alt={title}
    ratio={1}
    {...{ isCompany }}
  />
);
