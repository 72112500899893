export const TermsText = () => (
  <>
    <h1>Terms and Conditions of Use</h1>
    <p>
      These Terms and Conditions apply to your Colloquy account (“Colloquy”) and
      govern the relationship between Colloquy Group USA Inc and you. Use of
      your Colloquy account will constitute acceptance of these Terms and
      Conditions. You must therefore read them carefully.
    </p>
    <p>
      Colloquy is a free to use digital platform, and it aims to provide its
      community of users with help and information from others who are living
      with a particular disease or supporting someone who is living with that
      disease. The information provided by others and posted on the platform
      does not constitute medical opinion or advice, nor does Colloquy vet or in
      any way assess the information posted. You should always consult with a
      qualified doctor about your disease before taking any action.
    </p>
    <h3>Accuracy</h3>
    <p>
      Accuracy We do not provide any warranty or guarantee as to the accuracy,
      timeliness, completeness or suitability of the information available to
      the User for any particular purpose. You acknowledge that such information
      and materials may contain inaccuracies or errors and we expressly exclude
      liability for any such inaccuracies or errors to the fullest extent
      permitted by law.
    </p>
    <h2>1. Who May Use the Services</h2>
    <p>
      You may use the Services only if you agree to form a binding contract with
      Colloquy and are not a person barred from receiving services under the
      laws of the applicable jurisdiction. In any case, you must be at least{" "}
      <strong>13 years old</strong>, to use the Services. If you are accepting
      these Terms and using the Services on behalf of a company, organization,
      government, or other legal entity, you represent and warrant that you are
      authorized to do so and have the authority to bind such entity to these
      Terms, in which case the words “you” and “your” as used in these Terms
      shall refer to such entity.
    </p>
    <h2>2. Privacy</h2>
    <p>
      Our <strong>Privacy Policy</strong> describes how we handle the
      information you provide to us when you use our Services. You understand
      that through your use of the Services you consent to the collection and
      use (as set forth in the Privacy Policy) of this information, including
      the transfer of this information to the United States, Ireland, and/or
      other countries for storage, processing and use by Colloquy and its
      affiliates.
    </p>
    <h2>3. Content on the Services</h2>
    <p>
      You are responsible for your use of the Services and for any Content you
      provide, including compliance with applicable laws, rules, and
      regulations. You should only provide Content that you are comfortable
      sharing with others. The purpose of providing content is to engage the
      Colloquy community of users by sharing your experiences of living with a
      particular disease.
    </p>
    <p>
      Any use or reliance on any Content or materials posted via the Services or
      obtained by you through the Services is at your own risk. We do not
      endorse, support, represent or guarantee the completeness, truthfulness,
      accuracy, or reliability of any Content or communications posted via the
      Services or endorse any opinions expressed via the Services. You
      understand that by using the Services, you may be exposed to Content that
      might be offensive, harmful, inaccurate or otherwise inappropriate, or in
      some cases, postings that have been mislabeled or are otherwise deceptive.
      All Content is the sole responsibility of the person who originated such
      Content. We do not control the Content posted via the Services and, we
      cannot take responsibility for such Content. You understand that any
      Content that you view on the Colloquy platform represents the independent
      view of the Content creator and you understand that you should not take
      any action as a result of the Content you view without seeking
      professional advice from a qualified medical practitioner.
    </p>
    <p>
      We reserve the right to remove Content that violates the User Agreement,
      including for example, inappropriate or misleading content, copyright or
      trademark violations or other intellectual property misappropriation,
      impersonation, unlawful conduct, or harassment. Information regarding
      specific policies can be found by contacting us at{" "}
      <a href="mailto:CustomerServices@colloquy.health">
        CustomerServices@colloquy.health
      </a>
    </p>
    <p>
      If you believe that your Content has been copied in a way that constitutes
      copyright infringement, please report this at{" "}
      <a href="mailto:CustomerServices@colloquy.health">
        CustomerServices@colloquy.health
      </a>
    </p>
    <h3>Your Rights and Grant of Rights in the Content</h3>
    <p>
      You retain your rights to any Content you submit, post or display on or
      through the Colloquy Services. You own your Content (and your incorporated
      audio, photos and videos are considered part of the Content).
    </p>
    <p>
      By submitting, posting or displaying Content on or through the Services,
      you grant us a worldwide, non-exclusive, royalty-free license (with the
      right to sublicense) to use, copy, reproduce, process, adapt, modify,
      publish, transmit, display and distribute such Content in any and all
      media or distribution methods now known or later developed (for clarity,
      these rights include, for example, curating, transforming, and
      translating). This license authorizes us to make your Content available to
      the rest of the world and to let others do the same. You agree that this
      license includes the right for Colloquy to provide, promote, and improve
      the Services and to make Content submitted to or through the Services
      available to other companies, organizations or individuals for the
      syndication, broadcast, distribution, promotion or publication of such
      Content on other media and services, subject to our terms and conditions
      for such Content use. Such additional uses by Colloquy, or other
      companies, organizations or individuals, is made with no compensation paid
      to you with respect to the Content that you submit, post, transmit or
      otherwise make available through the Services as the use of the Services
      by you is hereby agreed as being sufficient compensation for the Content
      and grant of rights herein.
    </p>
    <p>
      Colloquy aims to help users in sharing their experience of a disease, and
      has an evolving set of rules for how ecosystem partners can interact with
      your Content on the Services. These rules exist to enable an open
      ecosystem with your rights in mind. You understand that we may modify or
      adapt your Content as it is distributed, syndicated, published, or
      broadcast by us and our partners and/or make changes to your Content in
      order to adapt the Content to different media.
    </p>
    <p>
      You represent and warrant that you have, or have obtained, all rights,
      licenses, consents, permissions, power and/or authority necessary to grant
      the rights granted herein for any Content that you submit, post or display
      on or through the Services. You agree that such Content will not contain
      material subject to copyright or other proprietary rights, unless you have
      necessary permission or are otherwise legally entitled to post the
      material and to grant Twitter the license described above.
    </p>
    <h2>4. Using the Services</h2>
    <p>
      The <strong>Colloquy Rules and Policies</strong> are part of the User
      Agreement and outline what is prohibited on the Services. You may use the
      Services only in compliance with these Terms and all applicable laws,
      rules and regulations.
    </p>
    <p>
      The Services may change from time to time, at our discretion. We may stop
      (permanently or temporarily) providing the Services or any features within
      the Services to you or to users generally. We have the right to create
      limits on use and storage at our sole discretion at any time. We may
      remove or refuse to distribute any Content on the Services, limit
      distribution or visibility of any Content on the service, suspend or
      terminate users, and reclaim usernames without liability to you.
    </p>
    <p>
      In consideration for Colloquy granting you access to and use of the
      Services, you agree that Colloquy and its third-party providers and
      partners may place advertising on the Services or in connection with the
      display of Content or information from the Services whether submitted by
      you or others.
    </p>
    <p>
      You agree not to misuse the Services, for example, by interfering with
      them or accessing them using a method other than the interface and the
      instructions that we provide. You agree that you will not work around any
      technical limitations in the software provided to you as part of the
      Services, or reverse engineer, decompile or disassemble the software,
      except and only to the extent that applicable law expressly permits. You
      may not do any of the following while accessing or using the Services: (i)
      access, tamper with, or use non-public areas of the Services, Colloquy’s
      computer systems, or the technical delivery systems of Colloquy’s
      providers; (ii) probe, scan, or test the vulnerability of any system or
      network or breach or circumvent any security or authentication measures;
      (iii) access or search or attempt to access or search the Services by any
      means (automated or otherwise) other than through our currently available,
      published interfaces that are provided by Colloquy (and only pursuant to
      the applicable terms and conditions); (iv) forge any TCP/IP packet header
      or any part of the header information in any email or posting, or in any
      way use the Services to send altered, deceptive or false
      source-identifying information; or (v) interfere with, or disrupt, (or
      attempt to do so), the access of any user, host or network, including,
      without limitation, sending a virus, overloading, flooding, spamming,
      mail-bombing the Services, or by scripting the creation of Content in such
      a manner as to interfere with or create an undue burden on the Services.
      We also reserve the right to access, read, preserve, and disclose any
      information as we reasonably believe is necessary to (i) satisfy any
      applicable law, regulation, legal process or governmental request, (ii)
      enforce the Terms, including investigation of potential violations hereof,
      (iii) detect, prevent, or otherwise address fraud, security or technical
      issues, (iv) respond to user support requests, or (v) protect the rights,
      property or safety of Colloquy, its users and the public.{" "}
    </p>
    <p>
      Colloquy does not disclose personally-identifying information to third
      parties except in accordance with our Privacy Policy.
    </p>
    <p>
      Colloquy may offer certain services or features for which additional terms
      and conditions may apply in connection with your use of those services. By
      using these additional services, you agree to any additional terms
      applicable to those services, and those additional terms become part of
      our agreement with you. If any of the applicable additional terms conflict
      with these Terms, the additional terms will prevail while you are using
      those services to which they apply.
    </p>
    <h3>Your Account</h3>
    <p>
      You need to create a user account to use our Services. You are responsible
      for safeguarding your account, so use a strong password and limit its use
      to this account. We cannot and will not be liable for any loss or damage
      arising from your failure to comply with the above.
    </p>
    <p>
      You can control most communications from the Services. We may need to
      provide you with certain communications, such as service announcements and
      administrative messages. These communications are considered part of the
      Services and your account, and you may not be able to opt-out from
      receiving them.
    </p>
    <h3>Your License to Use the Services</h3>
    <p>
      Colloquy gives you a personal, worldwide, royalty-free, non-assignable and
      non-exclusive license to use the software provided to you as part of the
      Services. This license has the sole purpose of enabling you to use and
      enjoy the benefit of the Services as provided by Colloquy, in the manner
      permitted by these Terms.
    </p>
    <p>
      The Services are protected by copyright, trademark, and other laws of both
      the United States and other countries. Nothing in the Terms gives you a
      right to use the Colloquy name or any of the Colloquy trademarks, logos,
      domain names, other distinctive brand features, and other proprietary
      rights. All right, title, and interest in and to the Services (excluding
      Content provided by users) are and will remain the exclusive property of
      Colloquy and its licensors. Any feedback, comments, or suggestions you may
      provide regarding Colloquy, or the Services is entirely voluntary and we
      will be free to use such feedback, comments or suggestions as we see fit
      and without any obligation to you.
    </p>
    <h3>Ending These Terms</h3>
    <p>
      You may end your legal agreement with Colloquy at any time by deactivating
      your account and discontinuing your use of the Services. Contact us at{" "}
      <a href="mailto:CustomerServices@colloquy.health">
        CustomerServices@colloquy.health
      </a>{" "}
      for instructions on how to deactivate your account and the Privacy Policy
      for more information on what happens to your information.
    </p>
    <p>
      We may suspend or terminate your account or cease providing you with all
      or part of the Services at any time for any or no reason, including, but
      not limited to, if we reasonably believe: (i) you have violated these
      Terms or the <strong>Colloquy Rules and Policies</strong>, (ii) you create
      risk or possible legal exposure for us; (iii) your account should be
      removed due to unlawful conduct, (iv) your account should be removed due
      to prolonged inactivity; or (v) our provision of the Services to you is no
      longer commercially viable. We will make reasonable efforts to notify you
      by the email address associated with your account or the next time you
      attempt to access your account, depending on the circumstances. In all
      such cases, the Terms shall terminate, including, without limitation, your
      license to use the Services, except that the following sections shall
      continue to apply: 2, 3, 5, and 6. If you believe your account was
      terminated in error you can file an appeal at{" "}
      <a href="mailto:CustomerServices@colloquy.health">
        CustomerServices@colloquy.health
      </a>{" "}
      For the avoidance of doubt, these Terms survive the deactivation or
      termination of your account.
    </p>
    <h2>5. Disclaimers and Limitations of Liability</h2>
    <p>The Services are Available "AS-IS"</p>
    <p>
      Your access to and use of the Services or any Content are at your own
      risk. You understand and agree that the Services are provided to you on an
      “AS IS” and “AS AVAILABLE” basis. The “Colloquy Entities” refers to
      Colloquy, its parents, affiliates, related companies, officers, directors,
      employees, agents, representatives, partners, and licensors. Without
      limiting the foregoing, to the maximum extent permitted under applicable
      law, THE COLLOQUY ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER
      EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
      OR NON-INFRINGEMENT. The Colloquy Entities make no warranty or
      representation and disclaim all responsibility and liability for: (i) the
      completeness, accuracy, availability, timeliness, security or reliability
      of the Services or any Content; (ii) any harm to your computer system,
      loss of data, or other harm that results from your access to or use of the
      Services or any Content; (iii) the deletion of, or the failure to store or
      to transmit, any Content and other communications maintained by the
      Services; and (iv) whether the Services will meet your requirements or be
      available on an uninterrupted, secure, or error-free basis. No advice or
      information, whether oral or written, obtained from the Colloquy Entities
      or through the Services, will create any warranty or representation not
      expressly made herein.
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COLLQOUY ENTITIES
      SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
      OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
      DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER
      INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR
      INABILITY TO ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF
      ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION, ANY
      DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;
      (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORIZED ACCESS,
      USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL THE
      AGGREGATE LIABILITY OF THE COLLOQUY ENTITIES EXCEED THE GREATER OF ONE
      HUNDRED U.S. DOLLARS (U.S. $100.00). THE LIMITATIONS OF THIS SUBSECTION
      SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER BASED ON WARRANTY,
      CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER
      OR NOT THE COLLOQUY ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY
      SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED
      OF ITS ESSENTIAL PURPOSE.
    </p>
    <h2>6. General</h2>
    <p>
      We may revise these Terms from time to time. The changes will not be
      retroactive, and the most current version of the Terms, which will always
      be available in the menu, will govern our relationship with you. We will
      try to notify you of material revisions, for example via a service
      notification or an email to the email associated with your account. By
      continuing to access or use the Services after those revisions become
      effective, you agree to be bound by the revised Terms.
    </p>
    <p>
      The laws of the State of Delaware, excluding its choice of law provisions,
      will govern these Terms and any dispute that arises between you and
      Colloquy. All disputes related to these Terms or the Services will be
      brought solely in the federal or state courts located in Delaware, United
      States, and you consent to personal jurisdiction and waive any objection
      as to inconvenient forum.
    </p>
    <p>
      If you are a federal, state, or local government entity in the United
      States using the Services in your official capacity and legally unable to
      accept the controlling law, jurisdiction or venue clauses above, then
      those clauses do not apply to you. For such U.S. federal government
      entities, these Terms and any action related thereto will be governed by
      the laws of the United States of America (without reference to conflict of
      laws) and, in the absence of federal law and to the extent permitted under
      federal law, the laws of the State of Delaware (excluding choice of law).
    </p>
    <p>
      In the event that any provision of these Terms is held to be invalid or
      unenforceable, then that provision will be limited or eliminated to the
      minimum extent necessary, and the remaining provisions of these Terms will
      remain in full force and effect. Colloquy’s failure to enforce any right
      or provision of these Terms will not be deemed a waiver of such right or
      provision.
    </p>
    <p>
      These Terms are an agreement between you and Colloquy Group USA Inc.,
      address address U.S.A. If you have any questions about these Terms, please
      contact us at{" "}
      <a href="mailto:CustomerServices@colloquy.health">
        CustomerServices@colloquy.health
      </a>
    </p>

    <p>
      <strong>Effective:</strong> 24 August 2022
    </p>
  </>
);
