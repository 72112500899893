import { Button } from "@chakra-ui/react";

export const ButtonFollowWrapper = ({ isFollowing, isLoading, ...props }) => (
  <Button
    pos="relative"
    overflow="hidden"
    _disabled={{
      cursor: "default",
    }}
    size="sm"
    h="35px"
    textTransform="uppercase"
    bg={isFollowing ? "primary.base" : "white"}
    border="solid 1px"
    borderColor="primary.base"
    fontSize="0.75rem"
    fontWeight="body.black"
    _hover={{ bg: isFollowing ? "primary.2500" : "themegray.500" }}
    _active={{ bg: isFollowing ? "primary.2200" : "themegray.600" }}
    disabled={isLoading}
    borderRadius="full"
    px={4}
    {...props}
  />
);
