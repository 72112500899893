import React from "react";
import { Button, Box } from "@chakra-ui/react";
import Icon from "@chakra-ui/icon";

export const ButtonNavPrimaryDesktop = ({ title, icon, ...props }) => (
  <Button
    variant="unstyled"
    textAlign="left"
    pl="1.2rem"
    pr="1.2rem"
    h="60px"
    w={{ base: "60px", md: "100%" }}
    bg="white"
    fontSize="1.2rem"
    d="flex"
    alignItems="center"
    justifyContent={{ base: "center", md: "flex-start" }}
    borderRadius="full"
    border="solid 2px"
    borderColor="white"
    mx="auto"
    color='themegray.3400'
    _hover={{
      bg: "primary.100",
    }}
    _focus={{
      borderColor: "primary.400",
    }}
    sx={{
      "&.active": {
        bg: "primary.200",
      },
    }}
    {...props}
  >
    <Icon flexShrink="0" flexGrow="0" fontSize="1.5rem" as={icon} />
    <Box as="span" ml="0.8rem" d={{ base: "none", md: "inline-block" }}>
      {title}
    </Box>
  </Button>
);
