import {
  ModalCloseButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { TextBlock } from "../../../../ui";
import { ModalPrimary } from "../../../../ui/2_Molecules/ModalPrimary/ModalPrimary";
import { modalClose } from "../../../_Redux/modalsSlice";
import { PrivacyText, RulesText, TermsText } from "./ui";
export const ModalTerms = () => {
  const modal = useSelector((state) => state.modals).modalTerms;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalClose({ modal: "modalTerms" }));
  };

  return (
    <ModalPrimary isOpen={modal?.isOpen} onClose={() => close()} maxWidth="4xl">
      <ModalCloseButton />
      <Tabs>
        <TabList>
          <Tab px={{ base: "0.5rem", md: "1rem", lg: "1.5rem" }}>
            Terms &amp; Conditions
          </Tab>
          <Tab px={{ base: "0.5rem", md: "1rem", lg: "1.5rem" }}>
            Privacy Policy
          </Tab>
          <Tab px={{ base: "0.5rem", md: "1rem", lg: "1.5rem" }}>
            Rules &amp; Policies
          </Tab>
        </TabList>

        <TabPanels mt="1.5rem">
          <TabPanel>
            <TextBlock>
              <TermsText />
            </TextBlock>
          </TabPanel>
          <TabPanel>
            <TextBlock>
              <PrivacyText />
            </TextBlock>
          </TabPanel>
          <TabPanel>
            <TextBlock>
              <RulesText />
            </TextBlock>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ModalPrimary>
  );
};
