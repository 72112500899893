import { config } from ".";

export const validationDictionary = {
  EMAIL_REQUIRED: "Email is required",
  NOT_VALID_EMAIL: "A valid email is required",
  DOB_REQUIRED: "Date of birth is required",
  NOT_OLD_ENOUGH: `You have to be at least ${config.minimumAge} to use Colloquy`,
  PASSWORD_NOT_LONG_ENOUGH: `Must be at least ${config.minimumPasswordLength} characters`,
  PASSWORD_REQUIRED: "Password is required",
};
