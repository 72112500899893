import React from "react";
import { NotificationAuthor } from "../../";

export const NotificationSavedContribution = ({ author }) => {
  const { displayName, avatarSrc } = author;
  return (
    <NotificationAuthor
      avatarName={displayName}
      avatarSrc={avatarSrc}
      message="Saved contribution"
    />
  );
};
