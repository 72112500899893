import { useDispatch, useSelector } from "react-redux";
import { ModalContribution } from "../../../2_Molecules";
import { modalClose } from "../../../_Redux/modalsSlice";
import { AudioContribution } from "./components";

export const ModalAudioContribution = () => {
  const modal = useSelector((state) => state.modals).modalAudioContribution;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalClose({ modal: "modalAudioContribution" }));
  };

  return (
    <ModalContribution
      title={modal?.call?.title}
      isOpen={modal.isOpen}
      onClose={() => close()}
    >
      <AudioContribution closeModal={close} call={modal?.call} />
    </ModalContribution>
  );
};
