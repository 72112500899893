import { AspectRatio, Box, useTheme } from "@chakra-ui/react";
import { rgba } from "polished";
import { useEffect, useRef } from "react";
import videojs from "video.js";

// import "@videojs/themes/dist/fantasy/index.css";
import "./videoPlayertest.css";

export const VideoPlayer = ({
  preload = "none",
  sources,
  poster,
  ratio = 5 / 4,
  colorScheme = "primary",
}) => {
  const { colors } = useTheme();
  const COLORSCHEMES = {
    primary: { accentcolor: colors.primary.base },
    accentone: { accentcolor: colors.accentone.base },
    accenttwo: { accentcolor: colors.accenttwo.base },
  };
  const chosenColor = COLORSCHEMES[colorScheme] || COLORSCHEMES.primary;
  const videoRef = useRef();
  const playerRef = useRef();

  useEffect(() => {
    const options = {
      autoplay: false,
      controls: true,
      preload,
      poster,
      sources,
    };
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      playerRef.current = videojs(videoElement, options, () => {});
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      // player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoRef, sources, poster]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <Box
      w="100%"
      overflow="hidden"
      className="custom"
      sx={{
        ".video-js": {
          "--vjs-theme-fantasy--primary": chosenColor.accentcolor,
        },
        ".vjs-big-play-button": {
          backgroundColor: rgba(chosenColor.accentcolor, 0.2),
        },
        ".video-js:hover .vjs-big-play-button": {
          backgroundColor: rgba(chosenColor.accentcolor, 0.4),
        },
        ".vjs-poster": {
          backgroundSize: "cover",
          filter: "grayscale(40%)",
        },
      }}
    >
      <AspectRatio ratio={ratio}>
        <div data-vjs-player>
          <Box
            as="video"
            ref={videoRef}
            className="video-js vjs-theme-fantasy"
          />
        </div>
      </AspectRatio>
    </Box>
  );
};
