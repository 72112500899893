import React from "react";
import { useOutletContext } from "react-router-dom";
import { ListCardCalls } from "../../";
import {
  EmptyStateCallsNotVerified,
  EmptyStateCallsVerifiedAuthor,
} from "../../2_Molecules";
import { ListWithEmptyStatePrimary } from "../../3_Organisms";

export const PagePersonCalls = () => {
  const { author } = useOutletContext();
  const EmptyState = author.data?.verified
    ? () => <EmptyStateCallsVerifiedAuthor author={author.data} />
    : EmptyStateCallsNotVerified;
  return (
    <ListWithEmptyStatePrimary
      data={author?.data?.calls}
      EmptyState={EmptyState}
    >
      <ListCardCalls data={author?.data?.calls} />
    </ListWithEmptyStatePrimary>
  );
};
