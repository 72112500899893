import { Box, Stack } from "@chakra-ui/react";

export const EmptyStateList = ({ children }) => (
  <Box
    bg="themegray.100"
    flexGrow="1"
    d="flex"
    justifyContent="center"
    flexDir="column"
    px="1.5rem"
  >
    <Stack spacing="2rem" alignItems="center">
      {children}
    </Stack>
  </Box>
);
