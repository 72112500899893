import { Box } from "@chakra-ui/react";

export const LoadingPrimaryIsLoadingWrapper = ({ children }) => (
  <Box
    flexGrow="1"
    w="full"
    d="flex"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Box>
);
