import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PageTitle, useProfile, LoaderSection } from "../../";
import { useForm } from "react-hook-form";
import { FormControlInput } from "../../../ui";

export const PageAccountName = () => {
  const { data, isLoading, updateProfile, error, patchStatus } = useProfile();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (data) {
      const { firstName, lastName, onlyDisplayInitials } = data;
      reset({
        firstName,
        lastName,
        onlyDisplayInitials,
      });
    }
  }, [data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Name" />
      <LoaderSection loading={!data} error={error}>
        <Box p="1.5rem">
          {data && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing={6}>
                <Stack spacing={6}>
                  <FormControlInput
                    {...register("firstName", {
                      required: "First name is required",
                    })}
                    error={errors.firstName?.message}
                    isInvalid={errors.firstName?.message ? true : false}
                    defaultValue={data.firstName}
                    label="First Name"
                  />
                  <FormControlInput
                    {...register("lastName", {
                      required: "Last name is required",
                    })}
                    error={errors.lastName?.message}
                    isInvalid={errors.lastName?.message ? true : false}
                    defaultValue={data.lastName}
                    label="Last Name"
                  />
                  <Checkbox {...register("onlyDisplayInitials")}>
                    Only display my initials
                  </Checkbox>
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || isLoading || patchStatus.isLoading}
                >
                  {(isLoading || patchStatus.isLoading) && "Loading"}
                  {!isDirty && "Up to date"}
                  {isDirty && !isLoading && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
