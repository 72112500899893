import { Box } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { useRef } from "react";
import { useDictaphoneAudioWave } from "./useDictaphoneAudioWave";

export const DictaphoneAudioWave = ({ audioStream }) => {
  const { colors } = useTheme();
  const ref = useRef();
  const containerRef = useRef();

  useDictaphoneAudioWave(
    audioStream,
    ref,
    containerRef,
    "white",
    colors.accenttwo.base
  );

  return (
    <Box
      bg="white"
      borderBottom="solid"
      borderBottomWidth={8}
      borderBottomColor="primary.3200"
    >
      <Box w="100%" h="240px" mx="auto"  ref={containerRef}>
        <canvas ref={ref} />
      </Box>
    </Box>
  );
};
