import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Avatar, ButtonVerified } from "../../../1_Atoms";

export const ListItemPersonSmall = ({
  displayName,
  roleTitle,
  avatarSrc,
  to,
  verified,
}) => (
  <Box _hover={{bg:'themegray.100'}} >
    <Link {...{ to }}>
      <HStack px={3} py={3}>
        <Avatar fontSize="sm" boxSize={8} src={avatarSrc} name={displayName} />
          <Stack spacing={0}>
            <HStack spacing={0}>
              <Text lineHeight="shorter" fontSize="sm">
                {displayName}
              </Text>
              {verified && <ButtonVerified {...verified} onClick={() => {}} />}
            </HStack>
            <Text
              lineHeight="shorter"
              textTransform="uppercase"
              fontSize="xs"
              fontWeight="body.black"
            >
              {roleTitle}
            </Text>
          </Stack>
      </HStack>
    </Link>
  </Box>
);
