import React, { useEffect } from "react";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import { PageTitle, useProfile, LoaderSection, formValidation } from "../../";
import { Controller, useForm } from "react-hook-form";
import { FormControlInputDob } from "../../../ui";

export const PageAccountDob = () => {
  const { data, isLoading, updateProfile, error, patchStatus } = useProfile();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    if (data) {
      const { dob } = data;
      reset({ dob });
    }
  }, [data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Name" />
      <LoaderSection loading={!data} error={error}>
        <Box p="1.5rem">
          {data && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing="2rem">
                <Controller
                  control={control}
                  name="dob"
                  rules={{
                    required: "Date of birth is required",
                    validate: formValidation.isValidDob,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlInputDob
                      {...field}
                      error={error?.message}
                      isInvalid={error?.message}
                      label="Date of birth"
                    />
                  )}
                />
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || isLoading || patchStatus.isLoading}
                >
                  {(isLoading || patchStatus.isLoading) && "Loading"}
                  {!isDirty && "Up to date"}
                  {isDirty && !isLoading && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
