import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ButtonNavPrimaryDesktop } from "../..";
import { BsBookmarkHeart } from "react-icons/bs";
import { MdUpdate } from "react-icons/md";
import { AiOutlineComment, AiOutlineTeam, AiOutlineUser } from "react-icons/ai";

const routes = [
  {
    title: "Calls",
    to: "/calls",
    icon: AiOutlineComment,
  },
  {
    title: "Activity",
    to: "/activity",
    icon: MdUpdate,
  },
  {
    title: "People",
    to: "/people",
    icon: AiOutlineTeam,
  },
  {
    title: "Saved",
    to: "/saved",
    icon: BsBookmarkHeart,
  },
  {
    title: "Profile",
    to: "/profile",
    icon: AiOutlineUser,
  },
];

export const NavigationPrimary = ({ children, updateAccentOne, ...props }) => {
  const width = { sm: "80px", md: "220px", lg: "230px", xl: "300px" };
  return (
    <Box flexGrow="1" d="flex">
      <Box as="header" bg="white" flexShrink="0" w={width} {...props}>
        <Box
          pos="fixed"
          bottom={{ base: "0", sm: "unset" }}
          zIndex="2"
          w={{ base: "100%", ...width }}
        >
          <Flex flexShrink="0">
            <Box
              w="100%"
              mt="1rem"
              mx={{ md: "1rem" }}
              d={{ base: "flex", sm: "block" }}
              bg="white"
              h={{ base: "70px", sm: "unset" }}
              alignItems="center"
              borderTop={{ base: "solid 1px", sm: "none" }}
              borderTopColor="gray.200"
            >
              {routes.map((route) => (
                <ButtonNavPrimaryDesktop
                  key={route.to}
                  mb={{ sm: "0.5rem" }}
                  as={NavLink}
                  {...route}
                />
              ))}
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box pb={{ base: "70px", sm: "unset" }} flexGrow="1" as="main" d="flex">
        {children}
      </Box>
    </Box>
  );
};

// const NavigationPrimaryMobileItem = ({ title, to = "", ...props }) => (
//   <Button as={NavLink} to={to} sx={{ "&.active": { bg: "red" } }} {...props}>
//     {title}
//   </Button>
// );
