import { createSlice } from "@reduxjs/toolkit";
const user = JSON.parse(sessionStorage.getItem("colloquy001"));
const initialState = { id: user?.id, jwtLoginToken: user?.jwtLoginToken };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (_, action) => {
      sessionStorage.setItem("colloquy001", JSON.stringify(action.payload));
      return action.payload;
    },
    removeCredentials: () => {
      sessionStorage.removeItem("colloquy001");
      return { id: null, jwtLoginToken: null };
    },
  },
});

export const { setCredentials, removeCredentials } = authSlice.actions;

export default authSlice.reducer;
