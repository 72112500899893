import { AnimatePresence, motion } from "framer-motion";
import { Box, Spinner } from "@chakra-ui/react";
import { ButtonWrapper, ButtonWrapperLoading } from "./framerVariants";

export const AvatarUploadButtonsWrapper = ({ children, isLoading }) => (
  <AnimatePresence exitBeforeEnter initial={false}>
    {!isLoading && (
      <motion.div
        key="1"
        variants={ButtonWrapper}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <Box d="flex" flexDir="column" gap="2" {...{ children }} />
      </motion.div>
    )}
    {isLoading && (
      <motion.div
        key="2"
        variants={ButtonWrapperLoading}
        initial="hide"
        animate="show"
        exit="hide"
      >
        <Spinner
          size="lg"
          color="primary.base"
          emptyColor="primary.400"
          thickness="5px"
        />
      </motion.div>
    )}
  </AnimatePresence>
);
