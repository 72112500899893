import { Text } from "@chakra-ui/react";

export const HeroPersonRole = ({ roleTitle, isCompany }) => {
  if (isCompany) return null;
  return (
    <Text
      lineHeight="shorter"
      textTransform="uppercase"
      fontSize="xs"
      fontWeight="body.black"
    >
      {roleTitle}
    </Text>
  );
};
