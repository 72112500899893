import { default as ReactAvatarEditor } from "react-avatar-editor";
import { useState } from "react";
import { AvatarEditorSlider } from "./components";
import {
  AvatarEditorButtonCancel,
  AvatarEditorButtonSave,
  AvatarEditorButtonsWrapper,
  AvatarEditorSliderWrapper,
  AvatarEditorTitle,
  AvatarEditorWrapper,
} from "./ui";

export const AvatarEditor = ({
  avatarRef,
  file,
  isLoading,
  handleCancelClick,
  handleSaveClick,
}) => {
  const [scale, setScale] = useState(1);
  return (
    <AvatarEditorWrapper>
      {/* title */}
      <AvatarEditorTitle />

      {/* main editor */}
      <ReactAvatarEditor
        ref={avatarRef}
        image={file}
        color={[47, 62, 147, 0.9]} // RGBA
        border={100}
        borderRadius={1000}
        width={500}
        height={500}
        scale={scale}
      />

      {/* sliders */}
      <AvatarEditorSliderWrapper>
        <AvatarEditorSlider onChange={(val) => setScale(val / 100 + 1)} />
      </AvatarEditorSliderWrapper>

      {/* buttons */}
      <AvatarEditorButtonsWrapper>
        <AvatarEditorButtonCancel {...{ isLoading, handleCancelClick, file }} />
        <AvatarEditorButtonSave {...{ isLoading, handleSaveClick, file }} />
      </AvatarEditorButtonsWrapper>
    </AvatarEditorWrapper>
  );
};
