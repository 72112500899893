import { motion } from "framer-motion";
import { ButtonTertiary } from "../../../../../../../../../ui";
import { introchild } from "./framerVariants";

export const AudioContributionIntroButton = ({ handleNextClick }) => (
  <motion.div
    style={{ width: "100%", display: "flex", justifyContent: "center" }}
    variants={introchild}
  >
    <ButtonTertiary
      size="sm"
      alignSelf="center"
      minW="180px"
      textTransform="uppercase"
      fontSize="0.75rem"
      fontWeight="body.black"
      onClick={handleNextClick}
    >
      Let's go
    </ButtonTertiary>
  </motion.div>
);
