import { Flex, HStack } from "@chakra-ui/react";
import { ButtonFollow } from "../../../../../1_Atoms";
import {
  HeroPersonAddress,
  HeroPersonExtraDetailsWrapper,
  HeroPersonFollowers,
  HeroPersonLikes,
} from "./ui";

export const HeroPersonExtraDetails = ({
  followIsLoading,
  handleFollowClick,
  isFollowing,
  likes,
  address,
  followers,
  isCurrentUser,
}) => (
  <HeroPersonExtraDetailsWrapper>
    <Flex justifyContent="space-between" alignItems="center">
      <div>
        <HeroPersonAddress {...{ address }} />
        <HStack>
          <HeroPersonLikes {...{ likes }} />
          <span>|</span>
          <HeroPersonFollowers {...{ followers }} />
        </HStack>
      </div>
      {!isCurrentUser && (
        <ButtonFollow
          isLoading={followIsLoading}
          onClick={handleFollowClick}
          {...{ isFollowing }}
        />
      )}
    </Flex>
  </HeroPersonExtraDetailsWrapper>
);
