import { Box } from "@chakra-ui/react";

export const AudioPlayerWrapper = ({ children, color }) => (
  <Box
    h="300px"
    w="100%"
    pos="relative"
    overflow="hidden"
    sx={{
      ".vjs-theme-fantasy": {
        "--vjs-theme-fantasy--secondary": "#fff",
      },
      ".video-js": {
        h: "100%",
        w: "100%",
        backgroundColor: "transparent",
        "--vjs-theme-fantasy--primary": color,
      },
      ".video-js .vjs-big-play-button .vjs-icon-placeholder:after": {
        content: "none",
      },
      ".vjs-big-play-button": {
        display: "flex",
        boxSize: "full",
        pos: "absolute",
        inset: 0,
        margin: 0,
        borderRadius: "none",
        alignItems: "center",
        justifyContent: "center",
        _hover: {
          ".vjs-icon-placeholder": {
            transform: "scale(1.2)",
          },
        },
        ".vjs-icon-placeholder": {
          transition: "all 0.2s ease",
          pos: "relative",
          margin: 0,
          boxSize: "100px",
          borderRadius: "full",
          border: "solid 3px white",
          alignItems: "center",
          justifyContent: "center",
          d: "flex",

          "&:before": {
            m: 0,
            inset: 0,
            position: "relative",
            color: "white",
            boxSize: "auto",
          },
        },
      },
    }}
    {...{ children }}
  />
);
