import React from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

export const OnBoardStepLayout = ({
  src,
  alt,
  title,
  text,
  index,
  children,
  length,
}) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    flexDir={{ base: "column", md: "row" }}
  >
    <Box
      borderRadius="lg"
      p="1rem"
      flexGrow="1"
      maxW={{ base: "400px", xl: "500px" }}
      mb={{ base: "2rem", md: "0" }}
    >
      <Image src={src} alt={alt} />
    </Box>
    <Box textAlign="left" pl={{ md: "3rem" }} color="white">
      <Box maxW="520px" mx="auto">
        <Text color="white" mb="1rem">
          Step {index + 1} of {length}
        </Text>
        <Heading mb="1rem">{title}</Heading>
        <Text>{text}</Text>
        {children}
      </Box>
    </Box>
  </Flex>
);
