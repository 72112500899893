import { useNotifications, useProfile } from ".";
import {
  useDeleteFollowAuthorMutation,
  usePostFollowAuthorMutation,
} from "../_Service";

export const useUserFollowing = () => {
  const profile = useProfile();
  const data = profile?.data?.peopleFollowing;
  const [postFollowAuthor, status] = usePostFollowAuthorMutation();
  const [deleteFollowAuthor, deleteStatus] = useDeleteFollowAuthorMutation();
  const { addToast, AUTHOR_FOLLOWED, AUTHOR_UNFOLLOWED, GENERIC_ERROR } =
    useNotifications();

  const isLoading = status.isLoading || deleteStatus.isLoading;

  const isFollowing = (author) => {
    return data?.filter(
      (person) => parseInt(person?.id) === parseInt(author?.id)
    )[0]
      ? true
      : false;
  };

  const followAuthor = async (author) => {
    try {
      await postFollowAuthor(author).unwrap();
      addToast({ action: AUTHOR_FOLLOWED, payload: author });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  const unfollowAuthor = async (author) => {
    try {
      await deleteFollowAuthor(author).unwrap();
      addToast({ action: AUTHOR_UNFOLLOWED, payload: author });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  const toggleFollow = (author) =>
    isFollowing(author) ? unfollowAuthor(author) : followAuthor(author);

  return {
    data,
    isFollowing,
    followAuthor,
    unfollowAuthor,
    isLoading,
    toggleFollow,
  };
};
