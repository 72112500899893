import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalReportAuthor: { isOpen: false, contribution: null },
  modalVerificationExplanation: { isOpen: false },
  modalContribution: { isOpen: false, contribution: null },
  modalVideoContribution: { isOpen: false, call: null },
  modalTextContribution: { isOpen: false, call: null },
  modalAudioContribution: { isOpen: false, call: null },
  modalTerms: { isOpen: false },
  modalRepExplanation: { isOpen: false },
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    modalOpen: (state, action) => {
      return {
        ...state,
        [action.payload.modal]: {
          isOpen: true,
          ...action.payload,
        },
      };
    },
    modalClose: (state, action) => {
      return {
        ...state,
        [action.payload.modal]: {
          isOpen: false,
        },
      };
    },
  },
});

export const { modalOpen, modalClose } = modalsSlice.actions;

export default modalsSlice.reducer;
