import { Box } from "@chakra-ui/react";

export const IllustrationEmptyStateCalls = (props) => (
  <Box as="svg" viewBox="0 0 100 100" fill="none" {...props}>
    <path
      d="M50.0084 19.146L56.7282 12.1064C57.7796 11.1575 59.0138 10.7819 60.4309 11.1247C61.7109 11.4675 62.7013 12.4748 63.0213 13.758L65.4441 23.2815L74.8914 20.6104C76.1714 20.2508 77.5428 20.6073 78.4723 21.5429C79.2799 22.48 79.7675 23.8499 79.4018 25.1238L76.7352 34.5726L86.2587 36.9954C87.5387 37.3154 88.5444 38.3059 88.8948 39.5858C89.2301 40.8658 88.7272 42.1 87.9044 43.1514L80.8646 50.0084L87.9044 56.7282C88.7272 57.7796 89.2301 59.0138 88.8948 60.4309C88.5444 61.7109 87.5387 62.7013 86.2587 63.0213L76.7352 65.4441L79.4018 74.8914C79.7675 76.1714 79.2799 77.5428 78.4723 78.4723C77.5428 79.2799 76.1714 79.7675 74.8914 79.4018L65.4441 76.7352L63.0213 86.2587C62.7013 87.5387 61.7109 88.5444 60.4309 88.8949C59.0138 89.2301 57.7796 88.7272 56.7282 87.9044L50.0084 80.8646L43.1514 87.9044C42.1 88.7272 40.8658 89.2301 39.5858 88.8949C38.3058 88.5444 37.3154 87.5387 36.9954 86.2587L34.5726 76.7352L25.1238 79.4018C23.8499 79.7675 22.48 79.2799 21.5429 78.4723C20.6073 77.5428 20.2508 76.1714 20.6104 74.8914L23.2815 65.4441L13.758 63.0213C12.4748 62.7013 11.4675 61.7109 11.1247 60.4309C10.7819 59.0138 11.1575 57.7796 12.1064 56.7282L19.146 50.0084L12.1064 43.1514C11.1575 42.1 10.7819 40.8658 11.1247 39.5858C11.4675 38.3059 12.4748 37.3154 13.758 36.9954L23.2815 34.5726L20.6104 25.1238C20.2508 23.8499 20.6073 22.48 21.5429 21.5429C22.48 20.6073 23.8499 20.2508 25.1238 20.6104L34.5726 23.2815L36.9954 13.758C37.3154 12.4748 38.3058 11.4675 39.5858 11.1247C40.8658 10.7819 42.1 11.1575 43.1514 12.1064L50.0084 19.146Z"
      fill="var(--chakra-colors-primary-2200)"
    />
    <path
      d="M72.4206 31.6445C73.7264 33.1681 73.7264 35.6303 72.4206 37.1539L45.6762 68.3573C44.3703 69.8809 42.26 69.8809 40.9542 68.3573L27.5791 52.7556C26.2736 51.232 26.2736 48.7698 27.5791 47.2462C28.8847 45.7226 31.0013 45.7226 32.3072 47.2462L43.2212 60.0811L67.6985 31.6445C69.0044 30.1185 71.1147 30.1185 72.4206 31.6445Z"
      fill="white"
    />
  </Box>
);
