import { Box } from "@chakra-ui/react";
import { ListItemPerson } from "../../../../../ui";
import { EmptyStatePeopleFollowing, PageTitle } from "../../../../2_Molecules";
import {
  AuthorSearch,
  ListWithEmptyStatePrimary,
} from "../../../../3_Organisms";
import { useUserFollowing, useVerifiedHandle } from "../../../../_Hooks";

export const PagePeopleMainSection = () => {
  const usersFollowing = useUserFollowing();
  const { handleVerifiedClicked } = useVerifiedHandle();

  return (
    <Box d="flex" flexDir="column" flexGrow={1}>
      <PageTitle title={`Following ${usersFollowing.data?.length}`} />
      <Box p={4} d={{ lg: "none" }}>
        <AuthorSearch />
      </Box>
      <ListWithEmptyStatePrimary
        EmptyState={EmptyStatePeopleFollowing}
        data={usersFollowing.data}
      >
        {usersFollowing?.data?.map((item) => (
          <ListItemPerson
            key={item.id}
            {...item}
            isFollowing={true}
            handleFollowClick={() => usersFollowing.unfollowAuthor(item)}
            followIsLoading={usersFollowing.isLoading}
            handleVerifiedClicked={(e) => {
              handleVerifiedClicked(item?.verified);
              e.preventDefault();
            }}
          />
        ))}
      </ListWithEmptyStatePrimary>
    </Box>
  );
};
