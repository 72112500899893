import {
  PageSignInFormWrapper,
  PageSignInFormTitle,
  PageSignInFormSubtitle,
} from "./ui";
import { FormSignIn } from "./components";

export const PageSignInForm = () => (
  <PageSignInFormWrapper>
    <PageSignInFormTitle />
    <PageSignInFormSubtitle />
    <FormSignIn />
  </PageSignInFormWrapper>
);
