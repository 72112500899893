import { Heading, Stack } from "@chakra-ui/react";
import { IllustrationEmptyStateContributions } from "../../1_Atoms";
import { getFirstName } from "../../_Utils";

export const EmptyStateContributionsAuthor = ({ author }) => (
  <>
    <IllustrationEmptyStateContributions
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        {getFirstName(author)} hasn&#8217;t made any contributions
      </Heading>
    </Stack>
  </>
);
