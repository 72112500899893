import { Box, HStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BadgePrimary,
  ButtonPrimary,
  ButtonAccentTwo,
  CardBody,
  CardPrimary,
  CardMediaPermissionDenied,
  ModalContribution,
  VideoPlayer,
  useMakeMediaContribution,
  useMediaRecorder,
  VideoRecorder,
  CardContributionVideoIntro,
} from "../../";
import { modalClose } from "../../_Redux/modalsSlice";

export const ModalVideoContribution = () => {
  const modal = useSelector((state) => state.modals).modalVideoContribution;
  const dispatch = useDispatch();

  const webcamRef = useRef();
  const mediaRecorder = useMediaRecorder(webcamRef);
  const mediaContribution = useMakeMediaContribution();
  const [step, setStep] = useState(0);

  const close = () => {
    mediaRecorder.setBlob();
    dispatch(modalClose({ modal: "modalVideoContribution" }));
    mediaContribution.setContributionComplete(false);
    setStep(0);
  };

  useEffect(() => {
    if (mediaContribution.contributionComplete) {
      close();
    }
  }, [mediaContribution.contributionComplete]);

  return (
    <ModalContribution
      title={modal?.call?.title}
      isOpen={modal.isOpen}
      onClose={() => close()}
    >
      {step === 0 && (
        <Box w="100%">
          <CardContributionVideoIntro
            mx="auto"
            onClick={() => setStep(1)}
            w="100%"
          />
        </Box>
      )}
      {step === 1 && (
        <MediaSourceAvailable>
          {mediaRecorder.url ? (
            <VideoPlayBack
              src={[
                {
                  src: mediaRecorder.url,
                  type: mediaRecorder?.type?.type,
                },
              ]}
              loading={mediaContribution.contributionLoading}
              onResetClick={() => mediaRecorder.setUrl()}
              onConfirm={() =>
                mediaContribution.createVideoContribution(
                  mediaRecorder.blob,
                  mediaRecorder.type.filetype,
                  modal?.call
                )
              }
            />
          ) : (
            <VideoRecorder {...{ mediaRecorder }} ref={webcamRef} />
          )}
        </MediaSourceAvailable>
      )}
    </ModalContribution>
  );
};

const VideoPlayBack = ({ src, onResetClick, onConfirm, loading }) => {
  return (
    <Box w="100%">
      <CardPrimary
        pos="relative"
        w="100%"
        bg="transparent"
        maxW="700px"
        mx="auto"
      >
        <VideoPlayer colorScheme="accenttwo" preload={true} sources={src} />
        <BadgePrimary pos="absolute" top="1rem" left="1rem">
          Contribution
        </BadgePrimary>
        <Box bg="white">
          <CardBody>
            <HStack spacing="1rem">
              <ButtonPrimary flexBasis="50%" onClick={onResetClick}>
                Redo
              </ButtonPrimary>
              <ButtonAccentTwo flexBasis="50%" onClick={onConfirm}>
                {loading ? "Uploading..." : "Use video"}
              </ButtonAccentTwo>
            </HStack>
          </CardBody>
        </Box>
      </CardPrimary>
    </Box>
  );
};

const MediaSourceAvailable = ({ children }) => {
  const [allGood, setAllGood] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => setAllGood(true))
      .catch((err) => setError(err.message));
  }, []);
  return !allGood && !error ? null : allGood ? (
    children
  ) : (
    <Box>
      <CardMediaPermissionDenied mx="auto" maxW="500px" />
    </Box>
  );
};
