export const RulesText = () => (
  <>
    <h1>Rules and Policies</h1>
    <p>
      Colloquy’s role is to enable users to hold a conversation about living
      with disease. Our rules are to ensure all people can participate in the
      public conversation freely and safely. We will not accept any form of
      harassment, discrimination or violent behaviour amongst people expressing
      themselves on Colloquy. Our rules are to ensure all people can participate
      in the public conversation freely and safely.
    </p>
    <h2>Safety</h2>
    <p>
      <strong>Violence:</strong> You may not threaten violence against an
      individual or a group of people.
    </p>
    <p>
      <strong>Child sexual exploitation:</strong> We have zero tolerance for
      child sexual exploitation.
    </p>
    <p>
      <strong>Abuse/harassment:</strong> You may not engage in the targeted
      harassment of someone, or incite other people to do so.
    </p>
    <p>
      <strong>Hateful conduct:</strong> You may not promote violence against,
      threaten, or harass other people on the basis of race, ethnicity, national
      origin, caste, sexual orientation, gender, gender identity, religious
      affiliation, age, disability, or disease.
    </p>
    <p>
      <strong>Suicide or self-harm:</strong> You may not promote or encourage
      suicide or self-harm.
    </p>
    <p>
      <strong>
        Sensitive media, including graphic violence and adult content:
      </strong>{" "}
      You may only post media that is consistent with and relevant to the
      conversation thread.
    </p>
    <p>
      <strong>Illegal or certain regulated goods or services:</strong> You may
      not use our service for any unlawful purpose or in furtherance of illegal
      activities. This includes selling, buying, or facilitating transactions in
      illegal goods or services, as well as certain types of regulated goods or
      services.
    </p>
    <h2>Privacy</h2>
    <p>
      <strong>Private information:</strong> You may not publish or post other
      people's private information.
    </p>
    <p>
      <strong>Nudity and Non-consensual nudity:</strong> You may not post or
      share intimate photos or videos of any kind.
    </p>

    <h2>Authenticity</h2>
    <p>
      <strong>Platform manipulation and spam:</strong> You may not use Colloquy
      services in a manner intended to artificially amplify or suppress
      information or engage in behavior that manipulates or disrupts
      people&apos;s experience on Colloquy.
    </p>
    <p>
      <strong>Misleading and Deceptive Identities:</strong> You may not
      impersonate individuals, groups, or organizations to mislead, confuse, or
      deceive others, nor use a fake identity in a manner that disrupts the
      experience of others on Colloquy.
    </p>
    <p>
      <strong>Copyright and trademark:</strong> You may not violate others’
      intellectual property rights, including copyright and trademark.
    </p>
    <h2>Third-party advertising in video content</h2>
    <p>
      You may not submit, post, or display any content on or through our
      services that includes third-party advertising.
    </p>
  </>
);
