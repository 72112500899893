import React from "react";
import { Outlet } from "react-router-dom";
import { useGroup, useOnboardingData, usePartner } from "../..";
import { Footer, LoadingPrimary } from "../../../ui";
import { RootNotLoggedInWrapper } from "./ui";

export const RootNotLoggedIn = () => {
  const onboarding = useOnboardingData();
  const partner = usePartner();
  const group = useGroup();

  return (
    <RootNotLoggedInWrapper>
      <LoadingPrimary
        isLoading={onboarding.isLoading || partner.isLoading || group.isLoading}
        error={onboarding.error || partner.error || group.error}
      >
        <Outlet />
        <Footer partnerList={partner?.data} footerText={partner?.footerText} />
      </LoadingPrimary>
    </RootNotLoggedInWrapper>
  );
};
