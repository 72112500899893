import { Stack } from "@chakra-ui/react";
import {
  ForgotPasswordFormEmailInput,
  ForgotPasswordFormSubmitButton,
  ForgotPasswordMessage,
  ForgotPasswordFormFormWrapper,
  ForgotPasswordTitle,
} from "./ui";

export const ForgotPasswordFormForm = ({
  register,
  errors,
  onSubmit,
  isLoading,
}) => (
  <ForgotPasswordFormFormWrapper {...{ onSubmit }}>
    <Stack>
      <ForgotPasswordTitle />
      <ForgotPasswordMessage />
    </Stack>
    <ForgotPasswordFormEmailInput {...{ register, errors }} />
    <ForgotPasswordFormSubmitButton {...{ isLoading }} />
  </ForgotPasswordFormFormWrapper>
);
