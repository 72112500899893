import { BsHandThumbsUp } from "react-icons/bs";
import { AudioContributionIntroIconAndText } from "../components";

export const AudioContributionIntroBlockThree = () => (
  <AudioContributionIntroIconAndText
    as={BsHandThumbsUp}
    text="There is nothing to download or install."
    maxW="280px"
  />
);
