import { useEffect, useState } from "react";
import { useGetKeyValuesQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const useContactDetails = () => {
  const keyValuePairCategoryId = 4;
  const [contactText, setContactText] = useState();
  const [contactEmail, setContactEmail] = useState();

  const copy = useBaseApi({
    query: useGetKeyValuesQuery,
    params: [keyValuePairCategoryId],
  });

  useEffect(() => {
    if (copy.data) {
      const text = copy.data.filter((item) => item.key === "Contact Us Text")[0];
      setContactText(text?.value);

      const email = copy.data.filter((item) => item.key === "Email Address")[0];
      setContactEmail(email?.value);
    }
  }, [copy.data]);

  return { ...copy, contactText, contactEmail };
};
