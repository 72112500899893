import { Box } from "@chakra-ui/react";

export const DictaphoneFooter = ({ children }) => (
  <Box
    flexGrow="1"
    mt={-1}
    bg="primary.base"
    p={5}
    d="flex"
    justifyContent="center"
    alignItems="center"
    {...{ children }}
  />
);
