import { useState } from "react";
import {
  useDeleteUserAvatarMutation,
  usePostUserAvatarMutation,
} from "../_Service";
import { useNotifications } from "./useNotifications";

export const useAvatarUpload = (avatarEditor) => {
  const [file, setFile] = useState();
  const [deleteUserAvatar, deleteUserAvatarStatus] =
    useDeleteUserAvatarMutation();
  const [postUserAvatar, postUserAvatarStatus] = usePostUserAvatarMutation();
  const notifications = useNotifications();

  const deleteAvatar = async () => {
    try {
      await deleteUserAvatar();
      notifications.addToast({ action: notifications.AVATAR_UPDATED });
    } catch (error) {
      notifications.addToast({
        action: notifications.GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const clearFile = () => setFile();

  const updateFile = (file) => setFile(file);

  const saveAvatar = async () => {
    if (avatarEditor) {
      try {
        const canvas = avatarEditor.current.getImageScaledToCanvas();
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const formData = new FormData();
        formData.append("file", blob);
        await postUserAvatar(formData).unwrap();
        notifications.addToast({ action: notifications.AVATAR_UPDATED });
        clearFile();
      } catch (error) {
        notifications.addToast({
          action: notifications.GENERIC_ERROR,
          payload: error,
        });
      }
    }
  };

  const replaceAvatar = async () => {
    try {
      await deleteUserAvatar().unwrap();
      if (avatarEditor) {
        const canvas = avatarEditor.current.getImageScaledToCanvas();
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const formData = new FormData();
        formData.append("file", blob);
        await postUserAvatar(formData).unwrap();
        clearFile();
      }
      notifications.addToast({ action: notifications.AVATAR_UPDATED });
    } catch (error) {
      notifications.addToast({
        action: notifications.GENERIC_ERROR,
        payload: error,
      });
    }
  };

  return {
    file,
    deleteAvatar,
    deleteUserAvatarStatus,
    postUserAvatarStatus,
    clearFile,
    updateFile,
    saveAvatar,
    replaceAvatar,
  };
};
