import { MenuItem } from "@chakra-ui/react";

export const MediaSourceDropdownMenuItem = ({ option, onChange, children }) => (
  <MenuItem
    _focus={{ bg: "rgba(255,255,255,0.1)" }}
    _active={{}}
    onClick={() => onChange(option.value)}
    {...{ children }}
  />
);
