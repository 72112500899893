import { useState } from "react";
import { useForm } from "react-hook-form";
import { usePostForgottenPasswordMutation } from "../../../../_Service";
import { FogotPasswordFormWrapper } from "./ui";
import {
  ForgotPasswordFormForm,
  ForgotPasswordFormSuccess,
} from "./components";
import { useNotifications } from "../../../../_Hooks";

const STEPS = { form: "FORM", confirmation: "CONFIRMATION" };

export const ForgotPasswordForm = () => {
  const [postForgottenPassword, status] = usePostForgottenPasswordMutation();
  const [step, setStep] = useState(STEPS.form);
  const { addToast, GENERIC_ERROR } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitForm = async ({ email }) => {
    try {
      await postForgottenPassword(email).unwrap();
      setStep(STEPS.confirmation);
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  return (
    <FogotPasswordFormWrapper>
      {step === STEPS.form && (
        <ForgotPasswordFormForm
          {...{ register, errors }}
          isLoading={status.isLoading}
          onSubmit={handleSubmit(submitForm)}
        />
      )}
      {step === STEPS.confirmation && (
        <ForgotPasswordFormSuccess
          handleTryAgainClick={() => setStep(STEPS.form)}
        />
      )}
    </FogotPasswordFormWrapper>
  );
};
