import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { NotificationGenericError } from "../../";
import { LoaderSpinner } from "../../../ui";
export const LoaderSection = ({ loading, error, children }) => {
  const [mounted, setMounted] = useState();
  useEffect(() => {
    setMounted(true);
  }, []);
  return error ? (
    <Box alignItems="center" flexGrow="1" p="1.5rem">
      <NotificationGenericError {...error} />
    </Box>
  ) : loading ? (
    <Box flexGrow="1" d="flex" bg='gray.100' justifyContent="center" alignItems="center">
      <LoaderSpinner />
    </Box>
  ) : (
    <Box flexGrow="1" flexDir="column" d="flex">
      {mounted && children}
    </Box>
  );
};
