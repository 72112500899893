import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ModalSecondary = ({ isOpen, onClose, children }) => {
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay bg="themegray.3800" />
      <ModalContent bg="none" boxShadow="none">
        {children}
      </ModalContent>
    </Modal>
  );
};
