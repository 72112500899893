import {
  AvatarEditor,
  AvatarEditorModal,
  AvatarPreviewAndButtons,
} from "./components";

export const AvatarUpload = ({
  src,
  name,
  handleDeleteClick,
  handleSaveClick,
  handleCancel,
  handleFileChange,
  isLoading,
  avatarRef,
  file,
}) => (
  <>
    <AvatarPreviewAndButtons {...{ file, src, name, handleDeleteClick, handleFileChange, isLoading }}/>
    <AvatarEditorModal {...{ file, handleCancel }}>
      <AvatarEditor handleCancelClick={handleCancel} {...{ avatarRef, file, handleSaveClick }}/>
    </AvatarEditorModal>
  </>
);
