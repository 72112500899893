import { useCallback, useEffect, useRef, useState } from "react";

export const useMediaRecorder = (ref) => {
  const mediaRecorderRef = useRef();
  const [capturing, setCapturing] = useState();
  const [blob, setBlob] = useState();
  const [type, setType] = useState();
  const [url, setUrl] = useState();

  useEffect(() => {
    const types = [
      { type: "video/webm;codecs=vp8", filetype: "webm" },
      { type: "video/mp4", filetype: "mp4" },
    ];
    const t = types.filter(({ type }) => MediaRecorder.isTypeSupported(type));
    setType(t[0]);
  }, []);

  useEffect(() => {
    if (blob) {
      const blobs = new Blob([blob], {
        type: type.type,
      });
      setUrl(URL.createObjectURL(blobs));
    }
  }, [blob, type]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setBlob(data);
      }
    },
    [setBlob]
  );

  const startCapture = () => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(ref.current.stream, {
      mimeType: type.type,
      videoBitsPerSecond: 1000000,
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  };

  const stopCapture = () => {
    setCapturing(false);
    mediaRecorderRef.current.stop();
  };

  return {
    startCapture,
    stopCapture,
    capturing,
    blob,
    type,
    url,
    setBlob,
    setUrl,
  };
};
