import { formatDistance, format } from "date-fns";
import { getUserFromSessionOrLocalStorage } from "./getUserFromSessionOrLocalStorage";

export const getDisplayName = (author) => {
  if (!author) return "No user provided";
  if (author.onlyDisplayInitials) {
    return `${author.firstName.toUpperCase()[0]}. ${
      author.lastName.toUpperCase()[0]
    }.`;
  }
  return `${author.firstName} ${author.lastName}`;
};

export const getFirstName = (author) => {
  if (!author) return "No user provided";
  if (author.onlyDisplayInitials) {
    return `${author.firstName}${author.lastName}`;
  }
  return author.firstName;
};

export const getBothNames = (author) => {
  if (!author) return "!Author not found";
  return `${author?.firstName} ${author?.lastName}`;
};

export const getRole = (roles) => {
  if (!roles || roles.length === 0) return null;
  const rep = getRep(roles);
  if (rep) return rep;
  return roles.filter((r) => r.title !== "Ambassador")[0];
};

export const getRoleById = (roles, id) => {
  if (!roles || roles.length === 0 || !id) return null;
  return roles.filter((r) => r.id?.toString() === id?.toString())[0];
};

export const getIsAmbassador = (roles) => {
  if (!roles || roles.length === 0) return false;
  return roles?.filter((r) => r.title === "Ambassador")[0] ? true : false;
};

export const getIsRep = (roles) => {
  if (!roles || roles.length === 0) return false;
  return roles?.filter((r) => r.text === "Rep")[0] ? true : false;
};

export const getVerifedDetails = (roles) => {
  const rep = getRep(roles);
  if (rep) {
    return { title: rep?.title, description: "Hello", type: "Rep" };
  }
  if (getIsAmbassador(roles)) {
    return { title: "Ambassador", type: "Ambassador" };
  }
  return null;
};

export const getRep = (roles) => {
  if (!roles || roles.length === 0) return false;
  return roles?.filter((r) => r.text === "Rep")[0];
};

export const getAmbassador = (roles) => {
  if (!roles || roles.length === 0) return false;
  return roles?.filter((r) => r.title === "Ambassador")[0];
};

export const getAvatarSrc = (avatar) => {
  if (!avatar || avatar.length === 0) return null;
  return avatar[0]?.filename;
};

export const getAuthorUrl = (author) => {
  if (!author) return "/";
  const user = getUserFromSessionOrLocalStorage();
  return parseInt(user?.id) === author?.id
    ? "/profile"
    : `/people/${author?.id}`;
};

export const getFormattedCreatedAt = (timestamp) => {
  return formatDistance(new Date(`${timestamp}Z`), Date.now(), {
    addSuffix: true,
  });
};

export const getFormattedAddress = (state, country) => {
  if (country === undefined) return "[Error: could not retrieve location]";
  if (country.id !== 187) return country.title;
  return `${state?.state}${state && ","} ${country?.title}`;
};

export const getFormattedDob = ({ dobDay, dobMonth, dobYear }) => {
  return format(new Date(dobYear, dobMonth - 1, dobDay), "do MMMM yyyy");
};

export const getAuthorIsMe = (author) => {
  const user = getUserFromSessionOrLocalStorage();
  return parseInt(user?.id) === parseInt(author?.id);
};

export const getAmbassadorsList = (authors) => {
  return authors
    ?.filter((author) => getIsAmbassador(author?.roles))
    .sort((a, b) => {
      const x = a?.firstName;
      const y = b?.firstName;
      return x < y ? -1 : y < x ? 1 : 0;
    });
};
