import {
  getUserFromSessionOrLocalStorage,
  transformCall,
  transformContributionLight,
} from "../../_Utils";
import { postloginBaseParams } from "./config";

export const getTimeline = {
  query: () => ({
    url: `/Timeline/${getUserFromSessionOrLocalStorage().id}`,
    params: {
      ...postloginBaseParams,
      currentUserId: getUserFromSessionOrLocalStorage().id,
    },
  }),
  transformResponse: (res) => {
    const { call, contribution } = res;

    return {
      call: call?.map((item) => {
        return { type: "call", ...transformCall(item) };
      }),
      contribution: contribution?.map((item) => {
        return { type: "contribution", ...transformContributionLight(item) };
      }),
    };
  },
  providesTags: (result) => {
    return result ? [{ ...result, type: "Timeline" }] : [{ type: "Timeline" }];
  },
};
