import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonNext = ({ label = "Continue", ...props }) => (
  <Button
    bg="accentone.1700"
    _hover={{ bg: "accentone.2000" }}
    _active={{ bg: "accentone.2000" }}
    _focus={{ bg: "accentone.2000" }}
    color="white"
    {...props}
  >
    {label}
  </Button>
);
