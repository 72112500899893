import React, { forwardRef, useState } from "react";
import { Button, Icon, InputRightElement } from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FormInput } from "../FormInput/FormInput";

export const FormInputPassword = forwardRef(
  ({ disabled, size, ...props }, ref) => {
    const [show, setShow] = useState(false);
    return (
      <FormInput
        disabled={disabled}
        pr="3.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
        {...{ ref, size }}
        {...props}
      >
        <InputRightElement width="3.5rem">
          <Button
            disabled={disabled}
            color="gray.600"
            size="sm"
            h={size === "sm" ? "1.4rem" : "1.75rem"}
            onClick={() => setShow(!show)}
          >
            {!show && <Icon as={FaEye} />}
            {show && <Icon as={FaEyeSlash} />}
          </Button>
        </InputRightElement>
      </FormInput>
    );
  }
);
