import { Text } from "@chakra-ui/react";

export const FormSuccessMessage = ({ handleTryAgainClick }) => (
  <Text color="white" textAlign="center">
    If you did not recieve instructions, please check your spam filter or{" "}
    <Text
      as="button"
      fontWeight="body.bold"
      textDecor="underline"
      onClick={handleTryAgainClick}
    >
      try again
    </Text>
  </Text>
);
