import { ModalOverlay as ChakraModalOverlay } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { rgba } from "polished";

export const ModalOverlay = (props) => {
  const { colors } = useTheme();
  return (
    <ChakraModalOverlay bg={rgba(colors?.themegray?.base, 0.9)} {...props} />
  );
};
