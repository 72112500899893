import { Text } from "@chakra-ui/react";

export const LatestCallsSideBarTitle = () => (
  <Text
    as="h2"
    fontWeight="semibold"
    fontSize="1.1rem"
    borderBottom="solid 1px"
    borderColor="gray.300"
    pl='0.5'
    mb="5"
  >
    Latest calls
  </Text>
);
