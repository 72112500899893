import React from "react";
import { Box } from "@chakra-ui/react";

export const IllustrationClap = (props) => (
  <Box as="svg" viewBox="0 0 56.9 56.9" {...props}>
    <g id="b">
      <g id="c">
        <g>
          <g>
            <path
              className="g"
              fill='#ffba94'
              d="M25.94,15.11c.01-.97-.77-1.76-1.74-1.76-.73,0-1.38,.45-1.63,1.14l-8.05,21.8c-.19,.46-.5,.86-.9,1.16l-6.84,5.18c-.57,.43-.65,1.25-.17,1.78l4.98,5.58c.34,.39,.9,.51,1.38,.31l8.05-3.34c2.72-1.13,4.6-3.33,4.82-6.24l-.02-.34,.12-25.29Z"
            />
            <path
              className="f"
              fill='#e58d3e'
              d="M12.5,51.31c-.59,0-1.17-.25-1.58-.71l-4.98-5.58c-.39-.44-.59-1.03-.53-1.62,.06-.59,.36-1.13,.83-1.48l6.84-5.18c.27-.21,.48-.48,.61-.79L21.72,14.18c.38-1.03,1.38-1.73,2.48-1.73,.71,0,1.38,.28,1.88,.78,.5,.5,.77,1.17,.76,1.88l-.12,25.28,.02,.34v.06c-.24,3.13-2.2,5.69-5.38,7l-8.05,3.34c-.26,.11-.54,.16-.81,.16ZM24.2,14.25c-.35,0-.66,.22-.78,.55l-8.05,21.8c-.26,.64-.67,1.17-1.2,1.57l-6.84,5.18c-.1,.07-.12,.17-.12,.22,0,.05,0,.15,.08,.24l4.98,5.58c.09,.1,.23,.13,.36,.08l8.05-3.34c2.5-1.04,4.05-3.01,4.26-5.42l-.02-.27,.12-25.35c0-.22-.08-.44-.24-.6-.16-.16-.37-.25-.59-.25Z"
            />
            <path
              className="f"
              fill='#e58d3e'
              d="M16.06,49.93c-.35,0-.69-.21-.83-.56-.19-.46,.03-.99,.49-1.18,.03-.01,2.69-1.12,6.72-3.09,3.45-1.69,2.51-7.71,2.5-7.77,0-.05-.01-.1-.01-.15v-7.43c0-.31-.25-.56-.56-.56-.23,0-.43,.14-.52,.35l-3.18,7.97c-.18,.46-.71,.69-1.17,.5-.46-.18-.69-.71-.5-1.17l3.18-7.97c.36-.9,1.22-1.49,2.19-1.49,1.3,0,2.36,1.06,2.36,2.36v7.36c.12,.84,.95,7.43-3.5,9.61-4.08,2-6.79,3.13-6.82,3.14-.11,.05-.23,.07-.35,.07Z"
            />
            <path
              className="i"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M9.4,52.94l-5.7-8.08c-.83-1.18-.55-2.81,.63-3.64l3.05-2.15c1.18-.83,2.81-.55,3.64,.63l5.7,8.08c.83,1.18,.55,2.81-.63,3.64l-3.05,2.15c-1.18,.83-2.81,.55-3.64-.63"
            />
            <path
              className="h"
              fill='#995817'
              d="M30.96,15.11c-.01-.97,.77-1.76,1.74-1.76,.73,0,1.38,.45,1.63,1.14l8.05,21.8c.19,.46,.5,.86,.9,1.16l6.84,5.18c.57,.43,.65,1.25,.17,1.78l-4.98,5.58c-.34,.39-.9,.51-1.38,.31l-8.05-3.34c-2.72-1.13-4.55-3.69-4.78-6.59l-.02,.02-.12-25.29Z"
            />
            <path
              className="j"
              fill='#60350f'
              d="M44.39,51.31c-.27,0-.55-.05-.81-.16l-8.05-3.34c-2.97-1.23-4.98-3.95-5.31-7.13-.03-.09-.05-.18-.05-.28l-.12-25.28c0-.71,.27-1.38,.76-1.88,.5-.5,1.17-.78,1.88-.78,1.1,0,2.1,.69,2.48,1.73l8.05,21.8c.12,.28,.33,.55,.6,.76l6.84,5.18c.47,.36,.77,.9,.83,1.48,.06,.59-.14,1.18-.53,1.62l-4.98,5.58c-.41,.46-.99,.71-1.58,.71Zm-12.42-11.14s.02,.09,.02,.14c.2,2.6,1.82,4.83,4.22,5.83l8.05,3.34c.12,.05,.27,.02,.35-.08l4.98-5.58c.08-.09,.08-.19,.08-.24,0-.05-.03-.15-.12-.22l-6.84-5.18c-.53-.4-.94-.93-1.19-1.54l-8.06-21.84c-.2-.54-.97-.7-1.38-.3-.16,.16-.24,.37-.24,.6h0l.12,25.07Z"
            />
            <path
              className="j"
              fill='#60350f'
              d="M40.84,49.93c-.12,0-.23-.02-.35-.07-.03-.01-2.74-1.14-6.82-3.14-4.45-2.18-3.62-8.77-3.5-9.61v-7.36c0-1.3,1.06-2.36,2.36-2.36,.97,0,1.83,.58,2.19,1.49l3.18,7.97c.18,.46-.04,.99-.5,1.17-.47,.19-.99-.04-1.17-.5l-3.18-7.97c-.08-.21-.29-.35-.52-.35-.31,0-.56,.25-.56,.56v7.43s0,.1-.01,.15c0,.06-.95,6.08,2.5,7.77,4.02,1.97,6.69,3.08,6.71,3.09,.46,.19,.68,.72,.49,1.18-.14,.35-.48,.56-.83,.56Z"
            />
            <path
              className="i"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M47.5,52.94l5.7-8.08c.83-1.18,.55-2.81-.63-3.64l-3.05-2.15c-1.18-.83-2.81-.55-3.64,.63l-5.7,8.08c-.83,1.18-.55,2.81,.63,3.64l3.05,2.15c1.18,.83,2.81,.55,3.64-.63"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M28.27,8.71c-.5,0-.9-.4-.9-.9V2.86c0-.5,.4-.9,.9-.9s.9,.4,.9,.9V7.8c0,.5-.4,.9-.9,.9Z"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M20.9,10.79c-.27,0-.54-.12-.72-.36l-2.85-3.75c-.3-.4-.22-.96,.17-1.26,.4-.3,.96-.23,1.27,.17l2.85,3.75c.3,.4,.22,.96-.17,1.26-.16,.12-.36,.18-.55,.18Z"
            />
            <path
              className="e"
              fill="var(--chakra-colors-accenttwo-base)"
              d="M35.64,10.79c-.19,0-.38-.06-.55-.18-.4-.3-.47-.87-.17-1.26l2.85-3.75c.3-.4,.87-.47,1.27-.17,.4,.3,.47,.87,.17,1.26l-2.85,3.75c-.18,.23-.45,.36-.72,.36Z"
            />
          </g>
          <rect className="d" fill="none" width="56.9" height="56.9" />
        </g>
      </g>
    </g>
  </Box>
);
