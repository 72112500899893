import React, { useRef } from "react";
import { Box } from "@chakra-ui/react";
import {
  getAvatarSrc,
  getDisplayName,
  useProfile,
  useAvatarUpload,
} from "../../";
import { AvatarUpload } from "../../../ui";

export const AccountAvatarUpload = () => {
  const avatarEditor = useRef();
  const avatarUpload = useAvatarUpload(avatarEditor);
  const profile = useProfile();
  return (
    <Box p="6">
      <AvatarUpload
        avatarRef={avatarEditor}
        src={getAvatarSrc(profile?.data?.avatar)}
        name={getDisplayName(profile?.data)}
        file={avatarUpload.file}
        isLoading={
          profile?.isFetching ||
          avatarUpload?.deleteUserAvatarStatus?.isLoading ||
          avatarUpload?.postUserAvatarStatus?.isLoading
        }
        handleCancel={avatarUpload.clearFile}
        handleFileChange={(fileObj) => {
          avatarUpload.updateFile(fileObj);
        }}
        handleDeleteClick={avatarUpload.deleteAvatar}
        handleSaveClick={() => {
          avatarUpload.replaceAvatar();
        }}
      />
    </Box>
  );
};
