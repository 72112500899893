import { Button } from "@chakra-ui/react";

export const ButtonBaseWrapper = ({ size, isLoading, ...props }) => {
  const sizeVariants = {
    xs: { fontSize: "xs" },
    sm: { fontSize: "xs" },
    md: { fontSize: "sm" },
    lg: { fontSize: "sm" },
  };
  return (
    <Button
      pos="relative"
      overflow="hidden"
      textTransform="uppercase"
      fontWeight="body.black"
      _disabled={{
        opacity: isLoading ? 1 : 0.5,
        cursor: "default",
      }}
      disabled={isLoading}
      {...{ size }}
      {...sizeVariants[size]}
      {...props}
    />
  );
};
