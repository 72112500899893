import React from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useSignUp } from "../..";

export const SignUpConfirmation = () => {
  const signup = useSignUp();
  return (
    <Container maxW="container.md">
      <Box
        p="2rem"
        boxShadow="xl"
        borderRadius="lg"
        overflow="hidden"
        w="100%"
        bg="white"
        pos="relative"
      >
        <Box mb="2rem" textAlign="center">
          <Heading color="primary.base" fontSize="1.5rem" mb="0.5rem">
            You're almost ready, congrats!
          </Heading>
          <Text maxW="350px" mx="auto" mb="1rem">
            Look out for an email to confirm your account. After that you can
            sign in and start sharing your experiences.
          </Text>
          <Stack mx="auto" maxW="200px">
            <Button as={Link} to="/sign-in">
              Sign in
            </Button>
            <Button onClick={() => signup.reset()}>
              Register another user
            </Button>
          </Stack>
        </Box>
        <Image
          pos="relative"
          marginTop="-150px"
          bottom="-150px"
          w="100%"
          mx="auto"
          maxW="350px"
          src="/images/brianhappy.svg"
          alt="Happy Brian"
        />
      </Box>
    </Container>
  );
};
