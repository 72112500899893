import { Card } from "../../../1_Atoms";
import {
  CardAudioBlock,
  CardTextBlock,
  CardVideoBlock,
} from "../../../2_Molecules";
import { CardContributionFooter, CardContributionHeader } from "./components";

export const CardContribution = ({
  audio,
  video,
  text,
  author,
  handleVerifiedClicked,
  isSaved,
  saveIsLoading,
  handleSavedClick,
  helpfulType,
  handleHelpfulClick,
  menuItems,
  updatedAt,
}) => (
  <Card maxW="500px" w="100%">
    <CardContributionHeader
      {...{ author, menuItems, handleVerifiedClicked, updatedAt }}
    />
    <CardAudioBlock variant="contribution" {...{ audio }} />
    <CardTextBlock variant="contribution" {...{ text }} />
    <CardVideoBlock variant="contribution" {...{ video }} />
    <CardContributionFooter
      {...{
        isSaved,
        saveIsLoading,
        handleSavedClick,
        helpfulType,
        handleHelpfulClick,
      }}
    />
  </Card>
);
