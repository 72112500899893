import { useGetGenderListQuery } from "../_Service";
import { getItemFromArrayById } from "../_Utils";
import { useBaseApi } from "./useBaseApi";

export const useGenderList = () => {
  const { data, ...rest } = useBaseApi({ query: useGetGenderListQuery });
  const getGenderById = (id) => getItemFromArrayById(id, data);
  return {
    data,
    ...rest,
    getGenderById,
  };
};
