import PropTypes from "prop-types";
import {
  ButtonFollowFollowing,
  ButtonFollowFollow,
  ButtonFollowWrapper,
} from "./ui";

export const ButtonFollow = ({ isFollowing, isLoading, ...props }) => (
  <ButtonFollowWrapper {...{ isFollowing, isLoading }} {...props}>
    <ButtonFollowFollowing {...{ isFollowing }} />
    {!isFollowing && <ButtonFollowFollow />}
  </ButtonFollowWrapper>
);

ButtonFollow.propTypes = {
  isFollowing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
