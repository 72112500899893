import { Box } from "@chakra-ui/react";

export const HeroCallWrapper = ({ children }) => (
  <Box
    maxW="100%"
    bg="white"
    borderBottom="solid 1px"
    borderBottomColor="themegray.400"
  >
    {children}
  </Box>
);
