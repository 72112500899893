import { Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CardAuthor, ContributeButtonBar } from "../../../../../2_Molecules";
import {
  CardCallPrimaryFooterWrapper,
  CardContributionCount,
  CardTitle,
} from "./ui";

export const CardCallPrimaryFooter = ({
  author,
  handleVerifiedClicked,
  title,
  contributionCount,
  handleVideoContributionClick,
  handleAudioContributionClick,
  handleTextContributionClick,
  to,
}) => (
  <CardCallPrimaryFooterWrapper>
    <Stack spacing={4}>
      <CardAuthor {...author} {...{ handleVerifiedClicked }} />
      <Link {...{ to }}>
        <CardTitle {...{ title }} />
      </Link>
      <CardContributionCount {...{ contributionCount }} />
      <ContributeButtonBar
        {...{
          handleVideoContributionClick,
          handleAudioContributionClick,
          handleTextContributionClick,
        }}
      />
    </Stack>
  </CardCallPrimaryFooterWrapper>
);
