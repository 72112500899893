import { Box } from "@chakra-ui/react";

export const AvatarEditorWrapper = ({ children }) => (
  <Box
    d="flex"
    flexDir="column"
    alignItems="center"
    sx={{
      canvas: {
        h: { base: "200px!important", sm: "300px!important" },
        w: { base: "200px!important", sm: "300px!important" },
      },
    }}
    {...{ children }}
  />
);
