import { Text } from "@chakra-ui/react";

export const CardCallSmallTitle = ({ title, ...props }) => (
  <Text
    fontWeight="body.bold"
    lineHeight="1.4"
    fontSize="0.9rem"
    mb="0.5rem"
    _groupHover={{ textDecor: "underline" }}
    {...props}
  >
    {title}
  </Text>
);
