import { HStack, Stack } from "@chakra-ui/react";
import { memo } from "react";
import { Link } from "react-router-dom";
import { ButtonVerified } from "../../../1_Atoms";
import {
  CardAuthorAvatar,
  CardAuthorName,
  CardAuthorRole,
  CardAuthorWrapper,
} from "./ui";

export const CardAuthor = memo(
  ({
    displayName,
    roleTitle,
    avatarSrc,
    to = "/",
    verified,
    isCompany,
    handleVerifiedClicked = () => {},
  }) => (
    <Link {...{ to }}>
      <CardAuthorWrapper>
        <CardAuthorAvatar {...{ avatarSrc, displayName }} />
        <Stack spacing={0}>
          <HStack spacing={1}>
            <CardAuthorName {...{ displayName }} />
            {verified && (
              <ButtonVerified
                {...verified}
                onClick={(e) => {
                  e.preventDefault();
                  handleVerifiedClicked();
                }}
              />
            )}
          </HStack>
          <CardAuthorRole {...{ roleTitle, isCompany }} />
        </Stack>
      </CardAuthorWrapper>
    </Link>
  )
);
