import { forwardRef } from "react";
import { Box, FormLabel } from "@chakra-ui/react";
import { FormError, FormDobInput } from "../../../1_Atoms";

export const FormControlInputDob = forwardRef(
  ({ label, reverse, error, size, ...props }, ref) => {
    const errorColor = reverse ? "accentone.1500" : "red.500";
    return (
      <Box textAlign="left">
        <FormLabel
          mb={1}
          fontSize="sm"
          fontWeight="body.bold"
          color={reverse ? "white" : "inherit"}
        >
          {label}
        </FormLabel>
        <FormDobInput {...{ ref, reverse, size }} {...props} />
        {error && <FormError color={errorColor} {...{ error }} />}
      </Box>
    );
  }
);
