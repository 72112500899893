import React, { useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Flex, Container, useDisclosure } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import {
  NavigationPrimary,
  NavDrawerPrimary,
  useCountryList,
  useStateList,
  LoaderLoggedIn,
  ModalDeleteContribution,
  ModalVerificationExplanation,
  useProfile,
  ModalAudioContribution,
  ModalReportAuthor,
  ModalTextContribution,
  ModalVideoContribution,
  ModalRepExplanation,
  useTimeline,
} from "../";

export const RootLoggedIn = () => {
  const secondaryNavDisclosure = useDisclosure();
  const { pathname } = useLocation();
  const stateList = useStateList();
  const countries = useCountryList();
  const profile = useProfile();
  const timeline = useTimeline();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <LoaderLoggedIn
        loading={
          countries.isLoading || stateList.isLoading || profile.isLoading
        }
        error={
          countries.error || stateList.error || profile.error || timeline.error
        }
      >
        <Box
          flexGrow="1"
          d="flex"
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <ModalVerificationExplanation />
          <ModalDeleteContribution />
          <ModalReportAuthor />
          <ModalVideoContribution />
          <ModalTextContribution />
          <ModalAudioContribution />
          <ModalRepExplanation />
          <NavDrawerPrimary {...secondaryNavDisclosure}>
            <Container
              d="flex"
              flexDir="column"
              flexGrow="1"
              maxW="container.xl"
              px="0"
            >
              <Flex flexGrow="1">
                <NavigationPrimary>
                  <AnimatePresence exitBeforeEnter>
                    <Outlet />
                  </AnimatePresence>
                </NavigationPrimary>
              </Flex>
            </Container>
          </NavDrawerPrimary>
        </Box>
      </LoaderLoggedIn>
    </>
  );
};
