import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import {
  LoaderSection,
  PageTitle,
  useProfile,
  useRoleList,
  getRole,
  getRoleById,
} from "../../";
import { FormControlSelect } from "../../../ui";

export const PageAccountRole = () => {
  const { data, error, updateRole, postUserRoleStatus, deleteUserRoleStatus } =
    useProfile();
  const roles = useRoleList();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  useEffect(() => {
    if (data) {
      const { role } = data;
      reset({
        roleId: getRole(role)?.id?.toString(),
      });
    }
  }, [data, roles.data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Role" />
      <LoaderSection
        loading={!data || !roles.data}
        error={error || roles.error}
      >
        <Box p="1.5rem">
          {data && data?.role && (
            <form
              onSubmit={handleSubmit((formData) =>
                updateRole({
                  newRole: getRoleById(roles?.data, formData?.roleId),
                  currentRole: getRole(data?.role),
                })
              )}
            >
              <Stack spacing="2rem">
                <Stack>
                  <FormControlSelect
                    {...register("roleId", {
                      required: "Role is required",
                    })}
                    loading={roles.isLoading}
                    error={errors.roleId?.message}
                    isInvalid={errors.roleId?.message}
                    label="Role"
                    defaultValue={getRole(data?.role)?.id}
                  >
                    <option value="" disabled>
                      Select role
                    </option>
                    {roles.data?.map(({ id, title }, i) => (
                      <option key={i} value={id}>
                        {title}
                      </option>
                    ))}
                  </FormControlSelect>
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || postUserRoleStatus.isLoading}
                >
                  {postUserRoleStatus.isLoading && "Loading"}
                  {!isDirty && !postUserRoleStatus.isLoading && "Up to date"}
                  {isDirty && !postUserRoleStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
