import { HStack, Stack } from "@chakra-ui/react";
import { ButtonVerified } from "../../../../../1_Atoms";
import {
  HeroPersonAvatar,
  HeroPersonDetailsWrapper,
  HeroPersonName,
  HeroPersonRole,
} from "./ui";

export const HeroPersonDetails = ({
  displayName,
  avatarSrc,
  verified,
  handleVerifiedClicked,
  roleTitle,
  isCompany,
}) => (
  <HeroPersonDetailsWrapper>
    <HStack spacing={6}>
      <HeroPersonAvatar {...{ displayName, avatarSrc }} />
      <Stack spacing={0}>
        <HStack spacing={1}>
          <HeroPersonName {...{ displayName }} />
          {verified && (
            <ButtonVerified {...verified} onClick={handleVerifiedClicked} />
          )}
        </HStack>
        <HeroPersonRole {...{ roleTitle, isCompany }} />
      </Stack>
    </HStack>
  </HeroPersonDetailsWrapper>
);
