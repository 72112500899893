import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

export const LayoutTwoColumn = ({ children, ...props }) => {
  const ih = useRef();
  const [offset, setOffset] = useState();

  useEffect(() => {
    updateOffset();
    window.addEventListener("resize", updateOffset);
    return () => window.removeEventListener("resize", updateOffset);
  }, []);

  const updateOffset = () => {
    const h = ih.current.offsetHeight;
    const b = document.body.clientHeight;
    const o = h > b ? (h - b) * -1 - 20 : 0;
    setOffset(o);
  };

  return (
    <Box
      d="flex"
      id="LayoutTwoColumn"
      flexGrow="1"
      borderLeft={{ sm: "solid 1px" }}
      borderLeftColor={{ sm: "gray.200" }}
      {...props}
    >
      <Box
        d="flex"
        flexGrow="1"
        borderRight="solid 1px"
        minH="100%"
        borderRightColor="gray.200"
      >
        {children[0]}
      </Box>
      <Box
        d={{ base: "none", lg: "block" }}
        w={{ lg: "280px", xl: "330px" }}
        flexShrink="0"
      >
        <Box top={`${offset + 80}px`} position="sticky" ref={ih}>
          {children[1]}
        </Box>
      </Box>
    </Box>
  );
};
