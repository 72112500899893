import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const CardAuthorWrapper = ({ to, children }) => (
  <Box
    as={Link}
    {...{ to }}
    bg="white"
    borderRadius="lg"
    border="solid 1px"
    borderColor="themegray.500"
    _hover={{ bg: "themegray.100", transition: "background-color 0.2s ease" }}
    textAlign="center"
    d="flex"
    flexDir="column"
    alignItems="center"
    gap={3}
    px={4}
    py={6}
  >
    {children}
  </Box>
);
