import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FormCheckbox, FormControlInput } from "../../../ui";
import { FormControlInputPassword } from "../../../ui/2_Molecules/Form/FormControlInput/FormControlInputPassword";
import { useSignUp } from "../../_Hooks";
import { modalOpen } from "../../_Redux/modalsSlice";
import { config, formValidation, validationDictionary } from "../../_Utils";
import { ButtonBack } from "./ButtonBack";
import { ButtonNext } from "./ButtonNext";

export const SignUpStepFour = ({ onPrevClicked, active }) => {
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const signUp = useSignUp();

  const onSubmit = (data) => {
    signUp.updateValues(data);
    signUp.signUp({ ...data, ...signUp.values });
  };

  useEffect(() => {
    if (signUp.values) {
      const { username, termsAgreed } = signUp.values;
      reset({
        username,
        termsAgreed,
      });
    }
  }, [signUp.values, reset]);

  const dispatch = useDispatch();

  return (
    <Box color="white">
      <Heading mb="2rem">Account details</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8}>
          <Stack spacing={6}>
            <FormControlInput
              {...register("username", {
                required: "Email is required",
                validate: formValidation.isValidEmail,
              })}
              disabled={!active || signUp.isLoading}
              error={errors.username?.message}
              isInvalid={errors.username?.message ? true : false}
              reverse
              placeholder="mail@website.com"
              label="Email"
            />
            <FormControlInputPassword
              {...register("password", {
                required: validationDictionary.PASSWORD_REQUIRED,
                validate: formValidation.isValidPassword,
              })}
              disabled={!active || signUp.isLoading}
              error={errors.password?.message}
              isInvalid={errors.password?.message ? true : false}
              reverse
              placeholder={`Min. ${config.minimumPasswordLength} characters`}
              label="Password"
            />
          </Stack>
          <Box d="flex" alignItems="flex-start">
            <Controller
              name="termsAgreed"
              control={control}
              rules={{ required: "You must agree to the terms to proceed" }}
              render={({ field, fieldState: { error } }) => (
                <FormCheckbox
                  {...field}
                  textAlign="left"
                  checked={field.value}
                  error={error?.message}
                  errorColor="accentone.1500"
                  isDisabled={!active || signUp.isLoading}
                >
                  I agree to the{" "}
                  <Text
                    as="button"
                    fontWeight="body.bold"
                    d="inline-block"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      dispatch(modalOpen({ modal: "modalTerms" }));
                    }}
                  >
                    terms and conditions
                  </Text>
                </FormCheckbox>
              )}
            />
          </Box>
          <div>
            <ButtonBack
              disabled={!active || signUp.isLoading}
              onClick={onPrevClicked}
            />
            <ButtonNext
              disabled={!active || signUp.isLoading}
              type="submit"
              label={signUp.isLoading ? "Loading..." : "Sign up"}
            />
          </div>
        </Stack>
      </form>
    </Box>
  );
};
