import { LayoutTwoColumn } from "../../2_Molecules";
import { PageCallMainSection, PageCallSidebar } from "./components";

export const PageCall = () => {
  return (
    <LayoutTwoColumn>
      <PageCallMainSection />
      <PageCallSidebar />
    </LayoutTwoColumn>
  );
};
