import { useEffect, useState } from "react";
import { useGetKeyValuesQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const OnBoardData = [
  "/images/onboardingreverse_01.svg",
  "/images/onboardingreverse_02.svg",
  "/images/onboardingreverse_03.svg",
  "/images/onboardingreverse_04.svg",
  "/images/onboardingreverse_05.svg",
  "/images/onboardingreverse_06.svg",
];

export const useOnboardingData = () => {
  const [steps, setSteps] = useState();
  const keyValuePairCategoryId = 1;
  const { data, ...onboarding } = useBaseApi({
    query: useGetKeyValuesQuery,
    params: [keyValuePairCategoryId],
  });
  
  useEffect(() => {
    if (data) {
      const maxStepNumber = data.reduce((a, b) =>
        a.stepNumber < b.stepNumber ? a.stepNumber : b.stepNumber
      );
      let mapped = [];

      for (let i = 0; i < maxStepNumber; i++) {
        const info = data.filter((item) => item.stepNumber === i + 1);
        const title = info.filter((item) => item.key === "Title")[0];
        const text = info.filter((item) => item.key === "Text")[0];
        const src = OnBoardData[i];
        mapped[i] = { id: i, title: title?.value, text: text?.value, src };
      }
      setSteps(mapped);
    }
  }, [data]);

  return { data, steps, ...onboarding };
};
