import { Box } from "@chakra-ui/react";

export const DictaphoneWrapper = ({ children }) => (
  <Box
    d="flex"
    flexDir='column'
    h="370px"
    maxW="600px"
    mx="auto"
    borderRadius="lg"
    overflow="hidden"
    transform="translateZ(0)"
    boxShadow="lg"
    {...{ children }}
  />
);
