import { ContributionAudioPlayer } from "../../../../../../../../ui/2_Molecules/ContributionAudioPlayer/ContributionAudioPlayer";
import {
  AudioContributionPlaybackButtonRedo,
  AudioContributionPlaybackButtonSave,
  AudioContributionPlaybackButtonStack,
  AudioContributionPlaybackWrapper,
} from "./ui";
export const AudioContributionPlayback = ({
  url,
  handleRedoRecordingClick,
  handleSaveRecordingClick,
  type,
  isLoading,
  ...props
}) => {
  return (
    <AudioContributionPlaybackWrapper {...props}>
      {url && (
        <ContributionAudioPlayer sources={[{ src: url, type: type?.type }]} />
      )}
      <AudioContributionPlaybackButtonStack>
        <AudioContributionPlaybackButtonRedo
          {...{ handleRedoRecordingClick, isLoading }}
        />
        <AudioContributionPlaybackButtonSave
          {...{ handleSaveRecordingClick, isLoading }}
        />
      </AudioContributionPlaybackButtonStack>
    </AudioContributionPlaybackWrapper>
  );
};
