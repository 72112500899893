import PropTypes from "prop-types";
import { ButtonBase } from "../ButtonBase/ButtonBase";

export const ButtonDefault = (props) => <ButtonBase {...props} />;

ButtonDefault.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingLabel: PropTypes.string,
  hasLoadingLabel: PropTypes.bool,
};
