import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonSavePostSaved = ({ isSaved }) => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate={isSaved ? "show" : "hide"}
    color="white"
  >
    Saved
  </Box>
);
