import { CardContribution } from "../CardContribution/CardContribution";
import { CardContributionNestedCall } from "./components";

export const CardContributionNested = ({
  call,
  handleVerifiedClicked,
  ...props
}) => (
  <CardContributionNestedCall {...{ handleVerifiedClicked }} {...call}>
    <CardContribution {...{ handleVerifiedClicked }} {...props} />
  </CardContributionNestedCall>
);
