import { Heading, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { CardPrimary, CardBody } from "../../";

export const CardMediaPermissionDenied = (props) => (
  <CardPrimary {...props}>
    <CardBody>
      <Heading fontSize="1.1rem" mb="1rem" textAlign="center">
        We can't seem to access your device.
      </Heading>
      <Text mb="1rem">
        Please enable access in browser settings or refresh the page to try
        again. Nothing is saved without your permission.
      </Text>
      <Text>
        If you still have any issues, please{" "}
        <Link textDecor="underline" as={RouterLink} to="/contact">
          contact us.
        </Link>
      </Text>
    </CardBody>
  </CardPrimary>
);
