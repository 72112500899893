import { CardCallPrimary } from "../CardCallPrimary/CardCallPrimary";
import { CardCallWide } from "../CardCallWide/CardCallWide";
import { CardContribution } from "../CardContribution/CardContribution";
import { CardContributionNested } from "../CardContributionNested/CardContributionNested";

const CARD_CALL = {
  primary: CardCallPrimary,
  wide: CardCallWide
};
const CARD_CONTRIBUTION = {
  primary: CardContribution,
  nested: CardContributionNested
};

const CARD_TYPES = {
  call: CARD_CALL,
  contribution: CARD_CONTRIBUTION,
};

export const CardSelector = ({ type = "call", variant = "primary", ...props }) => {
  if (!CARD_TYPES[type]) return <div>No card type</div>;
  if (!CARD_TYPES[type][variant]) return <div>No card variant</div>;
  const Card = CARD_TYPES[type][variant];
  return <Card {...props} />;
};
