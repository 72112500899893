import { Heading, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { BsBook, BsHandThumbsUp, BsMegaphone } from "react-icons/bs";
import { CardPrimary } from "../../1_Atoms/Cards/CardPrimary";
import { CardBody } from "../../1_Atoms/Cards/CardBody";
import { ButtonAccentTwo } from "../../1_Atoms";

export const CardContributionVideoIntro = ({ onClick, ...props }) => (
  <CardPrimary maxW="500px" bg="white" py="2rem" {...props}>
    <CardBody>
      <Stack spacing="2.5rem" alignItems="center">
        <Stack spacing="2rem">
          <Heading as="h2" fontSize="1.1rem" textAlign="center">
            How it works
          </Heading>
          <IconAndText
            as={BsMegaphone}
            text="Share your experience by recording a video clip under 2 minutes."
            maxW="300px"
          />
          <IconAndText
            as={BsBook}
            text="Help others to learn more about their condition."
            maxW="300px"
          />
          <IconAndText
            as={BsHandThumbsUp}
            text="There is nothing to download or install."
            maxW="250px"
          />
        </Stack>
        <ButtonAccentTwo onClick={onClick} alignSelf="center" minW="200px">
          Let's go
        </ButtonAccentTwo>
      </Stack>
    </CardBody>
  </CardPrimary>
);

const IconAndText = ({ text, as, ...props }) => (
  <HStack spacing="2rem" {...props}>
    <Icon fontSize="3rem" {...{ as }} />
    <Text>{text}</Text>
  </HStack>
);
