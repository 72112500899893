import { Button } from "@chakra-ui/react";

export const ButtonAccentTwo = (props) => (
  <Button
    h="35px"
    textTransform="uppercase"
    fontSize="0.75rem"
    fontWeight="body.black"
    letterSpacing="0.1px"
    bg="accenttwo.2200"
    color='white'
    _hover={{ bg: "accenttwo.2600" }}
    {...props}
  />
);
