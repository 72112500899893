import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAudioDeviceId,
  setDevices,
  setVideoDeviceId,
} from "../_Redux/videoConfigSlice";

export const useMediaSources = () => {
  const [getUserMediaCalled, setGetUserMediaCalled] = useState();
  const dispatch = useDispatch();
  const videoConfig = useSelector((state) => state.videoconfig);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() => setGetUserMediaCalled(true))
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (getUserMediaCalled) {
      navigator.mediaDevices.enumerateDevices().then((res) => {
        let devices = [];
        res.forEach((_device) => {
          var device = {};
          for (var d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === "audio") {
            device.kind = "audioinput";
          }

          if (device.kind === "video") {
            device.kind = "videoinput";
          }

          if (!device.deviceId) {
            device.deviceId = device.id;
          }

          if (!device.id) {
            device.id = device.deviceId;
          }
          devices.push(device);
        });
        dispatch(
          setDevices(
            devices.map(({ deviceId, kind, label }) => ({
              deviceId,
              kind,
              label,
            }))
          )
        );
        if (!videoConfig.videoDeviceId) {
          dispatch(
            setVideoDeviceId(
              res.filter((source) => source.kind === "videoinput")[0]?.deviceId
            )
          );
        }
        if (!videoConfig.audioDeviceId) {
          dispatch(
            setAudioDeviceId(
              res.filter((source) => source.kind === "audioinput")[0]?.deviceId
            )
          );
        }
      });
    }
  }, [getUserMediaCalled]);

  const setChosenVideoSourceId = (id) => dispatch(setVideoDeviceId(id));
  const setChosenAudioSourceId = (id) => dispatch(setAudioDeviceId(id));

  return {
    videoSources: videoConfig?.devices?.filter(
      (source) => source.kind === "videoinput"
    ),
    audioSources: videoConfig?.devices?.filter(
      (source) => source.kind === "audioinput"
    ),
    setChosenVideoSourceId,
    setChosenAudioSourceId,
    ...videoConfig,
  };
};
