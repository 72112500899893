import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  audioDeviceId: "",
  recordReady: false,
  mirrored: true,
  devices: null,
};

export const audioConfigSlice = createSlice({
  name: "audioconfig",
  initialState,
  reducers: {
    setAudioDeviceId: (state, action) => {
      return { ...state, audioDeviceId: action.payload };
    },
    setAudioDevices: (state, action) => {
      return { ...state, devices: action.payload };
    },
    setMirrored: (state, action) => {
      return { ...state, mirrored: action.payload };
    },
    setRecordReady: (state, action) => {
      return { ...state, recordReady: action.payload };
    },
  },
});

export const { setAudioDeviceId, setAudioDevices, setMirrored, setRecordReady } =
  audioConfigSlice.actions;

export default audioConfigSlice.reducer;
