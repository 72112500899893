import {
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { ButtonDefault } from "../../../1_Atoms";
import { NavDrawerCloseButton, NavDrawerContent, NavDrawerDrawer } from "./ui";
import { NavDrawerButton } from "./components";

export const NavigationDrawer = ({
  isOpen,
  onClose,
  handleLogoutClick,
  navItems,
}) => (
  <NavDrawerDrawer {...{ isOpen, onClose }}>
    <DrawerOverlay />
    <NavDrawerContent>
      <DrawerHeader>
        <NavDrawerCloseButton {...{ onClose }} />
      </DrawerHeader>
      <DrawerBody px={0}>
        {navItems?.map((item, i) => (
          <NavDrawerButton key={i} {...item} />
        ))}
      </DrawerBody>
      <DrawerFooter>
        <ButtonDefault w="full" onClick={handleLogoutClick}>
          Sign out
        </ButtonDefault>
      </DrawerFooter>
    </NavDrawerContent>
  </NavDrawerDrawer>
);
