import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import {
  NotificationAuthorFollowed,
  NotificationAuthorUnfollowed,
  NotificationContributionDeleted,
  NotificationContributionMediaSuccess,
  NotificationContributionReported,
  NotificationContributionTextSuccess,
  NotificationGenericError,
  NotificationHelpedContribution,
  NotificationProfileUpdated,
  NotificationSavedContribution,
  NotificationUnhelpedContribution,
  NotificationUnsavedContribution,
} from "../2_Molecules";

export const useNotifications = () => {
  const toast = useToast();

  const addToast = useCallback(
    ({ action, payload }) => {
      const Comp = action;
      if (Comp) {
        toast({
          position: "bottom-right",
          render: (props) => <Comp {...props} {...payload} />,
        });
      } else {
        toast({
          position: "bottom-right",
          ...payload,
        });
      }
    },
    [toast]
  );

  return {
    addToast,
    PROFILE_UPDATED: NotificationProfileUpdated,
    CONTRIBUTION_DELETED: NotificationContributionDeleted,
    AVATAR_UPDATED: NotificationProfileUpdated,
    CONTRIBUTION_TEXT_SUCCESS: NotificationContributionTextSuccess,
    CONTRIBUTION_MEDIA_SUCCESS: NotificationContributionMediaSuccess,
    CONTRIBUTION_UNHELPED: NotificationUnhelpedContribution,
    CONTRIBUTION_HELPED: NotificationHelpedContribution,
    AUTHOR_FOLLOWED: NotificationAuthorFollowed,
    AUTHOR_UNFOLLOWED: NotificationAuthorUnfollowed,
    CONTRIBUTION_SAVED: NotificationSavedContribution,
    CONTRIBUTION_UNSAVED: NotificationUnsavedContribution,
    CONTRIBUTION_REPORTED: NotificationContributionReported,
    GENERIC_ERROR: NotificationGenericError,
  };
};
