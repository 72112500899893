import { useDispatch, useSelector } from "react-redux";
import { ModalPrimary, RepExplanation, VerificationExplanation } from "../../";
import { modalClose } from "../../_Redux/modalsSlice";

export const ModalVerificationExplanation = () => {
  const modal = useSelector(
    (state) => state.modals
  ).modalVerificationExplanation;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalClose({ modal: "modalVerificationExplanation" }));
  };

  return (
    <ModalPrimary isOpen={modal.isOpen} onClose={() => close()}>
      {modal?.verified?.type === "Rep" && (
        <RepExplanation onClick={() => close()} {...modal?.verified} />
      )}
      {modal?.verified?.type !== "Rep" && (
        <VerificationExplanation onClick={() => close()} />
      )}
    </ModalPrimary>
  );
};
