import { useGetGroupQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const useGroup = () => {
  const group = useBaseApi({
    query: useGetGroupQuery,
  });

  return group;
};
