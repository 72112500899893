import {
  AudioPlayerThumbnailIcon,
  AudioPlayerThumbnailWrapper,
  AudioPlayerWrapper,
} from "./ui";
import { Box, HStack, useTheme } from "@chakra-ui/react";
import { AudioPlayerVideoJs } from "./components/AudioPlayerVideoJs/AudioPlayerVideoJs";

export const AudioPlayer = ({ options, sources, accentColor }) => {
  const { colors } = useTheme();
  const color = accentColor || colors?.accenttwo[2200];
  // const bars = useMemo(
  //   () =>
  //     [...Array(30).keys()].map((i) =>
  //       Math.floor(Math.random() * (70 - 35 + 1) + 35)
  //     ),
  //   []
  // );
  const barHeights = [
    63, 60, 67, 43, 57, 53, 56, 43, 56, 40, 56, 48, 56, 40, 41, 63, 47, 52, 41,
    66, 58, 47, 55, 62, 47, 40, 43, 57, 66, 59,
  ];

  return (
    <AudioPlayerWrapper {...{ color }}>
      <AudioPlayerThumbnailWrapper>
        <HStack pos="absolute" inset="0" alignItems="flex-end" spacing={1}>
          {barHeights?.map((h, i, arr) => (
            <Bar key={i} w={`${100 / arr.length}%`} h={`${h}%`} />
          ))}
        </HStack>
        <AudioPlayerThumbnailIcon {...{ color }} />
      </AudioPlayerThumbnailWrapper>
      <AudioPlayerVideoJs {...{ sources, options }} />
    </AudioPlayerWrapper>
  );
};

const Bar = ({ h, w }) => <Box w={w} bg="accenttwo.3200" h={h} />;
