import { useEffect, useState } from "react";
import { useGetTransformedCallsQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

const filterByRole = (item, value) => {
  const _value = parseInt(value);
  const roleIds = item?.author?.roles.map((r) => r.id);
  return roleIds.includes(_value);
};

const filterByTopic = (item, value) => {
  const topcIds = item?.topics.map((r) => r.title);
  return topcIds.includes(value);;
};

const filterByTitle = (item, search) => {
  const title = item?.title?.toLowerCase().replace(/\s+/g, "");
  const value = search?.toLowerCase().replace(/\s+/g, "");
  return title.includes(value);
};

const FILTERS = {
  role: filterByRole,
  topic: filterByTopic,
  title: filterByTitle,
};

const mostContributed = (a, b) => {
  const x = a?.contributionCount;
  const y = b?.contributionCount;
  return x > y ? -1 : 1;
};

const mostViewed = (a, b) => {
  const x = a?.views;
  const y = b?.views;
  return x > y ? -1 : 1;
};

const oldest = (a, b) => {
  const x = a?.updatedAt;
  const y = b?.updatedAt;
  return x < y ? -1 : x > y ? 1 : 0;
};

const newest = (a, b) => {
  const x = a?.updatedAt;
  const y = b?.updatedAt;
  return x > y ? -1 : x < y ? 1 : 0;
};

const SORTS = { mostContributed, mostViewed, newest, oldest };

const sortOptions = [
  { name: "Newest", value: "newest" },
  { name: "Oldest", value: "oldest" },
  { name: "Most contributed", value: "mostContributed" },
  { name: "Most viewed", value: "mostViewed" },
];

export const useCalls = () => {
  const calls = useBaseApi({ query: useGetTransformedCallsQuery });
  const [filteredList, setFilteredList] = useState();
  const [filterDropdowns, setFilterDropdowns] = useState();
  const [sort, setSort] = useState(sortOptions[0].value);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (calls?.data) {
      setFilterDropdowns([
        {
          label: "Topic",
          name: "topic",
          value: "clear",
          clearValue: "clear",
          options: [
            { name: "All", value: "clear" },
            ...getTopicsInFilterFormat(calls?.data),
          ],
        },
        {
          label: "Role",
          name: "role",
          value: "clear",
          clearValue: "clear",
          options: [
            { name: "All", value: "clear" },
            ...getRolesInFilterFormat(calls?.data),
          ],
        },
      ]);
    }
  }, [calls?.data]);

  const filterUpdated = ({ value, name }) => {
    setFilterDropdowns((prev) =>
      prev.map((item) => {
        if (item.name === name) return { ...item, value };
        return item;
      })
    );
  };

  const sortUpdated = ({ value }) => {
    setSort(value);
  };

  const searchUpdated = ({ value }) => {
    setSearch(value);
  };

  useEffect(() => {
    if (filteredList) {
      const listToSort = [...filteredList];
      setFilteredList(listToSort.sort(SORTS[sort]));
    }
  }, [sort]);

  useEffect(() => {
    if (filterDropdowns && calls?.data) {
      let list = calls?.data;
      filterDropdowns.forEach((filter) => {
        const value = filter.value;
        const filterFunction = FILTERS[filter.name];
        if (value !== filter.clearValue && filterFunction) {
          list = list.filter((item) => filterFunction(item, value));
        }
      });
      list = list.filter((item) => FILTERS.title(item, search));
      const listToSort = [...list];
      setFilteredList(listToSort.sort(SORTS[sort]));
    }
  }, [filterDropdowns, search, calls?.data]);

  return {
    ...calls,
    data: filteredList,
    filterDropdowns,
    filterUpdated,
    sortOptions,
    sortUpdated,
    sort,
    search,
    searchUpdated,
  };
};

const getTopicsInFilterFormat = (data) => {
  const allTopics = data
    ?.map((item) =>
      item?.topics?.map((topic) => ({ name: topic?.title, value: topic?.title }))
    )
    .filter((item) => item?.length);
  const flatTopics = allTopics.flat();
  const uniqueTopics = uniqByProp("value")(flatTopics);
  const topics = uniqueTopics.sort((a, b) => {
    const x = a.name;
    const y = b.name;
    return x < y ? -1 : x > y ? 1 : 0;
  });
  return topics;
};

const getRolesInFilterFormat = (data) => {
  const allRoles = data
    ?.map((item) =>
      item?.author?.roles.map((r) => ({ name: r?.title, value: r?.id }))
    )
    .filter((item) => item.length);
  const flatRoles = allRoles.flat();
  const roles = uniqByProp("value")(flatRoles);
  return roles.sort((a, b) => {
    const x = a.name;
    const y = b.name;
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

const uniqByProp = (prop) => (arr) =>
  Object.values(
    arr.reduce(
      (acc, item) =>
        item && item[prop] ? { ...acc, [item[prop]]: item } : acc,
      {}
    )
  );
