import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import { CardBody, CardCallToAction } from "../../../../1_Atoms";

export const AmbassadorCta = () => (
  <CardCallToAction>
    <CardBody>
      <Stack spacing="1rem">
        <Heading fontSize="1.2rem">Apply to become an Ambassador</Heading>
        <Text>
          Colloquy is led by a team of ambassadors to ensure the patient
          perspective is brought to the forefront of everything we do. The team
          of ambassadors are made up of patients, loved ones, experts and
          nurses, who facilitate exchanges that support you along your journey.
          As an ambassador, you can share calls (or questions) to the community
          to start a conversation and enable others to share their experiences.
        </Text>
        <Text>
          If you are interested in becoming an ambassador, please send an email
          to:{" "}
          <Link fontWeight="body.bold" href="mailto:info@colloquy.health">
            info@colloquy.health
          </Link>
        </Text>
      </Stack>
    </CardBody>
  </CardCallToAction>
);
