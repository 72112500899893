import React, { useState } from "react";
import { Box, Button, Icon, Container } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { OnBoardStepLayout } from "./OnBoardStepLayout";
import { useOnboardingData } from "../..";
import { LoaderSpinner } from "../../../ui";

export const Onboard = ({ onFinishClick }) => {
  const onboarding = useOnboardingData();

  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box w="100%">
      {onboarding.isLoading && <LoaderSpinner />}
      {onboarding.steps && (
        <>
          <Box
            d="flex"
            pos="absolute"
            w="100%"
            h="100%"
            top="0"
            alignItems="center"
          >
            <Container pos="relative" zIndex="2" maxW="container.xl">
              {currentIndex !== 0 && (
                <Prev
                  pos="absolute"
                  left="0"
                  disabled={currentIndex === 0}
                  onClick={() => setCurrentIndex((prev) => prev - 1)}
                />
              )}
              {currentIndex < onboarding?.steps?.length - 1 && (
                <Next
                  pos="absolute"
                  right="0"
                  disabled={currentIndex >= onboarding?.steps?.length - 1}
                  onClick={() => setCurrentIndex((prev) => prev + 1)}
                />
              )}
            </Container>
          </Box>
          <Carousel
            transitionTime={500}
            selectedItem={currentIndex}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            onChange={(e) => setCurrentIndex(e)}
          >
            {onboarding?.steps?.map((step, i) => (
              <Box key={i} px="4rem" mb="3rem" pos="relative" zIndex="10">
                <OnBoardStepLayout
                  {...step}
                  {...{ onFinishClick }}
                  index={i}
                  length={onboarding?.steps?.length}
                >
                  {i === onboarding?.steps?.length - 1 && (
                    <Button
                      mt="1.5rem"
                      minW="150px"
                      bg="accentone.1700"
                      _hover={{ bg: "accentone.2000" }}
                      _active={{ bg: "accentone.2000" }}
                      _focus={{ bg: "accentone.2000" }}
                      color="white"
                      onClick={onFinishClick}
                    >
                      Sign up
                    </Button>
                  )}
                </OnBoardStepLayout>
              </Box>
            ))}
          </Carousel>
        </>
      )}
    </Box>
  );
};

const Prev = (props) => <ButtonArrowBase as={BsChevronLeft} {...props} />;
const Next = (props) => <ButtonArrowBase as={BsChevronRight} {...props} />;

const ButtonArrowBase = ({ as, ...props }) => (
  <Button
    bg="transparent"
    px="0"
    color="accentone.2000"
    transform={"scale(0.8)"}
    _disabled={{}}
    _hover={{ transform: "scale(1)", color: "accentone.1500" }}
    _active={{ transform: "scale(1)", color: "accentone.1500" }}
    _focus={{ transform: "scale(1)", color: "accentone.1500" }}
    fontSize="3.5rem"
    {...props}
  >
    <Icon {...{ as }} />
  </Button>
);
