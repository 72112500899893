import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonFollowFollowing = ({ isFollowing }) => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate={isFollowing ? "show" : "hide"}
    color="white"
  >
    Following
  </Box>
);
