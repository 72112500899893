import { getUserFromSessionOrLocalStorage } from "../../_Utils";
import { postloginBaseParams } from "./config";
import { postLoginApi } from "./postlogin";

export const postSaveContribution = {
  query: (contribution) => ({
    url: `userSavedContribution`,
    params: {
      ...postloginBaseParams,
      contributionId: contribution.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "POST",
  }),
  async onQueryStarted(contribution, { dispatch, queryFulfilled }) {
    const patch = dispatch(
      postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
        ...draft,
        savedContributions: [...draft.savedContributions, contribution],
      }))
    );
    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      return error;
    }
  },
};

export const deleteSaveContribution = {
  query: (contribution) => ({
    url: `userSavedContribution`,
    params: {
      ...postloginBaseParams,
      contributionId: contribution.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "DELETE",
  }),
  async onQueryStarted(contribution, { dispatch, queryFulfilled }) {
    const patch = dispatch(
      postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
        ...draft,
        savedContributions: draft.savedContributions?.filter(
          (c) => c.id !== contribution.id
        ),
      }))
    );
    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      return error;
    }
  },
};
