import { Box, Icon, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import { FaCheck } from "react-icons/fa";

export const FormCheckbox = forwardRef(
  (
    {
      checked,
      onChange,
      children,
      error,
      label,
      errorColor = "red.500",
      isDisabled,
      ...props
    },
    ref
  ) => (
    <Box pos="relative" mb="18px">
      <Box
        d="flex"
        alignItems="center"
        cursor="pointer"
        ref={ref}
        as="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) onChange(!checked);
        }}
        {...props}
      >
        <Box
          mr={3}
          bg={checked ? "accentone.base" : "white"}
          boxSize="1.4rem"
          borderRadius="sm"
          d="flex"
          flexShrink={0}
          alignItems="center"
          justifyContent="center"
        >
          {checked && <Icon as={FaCheck} />}
        </Box>
        <Box as="span" textAlign="left" lineHeight={1.3}>
          {children}
        </Box>
      </Box>
      {error && (
        <Text
          pl="0.2rem"
          color={errorColor}
          textTransform="uppercase"
          fontSize="0.7rem"
          fontWeight="body.bold"
          pos="absolute"
          bottom="-18px"
          lineHeight="1"
        >
          {error}
        </Text>
      )}
    </Box>
  )
);
