import { CardVideoBlock } from "../../../2_Molecules";
import { CardCallPrimaryFooter } from "./components";
import { CardWrapper } from "./ui";

export const CardCallPrimary = ({
  handleVerifiedClicked,
  title,
  contributionCount,
  author,
  video,
  handleVideoContributionClick,
  handleAudioContributionClick,
  handleTextContributionClick,
  to = "/",
}) => (
  <CardWrapper>
    <CardVideoBlock variant="call" {...{ video }} />
    <CardCallPrimaryFooter
      {...{
        to,
        handleVerifiedClicked,
        author,
        title,
        contributionCount,
        handleVideoContributionClick,
        handleAudioContributionClick,
        handleTextContributionClick,
      }}
    />
  </CardWrapper>
);
