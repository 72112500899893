import { FormControlInput } from "../../../../../../../../ui";
import { formValidation } from "../../../../../../../_Utils";

export const ForgotPasswordFormEmailInput = ({ register, errors }) => (
  <FormControlInput
    label="Email"
    placeholder="mail@website.com"
    reverse
    {...register("email", {
      required: "Please enter your email address",
      validate: formValidation.isValidEmail,
    })}
    error={errors?.email?.message}
  />
);
