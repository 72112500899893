import { useEffect, useState } from "react";

export const useDictaphoneAudioWave = (
  stream,
  ref,
  containerRef,
  colorOne = "red",
  colorTwo = "blue"
) => {
  const [animationId, setAnimationId] = useState();

  useEffect(() => {
    let id;
    if (stream) {
      var context = new AudioContext();

      var src = context.createMediaStreamSource(stream);
      var analyser = context.createAnalyser();

      var canvas = ref.current;
      canvas.width = containerRef.current.clientWidth;
      canvas.height = containerRef.current.clientHeight;
      var ctx = canvas.getContext("2d");

      src.connect(analyser);

      analyser.fftSize = 2 ** 7;

      var bufferLength = analyser.frequencyBinCount;

      var dataArray = new Uint8Array(bufferLength);

      var WIDTH = canvas.width;
      var HEIGHT = canvas.height;

      var barWidth = WIDTH / (bufferLength - 10);
      var barHeight;
      var x = 0;
      function renderFrame() {
        id = requestAnimationFrame(renderFrame);
        setAnimationId(id);
        x = 0;

        analyser.getByteFrequencyData(dataArray);

        ctx.fillStyle = colorOne;
        ctx.fillRect(0, 0, WIDTH, HEIGHT);

        for (var i = 0; i < bufferLength; i++) {
          barHeight = dataArray[i];
          ctx.fillStyle = colorTwo;
          ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

          x += barWidth + 2;
        }
      }

      renderFrame();
    }
    return () => {
      cancelAnimationFrame(id);
    };
  }, [stream]);

  const stop = () => {
    cancelAnimationFrame(animationId);
  };

  return { animationId, stop };
};
