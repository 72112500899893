import { motion } from "framer-motion";
import { Stack } from "@chakra-ui/react";
import {
  AudioContributionIntroBlockOne,
  AudioContributionIntroBlockThree,
  AudioContributionIntroBlockTwo,
  AudioContributionIntroButton,
  AudioContributionIntroTitle,
  AudioContributionIntroWrapper,
} from "./ui";
import { introchild } from "./ui/framerVariants";

export const AudioContributionIntro = ({ handleNextClick, ...props }) => (
  <AudioContributionIntroWrapper {...props}>
    <Stack spacing={10} w="100%" maxW="300px" alignSelf="center">
      <AudioContributionIntroTitle />
      <Stack as={motion.div} variants={introchild} spacing={10}>
        <AudioContributionIntroBlockOne />
        <AudioContributionIntroBlockTwo />
        <AudioContributionIntroBlockThree />
      </Stack>
      <AudioContributionIntroButton {...{ handleNextClick }} />
    </Stack>
  </AudioContributionIntroWrapper>
);
