import { Box } from "@chakra-ui/react";

export const ListSortAndFilterWrapper = ({ children }) => (
  <Box
    px={6}
    py={4}
    bg="white"
    borderBottom="solid 1px"
    borderBottomColor="themegray.400"
  >
    {children}
  </Box>
);
