import { useTheme } from "@emotion/react";
import { AudioPlayer } from "../..";
import {
  ContributionAudioPlayerBadge,
  ContributionAudioPlayerWrapper,
} from "./ui";

export const ContributionAudioPlayer = (props) => {
  const { colors } = useTheme();
  return (
    <ContributionAudioPlayerWrapper>
      <ContributionAudioPlayerBadge />
      <AudioPlayer accentColor={colors?.accenttwo[1900]} {...props} />
    </ContributionAudioPlayerWrapper>
  );
};
