import { useState } from "react";
import { useNotifications } from "..";
import {
  serviceAzureStorageBlob,
  usePostMediaContributionMutation,
} from "../_Service";

export const useMakeMediaContribution = () => {
  const [postContribution, postStatus] = usePostMediaContributionMutation();
  const [contributionLoading, setContributionLoading] = useState();
  const [contributionComplete, setContributionComplete] = useState();
  const { GENERIC_ERROR, CONTRIBUTION_MEDIA_SUCCESS, addToast } =
    useNotifications();

  const createVideoContribution = async (file, filetype, call) => {
    setContributionLoading(true);
    try {
      const contribution = await postContribution({
        callId: call?.id,
        interactionTypeId: 1,
      }).unwrap();
      const { sasToken, sasFilename } = contribution.Value;
      await serviceAzureStorageBlob.uploadFileToBlob(
        file,
        sasToken,
        `${sasFilename}.${filetype}`
      );
      setContributionComplete(true);
      setContributionLoading(false);
      addToast({ action: CONTRIBUTION_MEDIA_SUCCESS });
    } catch (error) {
      setContributionLoading(false);
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  const createAudioContribution = async (file, filetype, call) => {
    setContributionLoading(true);
    try {
      const contribution = await postContribution({
        callId: call?.id,
        interactionTypeId: 3,
      }).unwrap();
      const { sasToken, sasFilename } = contribution.Value;
      await serviceAzureStorageBlob.uploadFileToBlob(
        file,
        sasToken,
        `${sasFilename}.${filetype}`
      );
      setContributionComplete(true);
      setContributionLoading(false);
      addToast({ action: CONTRIBUTION_MEDIA_SUCCESS });
    } catch (error) {
      setContributionLoading(false);
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  return {
    createVideoContribution,
    createAudioContribution,
    contributionComplete,
    contributionLoading,
    setContributionComplete,
    ...postStatus,
  };
};
