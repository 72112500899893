import { EmptyStateList, LoaderSection } from "../../2_Molecules";

export const ListWithEmptyStatePrimary = ({
  isLoading,
  children,
  data,
  error,
  EmptyState = () => <div>provide empty state component as Empty State</div>,
}) => (
  <LoaderSection loading={isLoading} error={error}>
    {(!data?.length || data?.length === 0) && (
      <EmptyStateList>
        <EmptyState />
      </EmptyStateList>
    )}
    {data?.length > 0 && children}
  </LoaderSection>
);
