import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAudioDeviceId, setAudioDevices } from "../_Redux/audioConfigSlice";

export const useAudioSources = () => {
  const [getUserMediaCalled, setGetUserMediaCalled] = useState();
  const dispatch = useDispatch();
  const audioConfig = useSelector((state) => state.audioConfig);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => setGetUserMediaCalled(true))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (getUserMediaCalled) {
      navigator.mediaDevices.enumerateDevices().then((res) => {
        let devices = [];
        res.forEach((_device) => {
          var device = {};
          for (var d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === "audio") {
            device.kind = "audioinput";
          }
          if (!device.deviceId) {
            device.deviceId = device.id;
          }
          if (!device.id) {
            device.id = device.deviceId;
          }
          if (device.kind === "audioinput") {
            devices.push({ deviceId: device?.deviceId, label: device?.label });
          }
        });
        dispatch(setAudioDeviceId(devices[0].deviceId));
        dispatch(setAudioDevices(devices));
      });
    }
  }, [getUserMediaCalled]);

  const setChosenAudioSourceId = (id) => dispatch(setAudioDeviceId(id));

  return { ...audioConfig, setChosenAudioSourceId };
};
