import { Box } from "@chakra-ui/react";

export const AvatarEditorButtonsWrapper = ({ children }) => (
  <Box
    d="flex"
    alignItems="center"
    justifyContent="center"
    gap={4}
    {...{ children }}
  />
);
