import { useGetEthnicityListQuery } from "../_Service";
import { getItemFromArrayById } from "../_Utils";
import { useBaseApi } from "./useBaseApi";

export const useEthnicityList = () => {
  const { data, ...rest } = useBaseApi({ query: useGetEthnicityListQuery });
  const getEthnicityById = (id) => getItemFromArrayById(id, data);
  return {
    data,
    ...rest,
    getEthnicityById,
  };
};
