import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from "@chakra-ui/react";

export const AvatarEditorSlider = ({ isLoading, onChange }) => (
  <Slider
    disabled={isLoading}
    my="1rem"
    min={0}
    max={300}
    defaultValue={1}
    {...{ onChange }}
  >
    <SliderTrack h="0.5rem" borderRadius="full">
      <SliderFilledTrack bg="accentone.base" />
    </SliderTrack>
    <SliderThumb boxSize={6} bg="primary.base" />
  </Slider>
);
