export const addItemToArray = (item, arr) => {
  return [...arr, item];
};

export const removeItemFromArrayById = (item, arr) => {
  return arr.filter((arrItem => arrItem.id !== item.id));
};


export const getItemFromArrayById = (id, list) => {
  return list?.filter((c) => c.id === id)[0];
};
