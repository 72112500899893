// https://twosixbrochure-lstjmh75g-twosixstudio.vercel.app/swatch?sw%5B0%5D%5Btitle%5D=primary&sw%5B0%5D%5BbaseColor%5D=%232f3e93&sw%5B0%5D%5Bamount%5D=40&sw%5B0%5D%5BlightAdjustment%5D=0.02&sw%5B0%5D%5BdarkAdjustment%5D=0.1&sw%5B0%5D%5BsaturationAdjustment%5D=0&sw%5B1%5D%5Btitle%5D=accentone&sw%5B1%5D%5BbaseColor%5D=%23f298b9&sw%5B1%5D%5Bamount%5D=40&sw%5B1%5D%5BlightAdjustment%5D=0.02&sw%5B1%5D%5BdarkAdjustment%5D=0.07&sw%5B1%5D%5BsaturationAdjustment%5D=-0.2&sw%5B2%5D%5Btitle%5D=accenttwo&sw%5B2%5D%5BbaseColor%5D=%232f8093&sw%5B2%5D%5Bamount%5D=40&sw%5B2%5D%5BlightAdjustment%5D=0.02&sw%5B2%5D%5BsaturationAdjustment%5D=0&sw%5B2%5D%5BdarkAdjustment%5D=0.05&sw%5B3%5D%5Btitle%5D=themegray&sw%5B3%5D%5BbaseColor%5D=%23272324&sw%5B3%5D%5Bamount%5D=40&sw%5B3%5D%5BlightAdjustment%5D=0.02&sw%5B3%5D%5BdarkAdjustment%5D=0.04&sw%5B3%5D%5BsaturationAdjustment%5D=0

import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
export const theme = extendTheme({
  styles: {
    global: {
      ".chakra-modal__overlay": {
        h: "110vh!important",
      },
      html: {
        d: "flex",
        flexDir: "column",
        h: "100%",
      },
      body: {
        overflowY: "scroll!important",
        flexGrow: 1,
        d: "flex",
        flexDir: "column",
        color: "themegray.3200",
      },
      "#root": { flexGrow: 1, d: "flex", flexDir: "column" },
    },
  },
  colors,
  fonts: {
    heading: "Raleway",
    body: "Raleway",
  },
  fontWeights: {
    heading: { light: 300, regular: 500, bold: 700, black: 800 },
    body: { light: 300, regular: 500, bold: 700, black: 800 },
  },
  shadows: {
    outline: "rgba(47, 62, 147, 0.5) 0px 0px 0px 2.5px",
  },
});
