import { motion } from "framer-motion";
import { CardPrimary } from "../../../../../../../../1_Atoms/Cards/CardPrimary";
import { introwrapper } from "./framerVariants";

export const AudioContributionIntroWrapper = (props) => (
  <CardPrimary
    as={motion.div}
    variants={introwrapper}
    initial="hide"
    animate="show"
    exit="exit"
    maxW="480px"
    mx="auto"
    py={10}
    px={8}
    {...props}
  />
);
