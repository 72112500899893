import { FormControlInput } from "../../../../../../ui";
import { formValidation, validationDictionary } from "../../../../../_Utils";

export const FormSignInUsername = ({ register, errors }) => (
  <FormControlInput
    {...register("username", {
      required: validationDictionary.EMAIL_REQUIRED,
      validate: formValidation.isValidEmail,
    })}
    error={errors.username?.message}
    isInvalid={errors.username?.message}
    placeholder="mail@website.com"
    label="Email"
    reverse
  />
);
