import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from ".";
import { signupUpdateValues, signupReset } from "../_Redux/signupSlice";
import { usePostUserSignUpMutation } from "../_Service";

export const useSignUp = () => {
  const dispatch = useDispatch();
  const values = useSelector((state) => state.signup);
  const [userSignUp, { error, isSuccess, isLoading, ...rest }] =
    usePostUserSignUpMutation({ fixedCacheKey: "shared-signup" });
  const notifications = useNotifications();

  const signUp = async (values) => {
    const supplementedValues = {
      ...values,
      groupId: [process.env.REACT_APP_GROUP_ID],
      town: "unknown",
      stateId: values.stateId || 0,
    };
    try {
      await userSignUp(supplementedValues).unwrap();
    } catch (error) {
      notifications.addToast({
        action: notifications.GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const updateValues = (values) => dispatch(signupUpdateValues(values));

  const reset = () => {
    rest.reset();
    dispatch(signupReset);
  };

  return { values, error, isSuccess, isLoading, signUp, reset, updateValues };
};
