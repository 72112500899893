import { IconButton, MenuButton } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { rgba } from "polished";

export const MediaSourceDropdownMenuButton = ({ iconButton }) => {
  const { colors } = useTheme();
  return (
    <MenuButton
      fontSize="1rem"
      boxSize="1.9rem"
      minW="unset"
      minH="unset"
      bg={rgba(colors?.themegray?.base, 0.8)}
      color="white"
      borderRadius="full"
      _hover={{ bg: "rgba(0,0,0,0.8)" }}
      _active={{ bg: "rgba(0,0,0,0.8)" }}
      as={IconButton}
      icon={iconButton}
    />
  );
};
