import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

export const AvatarEditorModal = ({ children, file, handleCancel }) => (
  <Modal isOpen={file} onClose={handleCancel}>
    <ModalOverlay />
    <ModalContent mx="1rem">
      <ModalBody py="2rem">{children}</ModalBody>
    </ModalContent>
  </Modal>
);
