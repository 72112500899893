import { ButtonTertiary } from "../../../../../../../../../ui";

export const AudioContributionPlaybackButtonSave = ({
  handleSaveRecordingClick,
  isLoading,
}) => (
  <ButtonTertiary
    loadingLabel='Uploading...'
    onClick={handleSaveRecordingClick}
    disabled={isLoading}
    isLoading={isLoading}
    size="sm"
    flexBasis="50%"
  >
    Use audio
  </ButtonTertiary>
);
