import { Box } from "@chakra-ui/react";
import { Badge, VideoPlayer } from "../../../../../1_Atoms";

export const HeroCallVideoAndBadge = ({ video, color = "accentone.base" }) => {
  if (!video) return null;
  return (
    <Box pos="relative">
      <VideoPlayer overlayColor={color} {...video} />
      <Badge pointerEvents="none" pos="absolute" top={3} left={3} bg={color}>
        Call
      </Badge>
    </Box>
  );
};
