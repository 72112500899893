import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Collapse, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FormSignInUsername, FormSignInSubmitButton } from "./ui";
import { FormSignInPassword } from "./components";
import { useAuthentication } from "../../../../_Hooks";
import { NotificationGenericError } from "../../../../2_Molecules";

export const FormSignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const { error, id, isLoading, ...auth } = useAuthentication();

  useEffect(() => {
    id && navigate("/");
  }, [id, navigate]);

  return (
    <Box w="full">
      <form
        onSubmit={handleSubmit((data) =>
          auth.login(data.username, data.password)
        )}
      >
        <Stack spacing={8}>
          <Collapse in={error}>
            <Box mt="1rem">
              <NotificationGenericError {...error} />
            </Box>
          </Collapse>
          <Stack spacing={4}>
            <FormSignInUsername {...{ register, errors }} />
            <FormSignInPassword {...{ register, errors }} />
          </Stack>
          <FormSignInSubmitButton {...{ isLoading }} />
        </Stack>
      </form>
    </Box>
  );
};
