import { setupListeners } from "@reduxjs/toolkit/query";
import { postLoginApi, preLoginApi } from "../_Service";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import signupReducer from "./signupSlice";
import authReducer from "./authSlice";
import modalsReducer from "./modalsSlice";
import videoconfigReducer from "./videoConfigSlice";
import audioconfigReducer from "./audioConfigSlice";

const combinedReducer = combineReducers({
  signup: signupReducer,
  auth: authReducer,
  modals: modalsReducer,
  videoconfig: videoconfigReducer,
  audioConfig: audioconfigReducer,
  [preLoginApi.reducerPath]: preLoginApi.reducer,
  [postLoginApi.reducerPath]: postLoginApi.reducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      preLoginApi.middleware,
      postLoginApi.middleware
    ),
});

setupListeners(store.dispatch);
