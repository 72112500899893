import { Box, Heading, Icon, IconButton } from "@chakra-ui/react";
import { ModalSecondary } from "./ModalSecondary";
import { FaTimes } from "react-icons/fa";
export const ModalContribution = ({ isOpen, onClose, title, children }) => (
  <ModalSecondary {...{ isOpen, onClose }}>
    <Box p={{ base: "1rem", sm: "1.5rem" }} textAlign="right">
      <IconButton
        onClick={() => onClose()}
        bg="themegray.3000"
        color="white"
        borderRadius="full"
        _hover={{
          bg: "accenttwo.base",
        }}
        icon={<Icon as={FaTimes} fontSize="1.2rem" />}
      />
    </Box>
    <Box px="1.5rem" pb="1.5rem" d="flex" flexDir="column" flexGrow="1">
      <Heading
        color="white"
        textAlign="center"
        mb="1rem"
        fontSize={{ base: "1.2rem", md: "1.5rem" }}
        maxW="60ch"
        mx="auto"
      >
        {title}
      </Heading>
      <Box
        flexDir="column"
        d="flex"
        flexGrow="1"
        justifyContent="center"
        alignItems="center"
      >
        {isOpen && children}
      </Box>
    </Box>
  </ModalSecondary>
);
