import { motion } from "framer-motion";
import { Dictaphone } from "../../../../../../../../ui";
import { contributionRecordWrapperVariant } from "./ui/framerVariants";

export const AudioContributionRecord = ({
  audioCapture,
  handleStopRecordingClick,
  audioSources,
  ...props
}) => {
  if (audioSources?.devices) {
    return (
      <motion.div
        variants={contributionRecordWrapperVariant}
        initial="hide"
        animate="show"
        exit="exit"
        style={{ flexGrow: 1 }}
        {...props}
      >
        <Dictaphone
          micOptions={audioSources?.devices?.map((device) => ({
            label: device?.label,
            value: device?.deviceId,
          }))}
          onMicSelectChange={(v) => {
            audioSources.setChosenAudioSourceId(v);
            audioCapture.setDeviceId(v);
          }}
          micValue={audioSources?.audioDeviceId}
          isRecording={audioCapture.recording}
          handleStartRecordingClick={audioCapture.onStartClick}
          handleStopRecordingClick={handleStopRecordingClick}
          audioStream={audioCapture.stream}
        />
      </motion.div>
    );
  }
  return null;
};
