import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { useAuthentication, usePartner } from "../..";
import { useDispatch } from "react-redux";
import { modalOpen } from "../../_Redux/modalsSlice";
import { Header, NavigationDrawer } from "../../../ui";
import { navData } from "./navData";

export const NavDrawerPrimary = ({ isOpen, onOpen, onClose, children }) => {
  const location = useLocation();
  const { logout } = useAuthentication();
  const partner = usePartner();
  const height = "80px";
  const dispatch = useDispatch();

  const navItems = [
    ...navData,
    {
      type: "button",
      name: "Terms and Conditions",
      description: "These Terms and Conditions apply to your account",
      onClick: () => {
        onClose();
        dispatch(modalOpen({ modal: "modalTerms" }));
      },
    },
  ];

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <>
      <Header
        {...{ height }}
        partners={partner}
        handleOpenDrawerClick={onOpen}
      />
      <NavigationDrawer
        handleLogoutClick={logout}
        {...{ isOpen, onClose, onOpen, navItems }}
      />
      <Box pt={height} flexGrow="1" d="flex">
        {children}
      </Box>
    </>
  );
};
