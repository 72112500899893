import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBack = (props) => (
  <Button
    bg="transparent"
    color="white"
    _hover={{ textDecor: "underline" }}
    _focus={{ textDecor: "underline", bg: "transparent" }}
    _active={{ textDecor: "underline", bg: "transparent" }}
    {...props}
  >
    Back
  </Button>
);
