import { preloginBaseParams } from "./config";

export const postForgottenPassword = {
  query: (email) => ({
    url: "UserPre/forgottenPassword",
    params: {
      ...preloginBaseParams,
      email,
    },
    method: "POST",
  }),
};
