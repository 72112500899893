import {
  getAmbassador,
  getUserFromSessionOrLocalStorage,
  transformAuthor,
  transformCall,
  transformContributionLight,
} from "../../_Utils";
import { postloginBaseParams } from "./config";
import { postLoginApi } from "./postlogin";

export const getProfile = {
  query: () => ({
    url: `Profile/${getUserFromSessionOrLocalStorage().id}`,
    params: postloginBaseParams,
  }),
  providesTags: (result) => {
    return result ? [{ ...result, type: "Profile" }] : [{ type: "Profile" }];
  },
};

export const getProfileTransformed = {
  query: () => ({
    url: `Profile/${getUserFromSessionOrLocalStorage().id}`,
    params: postloginBaseParams,
  }),
  transformResponse: (res) => {
    console.log({ res });
    return {
      ...res,
      ...transformAuthor(res),
      peopleFollowing: res?.peopleFollowing.map((item) =>
        transformAuthor(item?.author)
      ),
      savedContributions: res?.savedContribution.map(
        transformContributionLight
      ),
      helpfulContributions: res?.helpfulContribution,
      myContributions: res?.myContribution?.map((item) => ({
        ...transformContributionLight(item),
        author: transformAuthor(res),
      })),
      myCalls: res?.myCall?.map((item) => ({
        ...transformCall(item),
        author: transformAuthor(res),
      })),
    };
  },
  providesTags: (result) => {
    return result ? [{ ...result, type: "Profile" }] : [{ type: "Profile" }];
  },
};

export const patchProfile = {
  query: (values) => ({
    url: `Profile/${getUserFromSessionOrLocalStorage().id}`,
    params: postloginBaseParams,
    method: "PATCH",
    body: values,
  }),
  async onQueryStarted(_, { dispatch, queryFulfilled }) {
    try {
      const { data } = await queryFulfilled;
      dispatch(
        postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
          ...draft,
          ...data,
        }))
      );
    } catch (error) {
      return error;
    }
  },
  // invalidatesTags: ["Profile", id],
};

export const postUserAvatar = {
  query: (formData) => ({
    url: `UserAvatar/${getUserFromSessionOrLocalStorage().id}`,
    params: postloginBaseParams,
    method: "POST",
    body: formData,
  }),
  invalidatesTags: ["Profile"],
};

export const deleteUserAvatar = {
  query: () => ({
    url: `UserAvatar/${getUserFromSessionOrLocalStorage().id}`,
    params: postloginBaseParams,
    method: "DELETE",
  }),
  invalidatesTags: ["Profile"],
};

export const postUserRole = {
  query: (role) => ({
    url: `UserRole`,
    params: {
      ...postloginBaseParams,
      userId: getUserFromSessionOrLocalStorage().id,
      group_roleId: role?.id,
    },
    method: "POST",
  }),
  async onQueryStarted(role, { dispatch, queryFulfilled }) {
    try {
      await queryFulfilled;
      dispatch(
        postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => {
          return {
            ...draft,
            role: [getAmbassador(draft?.role), role],
          };
        })
      );
    } catch (error) {
      return error;
    }
  },
  // invalidatesTags: ["Profile"],
};

export const deleteUserRole = {
  query: (role) => ({
    url: `UserRole`,
    params: {
      ...postloginBaseParams,
      userId: getUserFromSessionOrLocalStorage().id,
      group_roleId: role?.id,
    },
    method: "DELETE",
  }),
  // invalidatesTags: ["Profile"],
};
