import { FooterPartner } from "./components";
import { FooterText, FooterWrapper } from "./ui";

export const Footer = ({ partnerList, footerText }) => (
  <FooterWrapper>
    {partnerList?.map((item) => (
      <FooterPartner key={item.URL} {...item} />
    ))}
    <FooterText {...{ footerText }} />
  </FooterWrapper>
);
