import { Heading } from "@chakra-ui/react";

export const ForgotPasswordTitle = () => (
  <Heading
    as="h1"
    color="white"
    fontWeight="heading.bold"
    fontSize={{ base: "1.5rem", md: "2rem" }}
  >
    Forgot password
  </Heading>
);
