import {
  useDeleteUserRoleMutation,
  useGetProfileQuery,
  useGetTransformedProfileQuery,
  usePatchProfileMutation,
  usePostUserRoleMutation,
} from "../_Service";
import { useBaseApi } from "./useBaseApi";
import { useNotifications } from "./useNotifications";

const formatValuesForApi = (updatedValues) => {
  const formatted = Object.keys(updatedValues).map((k) => ({
    field: k,
    value: updatedValues[k],
  }));
  return formatted;
};

export const useProfile = () => {
  const profile = useBaseApi({ query: useGetTransformedProfileQuery });
  const [patchProfile, patchStatus] = usePatchProfileMutation();
  const [postUserRole, postUserRoleStatus] = usePostUserRoleMutation();
  const [deleteUserRole, deleteUserRoleStatus] = useDeleteUserRoleMutation();
  const { GENERIC_ERROR, PROFILE_UPDATED, addToast } = useNotifications();

  const updateProfile = async ({ oldpassword, dob, ...rest }) => {
    let formattedValues = formatValuesForApi(rest);
    if (oldpassword) {
      formattedValues.forEach((element) => {
        if (element.field === "password") {
          element.password = oldpassword;
        }
      });
    }
    if (dob) {
      let formattedDob = formatValuesForApi(dob);
      formattedValues = [...formattedValues, ...formattedDob];
    }
    try {
      await patchProfile(formattedValues).unwrap();
      addToast({ action: PROFILE_UPDATED });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
      throw error;
    }
  };

  const updateRole = async ({ currentRole, newRole }) => {
    try {
      if (currentRole) {
        await deleteUserRole(currentRole).unwrap();
      }
      await postUserRole(newRole).unwrap();
      addToast({ action: PROFILE_UPDATED });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
      throw error;
    }
  };

  return {
    ...profile,
    updateProfile,
    updateRole,
    patchStatus,
    postUserRoleStatus,
    deleteUserRoleStatus,
  };
};
