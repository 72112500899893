import React from "react";
import {
  NotificationAuthor,
  getAvatarSrc,
  getDisplayName,
  getFirstName,
} from "../../";

export const NotificationAuthorFollowed = ({ avatar, ...props }) => {
  return (
    <NotificationAuthor
      avatarName={getDisplayName(props)}
      avatarSrc={getAvatarSrc(avatar)}
      message={`Following ${getFirstName(props)}`}
    />
  );
};
