import { useNotifications } from ".";
import { usePostTextContributionMutation } from "../_Service";

export const useMakeTextContribution = () => {
  const [postContribution, postStatus] = usePostTextContributionMutation();
  const { GENERIC_ERROR, CONTRIBUTION_TEXT_SUCCESS, addToast } =
    useNotifications();

  const createTextContribution = async (text, call) => {
    try {
      const body = { text, callId: call?.id, interactionTypeId: 2 };
      await postContribution(body).unwrap();
      addToast({ action: CONTRIBUTION_TEXT_SUCCESS });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  return { createTextContribution, ...postStatus };
};
