import { AvatarBadge } from "@chakra-ui/react";
import { ButtonVerified } from "../../../1_Atoms";
import {
  CardAuthorWrapper,
  CardAuthorAvatar,
  CardAuthorName,
  CardAuthorRole,
} from "./ui";

export const CardAuthorPrimary = ({
  displayName,
  avatarSrc,
  roleTitle,
  verified,
  handleVerifiedClicked,
  to,
}) => (
    <CardAuthorWrapper {...{ to }}>
      <CardAuthorAvatar {...{ displayName, avatarSrc }}>
        {verified && (
          <AvatarBadge
            bg="white"
            fontSize="1.8rem"
            boxSize="1.6rem"
            top="0"
            right="-0.2em"
            zIndex="2"
            {...verified}
            as={ButtonVerified}
            onClick={handleVerifiedClicked}
          />
        )}
      </CardAuthorAvatar>
      <div>
        <CardAuthorName {...{ displayName }} />
        <CardAuthorRole {...{ roleTitle }} />
      </div>
    </CardAuthorWrapper>
);
