import { getUserFromSessionOrLocalStorage } from "../../_Utils";
import { postloginBaseParams } from "./config";
import { postLoginApi } from "./postlogin";

export const postFollowAuthor = {
  query: (author) => ({
    url: `UserFollowing`,
    params: {
      ...postloginBaseParams,
      followingUserId: author.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "POST",
  }),
  async onQueryStarted(author, { dispatch, queryFulfilled }) {
    const patch = dispatch(
      postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
        ...draft,
        peopleFollowing: [...draft.peopleFollowing,  author ],
      }))
    );
    const authorPatch = dispatch(
      postLoginApi.util.updateQueryData(
        "getAuthor",
        author?.id?.toString(),
        (draft) => ({
          ...draft,
          followers: draft.followers + 1,
        })
      )
    );
    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      authorPatch.undo();
      return error;
    }
  },
};

export const deleteFollowAuthor = {
  query: (author) => ({
    url: `UserFollowing`,
    params: {
      ...postloginBaseParams,
      followingUserId: author.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "DELETE",
  }),
  async onQueryStarted(author, { dispatch, queryFulfilled }) {
    const patch = dispatch(
      postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
        ...draft,
        peopleFollowing: draft.peopleFollowing?.filter(
          (c) => c.id !== author.id
        ),
      }))
    );
    const authorPatch = dispatch(
      postLoginApi.util.updateQueryData(
        "getAuthor",
        author?.id?.toString(),
        (draft) => ({
          ...draft,
          followers: draft.followers - 1,
        })
      )
    );
    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      authorPatch.undo();
      return error;
    }
  },
};
