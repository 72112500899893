import { getUserFromSessionOrLocalStorage } from "../../_Utils";
import { postloginBaseParams } from "./config";
import { postLoginApi } from "./postlogin";

export const getHelpfulList = {
  query: () => ({
    url: "HelpfulType",
    params: postloginBaseParams,
  }),
};

export const postUserHelpfulContribution = {
  query: ({ contribution, helpful }) => ({
    url: "userHelpfulContribution",
    params: {
      ...postloginBaseParams,
      contributionId: contribution?.id,
      helpfulTypeId: helpful?.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "POST",
  }),
  async onQueryStarted(
    { contribution, helpful, exists },
    { dispatch, queryFulfilled }
  ) {
    let patch;
    let authorPatch;

    if (exists) {
      patch = dispatch(
        postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
          ...draft,
          helpfulContributions: draft.helpfulContributions.map((item) => {
            if (item.id !== contribution.id) return item;
            return { ...item, userHelpfulType: helpful };
          }),
        }))
      );
    } else {
      patch = dispatch(
        postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
          ...draft,
          helpfulContributions: [
            ...draft.helpfulContributions,
            { ...contribution, userHelpfulType: helpful },
          ],
        }))
      );
      authorPatch = dispatch(
        postLoginApi.util.updateQueryData(
          "getAuthor",
          contribution?.author?.id?.toString(),
          (draft) => ({
            ...draft,
            contributionHelpfulCount: draft.contributionHelpfulCount + 1,
          })
        )
      );
    }

    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      if (!exists) authorPatch.undo();
      authorPatch.undo();
      return error;
    }
  },
};

export const deleteUserHelpfulContribution = {
  query: ({ contribution }) => ({
    url: "userHelpfulContribution",
    params: {
      ...postloginBaseParams,
      contributionId: contribution?.id,
      userId: getUserFromSessionOrLocalStorage().id,
    },
    method: "DELETE",
  }),
  async onQueryStarted({ contribution }, { dispatch, queryFulfilled }) {
    const patch = dispatch(
      postLoginApi.util.updateQueryData("getTransformedProfile", undefined, (draft) => ({
        ...draft,
        helpfulContributions: draft.helpfulContributions.filter(
          (item) => item.id !== contribution?.id
        ),
      }))
    );
    const authorPatch = dispatch(
      postLoginApi.util.updateQueryData(
        "getAuthor",
        contribution?.author?.id?.toString(),
        (draft) => ({
          ...draft,
          contributionHelpfulCount: draft.contributionHelpfulCount - 1,
        })
      )
    );
    try {
      await queryFulfilled;
    } catch (error) {
      patch.undo();
      authorPatch.undo();
      return error;
    }
  },
};
