import { ButtonRecord } from "../../../1_Atoms";

export const DictaphoneButtonRecord = ({
  isRecording,
  handleStartRecordingClick,
  handleStopRecordingClick,
}) => (
  <ButtonRecord
    onClick={isRecording ? handleStopRecordingClick : handleStartRecordingClick}
    {...{ isRecording }}
  />
);
