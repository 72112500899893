import { Box } from "@chakra-ui/react";

export const CardPrimary = (props) => (
  <Box
    isolation="isolate"
    bg="white"
    borderRadius="xl"
    boxShadow="md"
    d="flex"
    flexGrow="1"
    flexDir="column"
    overflow="hidden"
    w="100%"
    {...props}
  />
);
