import React from "react";
import { Box } from "@chakra-ui/react";

export const IllustrationHandShake = (props) => (
  <Box as="svg" viewBox="0 0 56.9 56.9" {...props}>
    <g id="b">
      <g id="c">
        <g>
          <g>
            <path
              className="g"
              fill="#995817"
              d="M30.34,50.28h0c-.73-.92-1.23-2-1.48-3.15l-2.6-12.23c-.2-.94,.36-1.88,1.29-2.14,.76-.22,1.57,.08,2.01,.72l4.38,6.39,3.62-7.59c.52-1.09,.62-2.33,.28-3.48l-3.58-12.16c-.27-.9,.15-1.86,.99-2.28,.9-.45,2-.13,2.52,.73l8.72,14.35c.83,1.37,1.12,3,.81,4.57l-2.49,12.56c-.56,2.81-2.53,5.13-5.21,6.14l-.22,.08c-3.24,1.21-6.89,.2-9.03-2.51"
            />
            <path
              className="h"
              fill="#60350f"
              d="M36.57,54.21c-2.65,0-5.22-1.19-6.94-3.37-.81-1.03-1.38-2.24-1.65-3.52l-2.6-12.23c-.3-1.4,.55-2.8,1.92-3.2,1.13-.33,2.34,.11,3,1.08l3.49,5.1,2.95-6.18c.42-.89,.5-1.9,.23-2.84l-3.58-12.16c-.39-1.32,.22-2.73,1.45-3.34,1.31-.66,2.94-.19,3.7,1.06l8.72,14.35c.95,1.57,1.28,3.42,.92,5.22l-2.49,12.56c-.62,3.14-2.78,5.68-5.78,6.81l-.22,.08c-1.02,.38-2.08,.57-3.13,.57Zm-8.52-20.61c-.08,0-.17,.01-.25,.04-.46,.13-.75,.61-.65,1.08l2.6,12.23c.21,1.01,.67,1.97,1.3,2.78h0c1.91,2.41,5.13,3.31,8.01,2.23l.22-.08c2.41-.9,4.15-2.95,4.65-5.47l2.49-12.56c.27-1.35,.02-2.75-.69-3.93l-8.72-14.35c-.28-.46-.87-.63-1.35-.39-.45,.22-.67,.74-.53,1.22l3.58,12.16c.4,1.37,.29,2.84-.33,4.13l-3.62,7.59c-.14,.29-.42,.49-.75,.51-.32,.03-.63-.12-.81-.39l-4.38-6.39c-.18-.26-.46-.4-.76-.4Z"
            />
            <path
              className="f"
              fill="#ffba94"
              d="M26.56,6.62h0c.73,.92,1.23,2,1.48,3.15l2.6,12.23c.2,.94-.36,1.88-1.29,2.14-.76,.22-1.57-.08-2.01-.72l-4.38-6.39-3.62,7.59c-.52,1.09-.62,2.33-.28,3.48l3.58,12.16c.26,.9-.15,1.86-.99,2.28-.9,.45-2,.13-2.52-.73l-8.72-14.35c-.83-1.37-1.12-3-.81-4.57l2.49-12.56c.56-2.81,2.53-5.13,5.21-6.14l.22-.08c3.24-1.21,6.89-.2,9.03,2.51"
            />
            <path
              className="e"
              fill="#e58d3e"
              d="M20.8,43.64c-.97,0-1.91-.49-2.44-1.36l-8.72-14.35c-.95-1.57-1.28-3.42-.92-5.22l2.49-12.56c.62-3.14,2.78-5.68,5.78-6.81l.22-.08c3.62-1.36,7.67-.23,10.06,2.8h0c.81,1.03,1.38,2.24,1.65,3.52l2.6,12.23c.3,1.4-.55,2.8-1.92,3.2-1.13,.32-2.34-.11-3-1.08l-3.49-5.1-2.95,6.18c-.42,.89-.5,1.9-.23,2.84l3.58,12.16c.39,1.32-.22,2.73-1.45,3.34-.4,.2-.83,.3-1.26,.3Zm-.46-39.14c-.83,0-1.67,.15-2.49,.45l-.22,.08c-2.41,.9-4.15,2.95-4.65,5.47l-2.49,12.56c-.27,1.35-.02,2.75,.69,3.93l8.72,14.35c.28,.46,.87,.62,1.35,.39,.45-.22,.67-.74,.53-1.22l-3.58-12.16c-.4-1.37-.29-2.84,.33-4.13l3.62-7.59c.14-.29,.42-.49,.75-.51,.32-.03,.63,.12,.81,.39l4.38,6.39c.23,.33,.63,.47,1.02,.37,.46-.13,.75-.61,.65-1.08l-2.6-12.23c-.21-1.01-.66-1.97-1.3-2.78h0c-1.37-1.73-3.41-2.68-5.52-2.68Z"
            />
            <path
              className="h"
              fill="#60350f"
              d="M36.57,54.21c-2.65,0-5.22-1.19-6.94-3.37-.81-1.03-1.38-2.24-1.65-3.52l-2.6-12.23c-.3-1.4,.55-2.8,1.92-3.2,1.13-.33,2.34,.11,3,1.08l3.49,5.1,2.95-6.18c.42-.89,.5-1.9,.23-2.84l-3.58-12.16c-.39-1.32,.22-2.73,1.45-3.34,1.31-.66,2.94-.19,3.7,1.06l8.72,14.35c.95,1.57,1.28,3.42,.92,5.22l-2.49,12.56c-.62,3.14-2.78,5.68-5.78,6.81l-.22,.08c-1.02,.38-2.08,.57-3.13,.57Zm-8.52-20.61c-.08,0-.17,.01-.25,.04-.46,.13-.75,.61-.65,1.08l2.6,12.23c.21,1.01,.67,1.97,1.3,2.78h0c1.91,2.41,5.13,3.31,8.01,2.23l.22-.08c2.41-.9,4.15-2.95,4.65-5.47l2.49-12.56c.27-1.35,.02-2.75-.69-3.93l-8.72-14.35c-.28-.46-.87-.63-1.35-.39-.45,.22-.67,.74-.53,1.22l3.58,12.16c.4,1.37,.29,2.84-.33,4.13l-3.62,7.59c-.14,.29-.42,.49-.75,.51-.32,.03-.63-.12-.81-.39l-4.38-6.39c-.18-.26-.46-.4-.76-.4Z"
            />
            <path
              className="e"
              fill="#e58d3e"
              d="M20.8,43.64c-.97,0-1.91-.49-2.44-1.36l-8.72-14.35c-.95-1.57-1.28-3.42-.92-5.22l2.49-12.56c.62-3.14,2.78-5.68,5.78-6.81l.22-.08c3.62-1.36,7.67-.23,10.06,2.8h0c.81,1.03,1.38,2.24,1.65,3.52l2.6,12.23c.3,1.4-.55,2.8-1.92,3.2-1.13,.32-2.34-.11-3-1.08l-3.49-5.1-2.95,6.18c-.42,.89-.5,1.9-.23,2.84l3.58,12.16c.39,1.32-.22,2.73-1.45,3.34-.4,.2-.83,.3-1.26,.3Zm-.46-39.14c-.83,0-1.67,.15-2.49,.45l-.22,.08c-2.41,.9-4.15,2.95-4.65,5.47l-2.49,12.56c-.27,1.35-.02,2.75,.69,3.93l8.72,14.35c.28,.46,.87,.62,1.35,.39,.45-.22,.67-.74,.53-1.22l-3.58-12.16c-.4-1.37-.29-2.84,.33-4.13l3.62-7.59c.14-.29,.42-.49,.75-.51,.32-.03,.63,.12,.81,.39l4.38,6.39c.23,.33,.63,.47,1.02,.37,.46-.13,.75-.61,.65-1.08l-2.6-12.23c-.21-1.01-.66-1.97-1.3-2.78h0c-1.37-1.73-3.41-2.68-5.52-2.68Z"
            />
          </g>
          <rect className="d" fill="none" width="56.9" height="56.9" />
        </g>
      </g>
    </g>
  </Box>
);
