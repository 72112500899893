import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

export const NotificationError = ({ error }) => (
  <Alert w="100%" status="error" bg="red.500" color="white">
    <AlertIcon color="white" />
    <AlertDescription>
      <AlertTitle mr="0" as="span">
        Error:
      </AlertTitle>{" "}
      {error}
    </AlertDescription>
  </Alert>
);
