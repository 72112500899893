import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { loaderVariants } from "./framerVariants";

export const ButtonBaseChildrenWrapper = ({ isLoading, children }) => (
  <Box
    as={motion.div}
    variants={loaderVariants}
    animate={isLoading ? "hide" : "show"}
    {...{ children }}
  />
);
