import { Box } from "@chakra-ui/react";

export const Card = ({ children, ...props }) => (
  <Box
    // isolation="isolate"
    bg="white"
    border="solid 1px"
    borderColor="themegray.500"
    borderRadius="lg"
    d="flex"
    flexGrow="1"
    flexDir="column"
    overflow="hidden"
    w="100%"
    {...props}
  >
    {children}
  </Box>
);
