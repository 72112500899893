import React from "react";
import { Box } from "@chakra-ui/react";
import {  NotificationGenericError } from "../../";
import { LoaderSpinner, LogoPrimary } from "../../../ui";

export const LoaderLoggedIn = ({ loading, error, children }) =>
  error ? (
    <Box flexGrow="1" bg="primary.base" d="flex" alignItems="center" px="2rem">
      <NotificationGenericError {...error} />
    </Box>
  ) : loading ? (
    <Box
      flexGrow="1"
      bg="primary.base"
      d="flex"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
    >
      <LoaderSpinner />
      <LogoPrimary reverse={true} maxW="300px" w="90%" mx="auto" mt="2rem" />
    </Box>
  ) : (
    children
  );
