import { Text } from "@chakra-ui/react";

export const FormSuccessTitle = () => (
  <Text
    color="white"
    textAlign="center"
    as="h1"
    fontSize="2xl"
    fontWeight="heading.bold"
  >
    Check your mail
  </Text>
);
