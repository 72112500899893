import { Box } from "@chakra-ui/react";
import { Badge, VideoPlayer } from "../../../1_Atoms";

const COLORS = { call: "accentone.base", contribution: "accenttwo.base" };

export const CardVideoBlock = ({ variant = "call", video }) => {
  if (!video) return null;
  return (
    <Box pos="relative">
      <VideoPlayer overlayColor={COLORS[variant]} {...video} />
      <Badge
        bg={COLORS[variant]}
        pos="absolute"
        top="2"
        left="2"
        pointerEvents="none"
      >
        {variant}
      </Badge>
    </Box>
  );
};
