import { FaMicrophone } from "react-icons/fa";
import { MediaSourceDropdown } from "../../../ui/2_Molecules/MediaSourceDropdown/MediaSourceDropdown";

export const MicSelect = ({
  audioSources,
  audioDeviceId,
  setChosenAudioSourceId,
}) => {
  return (
    <MediaSourceDropdown
      options={audioSources?.map((source) => ({
        label: source.label,
        value: source.deviceId,
      }))}
      onChange={(v) => setChosenAudioSourceId(v)}
      value={audioDeviceId}
      iconButton={<FaMicrophone />}
    />
  );
};
