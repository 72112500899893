import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormControlInputDob, FormControlSelect } from "../../../ui";
import { FormSelect } from "../../1_Atoms";
import {
  useCountryList,
  useEthnicityList,
  useGenderList,
  useSignUp,
  useStateList,
} from "../../_Hooks";
import { formValidation } from "../../_Utils";
import { ButtonBack } from "./ButtonBack";
import { ButtonNext } from "./ButtonNext";

export const SignUpStepThree = ({ onPrevClicked, onNextClicked, active }) => {
  const signUp = useSignUp();
  const countryList = useCountryList();
  const stateList = useStateList();
  const genderList = useGenderList();
  const ethnicityList = useEthnicityList();
  const {
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const countryId = watch("countryId");

  const onSubmit = (data) => {
    let updated = data;
    if (data?.countryId !== "187") {
      updated = { ...updated, stateId: "" };
    }
    signUp.updateValues(updated);
    onNextClicked();
  };

  useEffect(() => {
    if (signUp.values) {
      const {
        ethnicityId,
        genderId,
        countryId,
        onlyDisplayInitials,
        dob,
        stateId,
      } = signUp.values;
      reset({
        ethnicityId,
        genderId,
        countryId,
        stateId,
        onlyDisplayInitials,
        dob,
      });
    }
  }, [signUp.values, reset]);

  return (
    <Box color="white">
      <Heading mb="2rem">Personal details</Heading>
      <form style={{ textAlign: "left" }} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Controller
            control={control}
            name="dob"
            rules={{
              required: "Date of birth is required",
              validate: formValidation.isValidDob,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControlInputDob
                label="Date of birth"
                disabled={!active}
                {...field}
                error={error?.message}
                isInvalid={error?.message}
                reverse
              />
            )}
          />

          <FormControlSelect
            {...register("genderId", {
              required: "Gender required",
            })}
            error={errors.genderId?.message}
            isInvalid={errors.genderId?.message}
            defaultValue=""
            label="Gender"
            disabled={!active}
            reverse
          >
            <option value="" disabled>
              Select gender
            </option>
            {genderList.data?.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </FormControlSelect>

          <FormControlSelect
            {...register("ethnicityId", {
              required: "Ethnicity required",
            })}
            error={errors.ethnicityId?.message}
            isInvalid={errors.ethnicityId?.message}
            defaultValue=""
            label="Ethnicity"
            disabled={!active}
            reverse
          >
            <option value="" disabled>
              Select ethnicity
            </option>
            {ethnicityList.data?.map(({ id, title }) => (
              <option key={id} value={id}>
                {title}
              </option>
            ))}
          </FormControlSelect>

          <FormControlSelect
            {...register("countryId", {
              required: "Country required",
            })}
            error={errors.countryId?.message}
            isInvalid={errors.countryId?.message}
            defaultValue=""
            label="Country"
            disabled={!active}
            reverse
          >
            <option value="" disabled>
              Select country
            </option>
            {countryList.data?.map(({ id, title }, index) => (
              <option key={`${index}{id}`} value={id}>
                {title}
              </option>
            ))}
          </FormControlSelect>

          {countryId === "187" && (
            <FormControlSelect
              {...register("stateId", {
                required: "State required",
              })}
              error={errors.stateId?.message}
              isInvalid={errors.stateId?.message}
              defaultValue=""
              label="State"
              disabled={!active}
              reverse
            >
              <option value="" disabled>
                Select state
              </option>
              {stateList.data?.map(({ id, state }) => (
                <option key={id} value={id}>
                  {state}
                </option>
              ))}
            </FormControlSelect>
          )}

          <Flex pt="1rem" justifyContent="center">
            <ButtonBack disabled={!active} onClick={onPrevClicked} />
            <ButtonNext disabled={!active} type="submit" />
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};
