import PropTypes from "prop-types";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CardCallSmallTitle, CardCallSmallWrapper } from "./ui";
import { CardThumbnail } from "../../../../1_Atoms";

export const CardCallSmall = ({ title, to, thumbnail }) => (
  <CardCallSmallWrapper>
    <Link to={to}>
      <Flex alignItems="flex-start" gap="3">
        <CardThumbnail src={thumbnail} alt={title} w="80px" ratio={1} />
        <CardCallSmallTitle {...{ to, title }} />
      </Flex>
    </Link>
  </CardCallSmallWrapper>
);

CardCallSmall.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
};
