import React from "react";
import { Box, Text, Heading, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      bg="primary.base"
      pos="fixed"
      w="100vw"
      h="100vh"
      left="0"
      top="0"
      d="flex"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
    >
      <Heading fontSize="3.5rem" color="white">
        404
      </Heading>
      <Text mt="1rem" color="white" as="p">
        Sorry, page not found
      </Text>
      <Button mt="3rem" onClick={() => navigate("/")}>
        Back to home
      </Button>
    </Box>
  );
};
