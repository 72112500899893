import { CardCallSmall } from "./components";
import {
  LatestCallsSideBarStack,
  LatestCallsSideBarTitle,
  LatestCallsSideBarWrapper,
} from "./ui";

export const LatestCallsSideBar = ({ isLoading, list }) => (
  <>
    {!isLoading && (
      <LatestCallsSideBarWrapper>
        <LatestCallsSideBarTitle />
        <LatestCallsSideBarStack>
          {list?.map((item) => (
            <CardCallSmall key={item?.id} {...item} />
          ))}
        </LatestCallsSideBarStack>
      </LatestCallsSideBarWrapper>
    )}
  </>
);
