import { HeroPersonWrapper } from "./ui";
import { HeroPersonDetails, HeroPersonExtraDetails } from "./components";
import { Stack } from "@chakra-ui/react";

export const HeroPerson = ({
  displayName,
  verified,
  handleVerifiedClicked,
  avatarSrc,
  roleTitle,
  followers,
  likes,
  address,
  isFollowing,
  handleFollowClick,
  followIsLoading,
  isCurrentUser,
  isCompany,
}) => (
  <HeroPersonWrapper>
    <Stack spacing={4}>
      <HeroPersonDetails
        {...{
          displayName,
          avatarSrc,
          verified,
          handleVerifiedClicked,
          roleTitle,
          isCompany,
        }}
      />
      <HeroPersonExtraDetails
        {...{
          followIsLoading,
          handleFollowClick,
          isFollowing,
          likes,
          address,
          followers,
          isCurrentUser,
        }}
      />
    </Stack>
  </HeroPersonWrapper>
);
