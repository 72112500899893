import { Box, Flex, HStack, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ButtonFollow, ButtonVerified } from "../../../1_Atoms";
import {
  ListItemPersonWrapper,
  ListItemPersonAvatar,
  ListItemPersonRole,
  ListItemPersonName,
} from "./ui";

export const ListItemPerson = ({
  displayName,
  avatarSrc,
  roleTitle,
  verified,
  handleVerifiedClicked,
  isCompany,
  isFollowing,
  handleFollowClick,
  followIsLoading,
  to,
}) => (
  <ListItemPersonWrapper>
    <Flex justifyContent="space-between" alignItems="center">
      <Box flexGrow={1} py={3} as={Link} {...{ to }}>
        <HStack alignItems="center" spacing={2}>
          <ListItemPersonAvatar {...{ displayName, avatarSrc }} />
          <Stack spacing={0}>
            <HStack spacing={1}>
              <ListItemPersonName {...{ displayName }} />
              {verified && <ButtonVerified onClick={handleVerifiedClicked} />}
            </HStack>
            <ListItemPersonRole {...{ isCompany, roleTitle }} />
          </Stack>
        </HStack>
      </Box>
      <ButtonFollow
        isLoading={followIsLoading}
        onClick={handleFollowClick}
        {...{ isFollowing }}
      />
    </Flex>
  </ListItemPersonWrapper>
);
