import React from "react";
import {
  NotificationAuthor,
  getAvatarSrc,
  getDisplayName,
  getFirstName,
} from "../../";

export const NotificationAuthorUnfollowed = ({ avatar, ...props }) => {
  return (
    <NotificationAuthor
      avatarName={getDisplayName(props)}
      avatarSrc={getAvatarSrc(avatar)}
      message={`Unfollowed ${getFirstName(props)}`}
    />
  );
};
