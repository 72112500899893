import { Box, Button, Heading, HStack, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useContribution, ModalPrimary } from "../../";
import { LoaderSpinner } from "../../../ui";
import { modalClose } from "../../_Redux/modalsSlice";

export const ModalDeleteContribution = () => {
  const modal = useSelector((state) => state.modals).modalContribution;
  const dispatch = useDispatch();
  const contribution = useContribution();

  const close = () => {
    dispatch(modalClose({ modal: "modalContribution" }));
  };

  const onDelete = () => {
    contribution.deleteContribution(modal?.contribution).then(() => close());
  };

  return (
    <ModalPrimary isOpen={modal.isOpen} onClose={() => close()}>
      {contribution.isLoading && (
        <Box d="flex" flexDir="column" alignItems="center">
          <LoaderSpinner />
          <Text mt="1rem">Deleting...</Text>
        </Box>
      )}
      {!contribution.isLoading && (
        <Box maxW="300px" mx="auto" textAlign="center">
          <Heading fontSize="1.2rem" mb="0.5rem">
            Delete Contribution?
          </Heading>
          <Text mb="1rem">
            Are you sure you want to delete your contribution? This cannot be
            undone.
          </Text>
          <HStack justifyContent="center">
            <Button size="sm" onClick={() => close()}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="red" onClick={() => onDelete()}>
              Yes, I'm sure
            </Button>
          </HStack>
        </Box>
      )}
    </ModalPrimary>
  );
};
