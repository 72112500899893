import { useGetCountryListQuery } from "../_Service";
import { getItemFromArrayById } from "../_Utils";
import { useBaseApi } from "./useBaseApi";

export const useCountryList = () => {
  const { data, ...rest } = useBaseApi({ query: useGetCountryListQuery });
  const getCountryById = (id) => getItemFromArrayById(id, data);

  return {
    data,
    ...rest,
    getCountryById,
  };
};
