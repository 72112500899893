import { Box } from "@chakra-ui/react";

export const NotificationBase = (props) => (
  <Box
    fontWeight="body.bold"
    p="1.2rem 1.1rem"
    color="white"
    bg="primary.base"
    boxShadow="md"
    alignItems="center"
    borderRadius="md"
    {...props}
  />
);
