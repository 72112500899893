import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const CardWrapper = ({ children, to }) => (
  <Box
    as={Link}
    {...{ to }}
    bg="white"
    p={4}
    rounded="md"
    shadow="sm"
    w="full"
    role="group"
    d='flex'
  >
    {children}
  </Box>
);
