import { useEffect, useState } from "react";
import { CardMediaPermissionDenied } from "../../../../../../../../..";

export const AudioSourcePermission = ({ children }) => {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => setSuccess(true))
      .catch((err) => setError(err.message));
  }, []);

  if (success) return children;
  if (error) return <CardMediaPermissionDenied maxW='500px' mx='auto' />;
  return null;
};
