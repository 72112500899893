import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { CardCall } from "../../";

export const ListCardCalls = ({ data }) => (
  <Box flexGrow="1" bg="themegray.200">
    <Stack
      p="1.5rem"
      px="2rem"
      d="flex"
      flexDir="column"
      alignItems="center"
      spacing={6}
    >
      {data?.map((call) => (
        <CardCall key={call.id} data={call} />
      ))}
    </Stack>
  </Box>
);
