import { Avatar } from "../../../../1_Atoms";

export const CardAuthorAvatar = ({ displayName, avatarSrc }) => (
  <Avatar
    boxSize={{ base: 10 }}
    fontSize={{ base: "sm" }}
    name={displayName}
    src={avatarSrc}
  />
);
