import { Heading, Stack, Text } from "@chakra-ui/react";
import { IllustrationEmptyStateCalls } from "../../1_Atoms";
import { getFirstName } from "../../_Utils";

export const EmptyStateCallsVerifiedAuthor = ({ author }) => (
  <>
    <IllustrationEmptyStateCalls
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        {getFirstName(author)} hasn&#8217;t made any calls
      </Heading>
      <Text fontSize={{ base: "0.9rem", md: "1rem" }}>
        Contact us if you&#8217;re interested in becoming an ambassador
      </Text>
    </Stack>
  </>
);
