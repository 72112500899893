import { Badge as BadgeChakra } from "@chakra-ui/react";

export const Badge = (props) => (
  <BadgeChakra
    bg="primary.base"
    color="white"
    borderRadius="md"
    px="8px"
    py="4px"
    lineHeight="1"
    fontWeight="body.black"
    alignSelf='flex-start'
    {...props}
  />
);
