import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import {
  useSignUp,
  useGenderList,
  useCountryList,
  useEthnicityList,
  useRoleList,
  useStateList,
  Onboard,
  SignUp,
  SignUpConfirmation,
} from "../..";
import { AnimatePresence, motion } from "framer-motion";
import { PageSignUpHeader } from "./components";

export const PageSignUp = () => {
  const { isSuccess } = useSignUp();
  const genderList = useGenderList();
  const ethnicityList = useEthnicityList();
  const stateList = useStateList();
  const countryList = useCountryList();
  const roleList = useRoleList();
  const [onBoarding, setOnBoarding] = useState(true);
  return (
    <Background>
      <PageSignUpHeader />
      <Flex flexGrow="1" w="full" flexDir="column">
        <Box d="flex" flexGrow="1">
          <AnimatePresence unmountOnExit={true}>
            {isSuccess && (
              <FadeInOut>
                <SignUpConfirmation />
              </FadeInOut>
            )}
          </AnimatePresence>
          {roleList.data &&
            stateList.data &&
            countryList.data &&
            genderList.data &&
            ethnicityList.data && (
              <AnimatePresence unmountOnExit={true}>
                {!onBoarding && !isSuccess && (
                  <FadeInOut>
                    <SignUp
                      onBackToBoardingClick={() => {
                        setOnBoarding(true);
                      }}
                    />
                  </FadeInOut>
                )}
              </AnimatePresence>
            )}

          <AnimatePresence unmountOnExit={true}>
            {!isSuccess && onBoarding && (
              <FadeInOut>
                <Onboard onFinishClick={() => setOnBoarding(false)} />
              </FadeInOut>
            )}
          </AnimatePresence>
        </Box>
      </Flex>
    </Background>
  );
};

// UI elements
const Background = (props) => (
  <Box
    w="100%"
    flexGrow="1"
    d="flex"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    overflow="hidden"
    {...props}
  />
);

const FadeInOut = ({ children }) => (
  <motion.div
    initial={{
      opacity: 0,
      position: "absolute",
      transform: "scale(0.9)",
    }}
    animate={{ opacity: 1, position: "relative", transform: "scale(1)" }}
    exit={{ opacity: 0, position: "absolute", transform: "scale(0.9)" }}
    style={{
      width: "100%",
      top: 0,
      left: 0,
      height: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    {children}
  </motion.div>
);
