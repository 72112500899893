import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  PageCalls,
  PageTimeline,
  PageNotFound,
  PageSignIn,
  PageSignUp,
  RootLoggedIn,
  RootNotLoggedIn,
  useAuthentication,
  PageCall,
  PageProfile,
  PagePeople,
  PagePerson,
  PageAccount,
  PagePersonCalls,
  PagePersonContributions,
  PageSaved,
  PageAccountName,
  PageAccountDob,
  PageAccountLocation,
  PageAccountAvatar,
  PageProfileCalls,
  PageProfileContributions,
  PageAccountEmail,
  PageAccountGender,
  PageAccountPassword,
  PageAccountRole,
  PageAmbassadors,
  PageAccountEthnicity,
  PageContact,
  PageForgotPassword,
} from "..";
import { ModalTerms } from "../3_Organisms/Modals";
import { PageSignInForm } from "../4_Pages/PageSignInForm/PageSignInForm";
import ReactGA from "react-ga4";

export const RootBase = () => {
  const { id } = useAuthentication();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  return (
    <>
      <ModalTerms />
      <Routes>
        {id && (
          <>
            <Route path="*" element={<PageNotFound />} />
            <Route element={<RootLoggedIn />}>
              <Route index element={<Navigate to="/calls" replace />} />
              <Route path="/activity" element={<PageTimeline />} />
              <Route path="/calls" element={<PageCalls />} />
              <Route path="calls/:callId" element={<PageCall />} />
              <Route path="/profile" element={<PageProfile />}>
                <Route index element={<PageProfileContributions />} />
                <Route path="calls" element={<PageProfileCalls />} />
              </Route>
              <Route path="/contact" element={<PageContact />} />
              <Route path="/ambassadors" element={<PageAmbassadors />} />
              <Route path="/my-account/*" element={<PageAccount />}>
                <Route path="name" element={<PageAccountName />} />
                <Route path="location" element={<PageAccountLocation />} />
                <Route path="dob" element={<PageAccountDob />} />
                <Route path="avatar" element={<PageAccountAvatar />} />
                <Route path="email" element={<PageAccountEmail />} />
                <Route path="gender" element={<PageAccountGender />} />
                <Route path="ethnicity" element={<PageAccountEthnicity />} />
                <Route path="password" element={<PageAccountPassword />} />
                <Route path="role" element={<PageAccountRole />} />
              </Route>
              <Route path="/people" element={<PagePeople />} />
              <Route path="/saved" element={<PageSaved />} />
              <Route path="people/:id" element={<PagePerson />}>
                <Route index element={<PagePersonContributions />} />
                <Route path="calls" element={<PagePersonCalls />} />
              </Route>
            </Route>
          </>
        )}
        <Route element={<RootNotLoggedIn />}>
          <Route path="/sign-in" element={<PageSignIn />}>
            <Route index element={<PageSignInForm />} />
            <Route
              path="/sign-in/forgot-password"
              element={<PageForgotPassword />}
            />
          </Route>
          <Route path="/sign-up" element={<PageSignUp />} />
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Route>
      </Routes>
    </>
  );
};
