import { MdVerified } from "react-icons/md";
import { Button, Icon } from "@chakra-ui/react";

export const ButtonVerified = ({ type = "Rep", ...props }) => {
  const TYPEVARIANTS = {
    Rep: {
      color: "accenttwo.base",
      _focus: { ring: "2", ringColor: "accenttwo.base" },
      _hover: { color: "accenttwo.2200" },
    },
    Ambassador: {
      color: "accentone.base",
      _focus: { ring: "2", ringColor: "accentone.base" },
      _hover: { color: "accentone.2200" },
    },
  };
  return (
    <Button
      variant="unstyled"
      d="flex"
      alignItems="center"
      boxSize="1.38rem"
      minH="auto"
      minW="auto"
      borderRadius="full"
      {...TYPEVARIANTS[type]}
      {...props}
    >
      <Icon as={MdVerified} fontSize="xl" />
    </Button>
  );
};
