// import { useEffect, useRef } from "react";
// import videojs from "video.js";

// const defaultOptions = {
//   autoplay: false,
//   controls: true,
//   preload: "none",
// };

// export const AudioPlayerVideoJs = ({ options }) => {
//   const audioRef = useRef();
//   const playerRef = useRef();

//   useEffect(() => {
//     if (!playerRef.current) {
//       const audioElement = audioRef.current;

//       playerRef.current = videojs(
//         audioElement,
//         { ...defaultOptions, ...options },
//         () => {
//           playerRef.current.player().currentTime(100);
//         }
//       );

//       playerRef.current.on("durationchange", () => {
//         if (isFinite(playerRef.current.player().getCache().duration)) {
//           playerRef.current.player().currentTime(0);
//         }
//       });
//     } else {
//       playerRef.current.player().src(options?.sources);
//       playerRef.current.player().currentTime(100);
//     }
//   }, [audioRef, options]);

//   return (
//     <div style={{ zIndex: 2 }} data-vjs-player>
//       <audio ref={audioRef} className="video-js vjs-theme-fantasy" />
//     </div>
//   );
// };

import { memo, useEffect, useRef } from "react";
import videojs from "video.js";

export const AudioPlayerVideoJs = memo(
  ({ preload = "none", sources }) => {
    const videoRef = useRef();
    const playerRef = useRef();

    useEffect(() => {
      const options = {
        autoplay: false,
        controls: true,
        preload,
        sources,
      };
      if (!playerRef.current) {
        const videoElement = videoRef.current;
        if (!videoElement) return;
        playerRef.current = videojs(videoElement, options, () => {});
      } else {
        // you can update player here [update player through props]
        const player = playerRef.current;
        // player.autoplay(options.autoplay);
        player.src(options.sources);
      }
    }, [videoRef, sources]);

    useEffect(() => {
      const player = playerRef.current;
      return () => {
        if (player) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (
      <div style={{ zIndex: 2 }} data-vjs-player>
        <audio ref={videoRef} className="video-js vjs-theme-fantasy" />
      </div>
    );
  }
);
