import { Box } from "@chakra-ui/react";
import { forwardRef } from "react";
import { LoaderSpinner } from "../../../ui";
import { VideoContainer } from "./VideoContainer";
import { VideoControls } from "./VideoControls";
import { VideoWebcam } from "./VideoWebcam";

export const VideoRecorder = forwardRef(({ mediaRecorder }, ref) => {
  return (
    <VideoContainer>
      <Box pos="absolute" h="100%" w="100%" d="flex" justifyContent="center">
        <LoaderSpinner />
      </Box>
      <VideoWebcam ref={ref} />
      <VideoControls {...{ mediaRecorder }} />
    </VideoContainer>
  );
});
