import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { useCalls } from "../_Hooks/useCalls";
import {
  LayoutTwoColumn,
  PageTitle,
  AuthorSearch,
  LoaderSection,
  CardCall,
} from "..";
import { ListSortAndFilter } from "../../ui";

export const PageCalls = () => {
  const calls = useCalls();
  return (
    <LayoutTwoColumn>
      <Box w="100%" d="flex" flexDir="column" bg="themegray.200">
        <PageTitle title="Calls" />
        <LoaderSection loading={calls.isLoading} error={calls.error}>
          <ListSortAndFilter
            onChange={calls?.filterUpdated}
            onSortChange={calls?.sortUpdated}
            filterDropdowns={calls?.filterDropdowns}
            sortOptions={{ value: calls?.sort, options: calls?.sortOptions }}
            search={calls?.search}
            onSearchChange={calls?.searchUpdated}
          />
          <Stack
            spacing="1.5rem"
            bg="themegray.200"
            alignItems="center"
            flexGrow="1"
            p="1.5rem"
          >
            {calls?.data?.map((call) => (
              <CardCall key={call?.id} variant="wide" data={call} />
            ))}
          </Stack>
        </LoaderSection>
      </Box>
      <Box p="1.5rem">
        <AuthorSearch />
      </Box>
    </LayoutTwoColumn>
  );
};
