import { useEffect, useState } from "react";
import { useGetTimelineQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const useTimeline = () => {
  const timeline = useBaseApi({
    query: useGetTimelineQuery,
    params: [{ count: 1 }, { refetchOnMountOrArgChange: true }],
  });
  const [list, setList] = useState();

  useEffect(() => {
    if (timeline.data?.contribution && timeline.data?.call) {
      setList(
        [...timeline.data.contribution, ...timeline.data.call].sort((a, b) => {
          return a.updatedAt > b.updatedAt
            ? -1
            : a.updatedAt < b.updatedAt
            ? 1
            : 0;
        })
      );
    }
  }, [timeline.data]);

  return { ...timeline, list };
};
