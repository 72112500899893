import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";

export const NotificationGenericError = ({ message }) => {
  return (
    <Alert w="100%" status="error" bg="red.500" color="white">
      <AlertIcon color="white" />
      <AlertDescription>
        <AlertTitle mr="0" as="span">
          Error:
        </AlertTitle>{" "}
        {message}
      </AlertDescription>
    </Alert>
  );
};
