import { Box } from "@chakra-ui/react";

export const FogotPasswordFormWrapper = ({ children, onSubmit }) => (
  <Box
    w="full"
    maxW="sm"
    px={4}
    mx="auto"
    d="flex"
    flexDir="column"
    alignItems="center"
    gap={6}
    {...{ onSubmit }}
  >
    {children}
  </Box>
);
