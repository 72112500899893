import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { normalizeErrorNotification } from "../../_Utils";

export const preloginBaseParams = {
  "subscription-key": process.env.REACT_APP_API_PRE_LOGIN_SUBSCRIPTION_KEY,
  platformId: process.env.REACT_APP_API_PLATFORM_ID,
  groupId: process.env.REACT_APP_GROUP_ID,
};

export const postloginBaseParams = {
  "subscription-key": process.env.REACT_APP_API_POST_LOGIN_SUBSCRIPTION_KEY,
  platformId: process.env.REACT_APP_API_PLATFORM_ID,
  groupId: process.env.REACT_APP_GROUP_ID,
};

const baseQueryWithFormattedErrors =
  (baseQuery) => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result?.data?.errorCode) {
      throw normalizeErrorNotification(result.data);
    }
    if (result?.error) {
      throw normalizeErrorNotification(result.error);
    }
    return result;
  };

const baseQuery = (baseUrl) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set(
        "productLevelToken",
        process.env.REACT_APP_API_JWT_PRODUCT_LEVEL
      );
      const { jwtLoginToken } = getState().auth;
      if (jwtLoginToken) headers.set("loginToken", jwtLoginToken);
      return headers;
    },
  });

export const postLoginBaseQueryWithLogic = baseQueryWithFormattedErrors(
  baseQuery(process.env.REACT_APP_POST_LOGIN_API_PREFIX)
);

export const preLoginBaseQueryWithLogic = baseQueryWithFormattedErrors(
  baseQuery(process.env.REACT_APP_PRE_LOGIN_API_PREFIX)
);
