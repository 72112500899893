import { useDispatch, useSelector } from "react-redux";
import { ModalPrimary, RepExplanation } from "../../";
import { modalClose } from "../../_Redux/modalsSlice";

export const ModalRepExplanation = () => {
  const modal = useSelector((state) => state.modals).modalRepExplanation;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalClose({ modal: "modalRepExplanation" }));
  };

  return (
    <ModalPrimary isOpen={modal.isOpen} onClose={() => close()}>
      <RepExplanation onClick={() => close()} {...modal} />
    </ModalPrimary>
  );
};
