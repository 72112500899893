import { useDispatch, useSelector } from "react-redux";
import { removeCredentials } from "../_Redux/authSlice";
import { postLoginApi, useGetUserLoginMutation } from "../_Service";

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.auth);
  const [userLogin, userLoginStatus] = useGetUserLoginMutation();

  const login = (username, password) => userLogin({ username, password });

  const logout = () => {
    dispatch(removeCredentials());
    dispatch(postLoginApi.util.resetApiState());
  };
  
  return { id, login, logout, ...userLoginStatus };
};
