import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const FormSignInPasswordHelper = () => (
  <Text
    mt={2}
    color="white"
    as={Link}
    to="/sign-in/forgot-password"
    fontWeight="body.bold"
    fontSize="sm"
    _hover={{ textDecor: "underline" }}
  >
    Forgot password?
  </Text>
);
