import PropTypes from "prop-types";
import { ButtonBase } from "../ButtonBase/ButtonBase";

export const ButtonPrimary = (props) => (
  <ButtonBase
    bg="primary.base"
    color="white"
    spinnerColor="white"
    spinnerEmptyColor="rgba(255,255,255,0.5)"
    _hover={{ bg: "primary.2500" }}
    _active={{ bg: "primary.2200" }}
    {...props}
  />
);

ButtonPrimary.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingLabel: PropTypes.string,
  hasLoadingLabel: PropTypes.bool,
};
