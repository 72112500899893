import React from "react";
import { Box } from "@chakra-ui/react";

export const LogoPrimary = ({ reverse, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 701.94 108.07"
    {...props}
  >
    <path
      style={{ fill: "var(--chakra-colors-accentone-base)" }}
      d="M716.45,581.29c-7.78-5.5-8.78-15.6-8.4-22.56a54,54,0,1,0-19.34,17.92c16,9,26.36,6.31,27.31,6.12A.81.81,0,0,0,716.45,581.29Zm-18-52A35.58,35.58,0,0,1,697,539.52c0,.11-.06.23-.1.35A35.73,35.73,0,0,1,662.77,565h0c-.53,0-1.06,0-1.59,0l-.38,0q-.79,0-1.56-.12l-.29,0-1.36-.17-.34-.05c-.5-.08-1-.17-1.49-.27l-.39-.08-1.32-.3-.22-.06c-.48-.12-1-.26-1.43-.41l-.42-.13c-.47-.14-.93-.3-1.38-.46l-.3-.12c-.39-.14-.77-.29-1.15-.45l-.4-.17c-.44-.18-.86-.38-1.29-.58l-.4-.19-1-.54-.33-.17,0,0c-10.6,5.92-17.43,4.17-18.05,4a.54.54,0,0,1-.28-1c5.14-3.64,5.8-10.31,5.55-14.91-.13-.2-.26-.4-.38-.61s-.2-.3-.29-.45c-.24-.4-.47-.81-.7-1.22l-.29-.54c-.15-.28-.29-.57-.43-.85s-.19-.37-.27-.55c-.19-.39-.37-.78-.54-1.18-.06-.16-.13-.31-.19-.47-.15-.37-.3-.74-.44-1.11l-.2-.54c-.12-.34-.23-.68-.34-1l-.15-.48c-.15-.48-.29-1-.42-1.45l-.09-.4c-.1-.38-.19-.77-.27-1.16,0-.17-.07-.34-.11-.51-.08-.42-.15-.84-.22-1.26l-.06-.32c-.07-.53-.14-1-.2-1.58,0-.15,0-.29,0-.43,0-.42-.07-.83-.09-1.24l0-.48c0-.54,0-1.09,0-1.64h0c0-.62,0-1.23.05-1.84s.08-1.22.14-1.82a35,35,0,0,1,1.21-6.24c.11-.38.22-.75.34-1.13a35.73,35.73,0,0,1,68,0c.12.38.23.75.34,1.13a35,35,0,0,1,1.21,6.24c.07.6.11,1.21.14,1.82s0,1.22,0,1.84Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M754.57,529.43v-.2c0-19.66,14.72-35.76,35.67-35.76,12.31,0,19.92,4.14,26.39,10.13a1,1,0,0,1,.07,1.33l-6.56,7.56a1,1,0,0,1-1.36.07c-5.33-4.77-11.08-7.93-18.64-7.93-13.24,0-22.82,10.87-22.82,24.4v.2c0,13.53,9.58,24.6,22.82,24.6,8.17,0,13.51-3.2,19.17-8.36a1,1,0,0,1,1.33,0l6.5,6.58a1,1,0,0,1,0,1.35c-7,7.12-14.85,11.57-27.4,11.57C769.59,565,754.57,549.29,754.57,529.43Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M907.19,494.65h10.24a1,1,0,0,1,1,1v56.31a.74.74,0,0,0,.82.82h34.58a1,1,0,0,1,1,1v9.15a1,1,0,0,1-1,1H907.19a1,1,0,0,1-1-1V495.61A1,1,0,0,1,907.19,494.65Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M965.88,494.65h10.23a1,1,0,0,1,1,1v56.31a.74.74,0,0,0,.82.82h34.58a1,1,0,0,1,1,1v9.15a1,1,0,0,1-1,1H965.88a1,1,0,0,1-1-1V495.61A1,1,0,0,1,965.88,494.65Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M1178,534.47V495.61a1,1,0,0,1,1-1h10.23a1,1,0,0,1,1,1V534c0,12.85,6.62,19.76,17.49,19.76s17.39-6.52,17.39-19.26V495.61a1,1,0,0,1,1-1h10.22a1,1,0,0,1,1,1v38.26c0,20.65-11.66,31-29.74,31S1178,554.52,1178,534.47Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M1271.62,536.3l-26.33-40.16a1,1,0,0,1,.8-1.49h12a1,1,0,0,1,.81.45l18.27,28.9a1,1,0,0,0,1.63,0l18.46-28.9a1,1,0,0,1,.81-.45h11.61a1,1,0,0,1,.81,1.49L1284.19,536a.92.92,0,0,0-.16.53v26.32a1,1,0,0,1-1,1h-10.33a1,1,0,0,1-1-1v-26A1,1,0,0,0,1271.62,536.3Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M859.27,493.47A35.76,35.76,0,1,0,895,529.23,35.76,35.76,0,0,0,859.27,493.47Zm0,59.4a23.64,23.64,0,1,1,23.64-23.64A23.63,23.63,0,0,1,859.27,552.87Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M1052.35,493.47a35.76,35.76,0,1,0,35.76,35.76A35.77,35.77,0,0,0,1052.35,493.47Zm0,59.4A23.64,23.64,0,1,1,1076,529.23,23.64,23.64,0,0,1,1052.35,552.87Z"
      transform="translate(-608.73 -475.21)"
    />
    <path
      style={{ fill: reverse ? "#fff" : "var(--chakra-colors-primary-base)" }}
      d="M1167.31,563.68c-5.14-3.65-5.81-10.33-5.55-14.94a35.69,35.69,0,1,0-12.8,11.87c10.61,5.92,17.44,4.17,18.07,4A.54.54,0,0,0,1167.31,563.68Zm-59.17-34.45a23.65,23.65,0,1,1,23.64,23.64A23.63,23.63,0,0,1,1108.14,529.23Z"
      transform="translate(-608.73 -475.21)"
    />
  </Box>
);
