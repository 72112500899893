import { useNotifications } from ".";
import { useProfile } from "..";
import {
  useDeleteSaveContributionMutation,
  usePostSaveContributionMutation,
} from "../_Service";

export const useSavedContributions = () => {
  const profile = useProfile();
  const data = profile?.data?.savedContributions;
  const [postSaveContribution, postSaveStatus] =
    usePostSaveContributionMutation();
  const [deleteSaveContribution, deleteSaveStatus] =
    useDeleteSaveContributionMutation();
  const { CONTRIBUTION_SAVED, CONTRIBUTION_UNSAVED, GENERIC_ERROR, addToast } =
    useNotifications();

  const isLoading = postSaveStatus.isLoading || deleteSaveStatus.isLoading;
  const error = profile.error;

  const saveContribution = async (contribution) => {
    addToast({
      action: CONTRIBUTION_SAVED,
      payload: contribution,
    });
    try {
      await postSaveContribution(contribution).unwrap();
    } catch (error) {
      addToast({
        action: GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const deleteContribution = async (contribution) => {
    addToast({
      action: CONTRIBUTION_UNSAVED,
      payload: contribution,
    });
    try {
      await deleteSaveContribution(contribution).unwrap();
    } catch (error) {
      addToast({
        action: GENERIC_ERROR,
        payload: error,
      });
    }
  };

  const contributionIsSaved = (contribution) => {
    return data?.filter(({ id }) => id === contribution?.id)[0] ? true : false;
  };
  return {
    saveContribution,
    data,
    error,
    contributionIsSaved,
    deleteContribution,
    isLoading,
  };
};
