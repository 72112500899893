import { swatchGenerator } from "../_Utils";

export const colors = {
  primary: swatchGenerator(
    process.env.REACT_APP_SWATCH_PRIMARY || "#2f3e93",
    40
  ),
  accentone: swatchGenerator(
    process.env.REACT_APP_SWATCH_ACCENTONE || "#f298b9",
    40
  ),
  accenttwo: swatchGenerator(
    process.env.REACT_APP_SWATCH_ACCENTTWO || "#2f8093",
    40
  ),
  themegray: {
    100: "#f9f9fb",
    200: "#f1f2f6",
    300: "#eaebf1",
    400: "#e3e4ed",
    500: "#dbdde8",
    600: "#d4d6e3",
    700: "#cccfde",
    800: "#c5c8d9",
    900: "#bdc1d4",
    1000: "#b6bad0",
    1100: "#afb3cb",
    1200: "#a7acc6",
    1300: "#a0a5c1",
    1400: "#989ebc",
    1500: "#9197b8",
    1600: "#8990b3",
    1700: "#8289ae",
    1800: "#7b82a9",
    1900: "#737ba4",
    2000: "#6c749f",
    2100: "#646d9b",
    2200: "#606793",
    2300: "#5b628c",
    2400: "#565d84",
    2500: "#51587d",
    2600: "#4c5376",
    2700: "#474d6e",
    2800: "#434867",
    2900: "#3e435f",
    3000: "#393e58",
    3100: "#343950",
    3200: "#2f3349",
    3300: "#2b2e42",
    3400: "#26293a",
    3500: "#212433",
    3600: "#1c1e2b",
    3700: "#171924",
    3800: "#12141c",
    3900: "#0e0f15",
    4000: "#090a0e",
    base: "#30344a",
  },
};
