import { Avatar as AvatarChackra } from "@chakra-ui/react";

export const Avatar = ({ fontSize, ...props }) => (
  <AvatarChackra
    bg="primary.300"
    color="primary.2500"
    fontWeight="heading.bold"
    sx={{ ".chakra-avatar__initials": { fontSize } }}
    {...props}
  />
);
