import {
  IllustrationClap,
  IllustrationHandShake,
  IllustrationHeart,
  IllustrationLightBulb,
  IllustrationThumbsUp,
  IllustrationThumbsUpBlank,
} from "../../../Illustrations";

export const HELPFUL_OPTIONS = [
  { id: 0, Icon: IllustrationThumbsUpBlank },
  { id: 1, title: "Helpful", Icon: IllustrationThumbsUp },
  { id: 2, title: "Thoughtful", Icon: IllustrationLightBulb },
  { id: 3, title: "Inspiring", Icon: IllustrationHeart },
  { id: 4, title: "Encouraging", Icon: IllustrationClap },
  { id: 5, title: "Relatable", Icon: IllustrationHandShake },
];
