import { BsCameraVideoFill } from "react-icons/bs";
import { MediaSourceDropdown } from "../../../ui/2_Molecules/MediaSourceDropdown/MediaSourceDropdown";

export const CameraSelect = ({
  videoSources,
  videoDeviceId,
  setChosenVideoSourceId,
}) => (
  <MediaSourceDropdown
    options={videoSources?.map((source) => ({
      label: source.label,
      value: source.deviceId,
    }))}
    onChange={(v) => setChosenVideoSourceId(v)}
    value={videoDeviceId}
    iconButton={<BsCameraVideoFill />}
  />
);
