import { memo, useEffect, useRef } from "react";
import { AspectRatio } from "@chakra-ui/react";
import videojs from "video.js";

import { VideoPlayerWrapper } from "./ui";

export const VideoPlayer = memo(({
  preload = "none",
  sources,
  poster,
  ratio = 4 / 3,
  overlayColor,
}) => {
  const videoRef = useRef();
  const playerRef = useRef();

  useEffect(() => {
    const options = {
      autoplay: false,
      controls: true,
      preload,
      poster,
      sources,
    };
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      playerRef.current = videojs(videoElement, options, () => {});
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      // player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [videoRef, sources, poster]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <VideoPlayerWrapper {...{ overlayColor }}>
      <AspectRatio {...{ ratio }}>
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-theme-fantasy" />
        </div>
      </AspectRatio>
    </VideoPlayerWrapper>
  );
});
