import { DictaphoneAudioWave } from "./components";
import {
  DictaphoneButtonRecord,
  DictaphoneFooter,
  DictaphonePreviewBar,
  DictaphonePreviewWrapper,
  DictaphoneWrapper,
  DictaphoneMicSelect,
} from "./ui";

export const Dictaphone = ({
  isRecording,
  handleStartRecordingClick,
  handleStopRecordingClick,
  micOptions,
  onMicSelectChange,
  micValue,
  audioStream,
}) => {
  if (micOptions)
    return (
      <DictaphoneWrapper>
        <DictaphonePreviewWrapper>
          <DictaphoneAudioWave {...{ audioStream, micValue }} />
        </DictaphonePreviewWrapper>
        <DictaphonePreviewBar {...{ isRecording }} />
        <DictaphoneMicSelect {...{ micOptions, onMicSelectChange, micValue }} />
        <DictaphoneFooter>
          <DictaphoneButtonRecord
            {...{
              isRecording,
              handleStartRecordingClick,
              handleStopRecordingClick,
            }}
          />
        </DictaphoneFooter>
      </DictaphoneWrapper>
    );
  return null;
};
