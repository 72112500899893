import { Heading, Stack, Text } from "@chakra-ui/react";
import { IllustrationEmptyStateCalls } from "../../1_Atoms";

export const EmptyStateCallsVerifiedProfile = () => (
  <>
    <IllustrationEmptyStateCalls
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        You are an ambassador
      </Heading>
      <Text fontSize={{ base: "0.9rem", md: "1rem" }}>
        Contact us to make some calls
      </Text>
    </Stack>
  </>
);
