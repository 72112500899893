import { Box, Progress, Stack } from "@chakra-ui/react";
import { LoaderSection, PageTitle } from "../../../../2_Molecules";
import { ListClientInfiniteScroll } from "../../../../3_Organisms";
import { CardCall, CardContribution } from "../../../../Cards";
import { useTimeline } from "../../../../_Hooks";

export const PageTimelineMainSection = () => {
  const timeline = useTimeline();
  return (
    <LoaderSection loading={timeline.isLoading}>
      <PageTitle title="Activity" />
      <Box pos="relative">
        {timeline.isFetching && (
          <Progress
            size="sm"
            pos="absolute"
            top="0"
            w="full"
            isIndeterminate
            sx={{
              div: {
                backgroundImage:
                  "linear-gradient( to right, transparent 0%, var(--chakra-colors-accentone-base) 50%, transparent 100% )",
              },
            }}
          />
        )}
      </Box>
      <ListClientInfiniteScroll count={6} fullList={timeline?.list}>
        {({ list }) => (
          <Stack
            bg="themegray.200"
            p="1.5rem"
            px="2rem"
            d="flex"
            flexGrow="1"
            flexDir="column"
            alignItems="center"
            spacing={6}
          >
            <ListMixedCards {...{ list }} />
          </Stack>
        )}
      </ListClientInfiniteScroll>
    </LoaderSection>
  );
};

const CardTypes = {
  call: CardCall,
  contribution: (props) => <CardContribution variant='nested' {...props} />,
  default: () => <div>Not correct card type</div>,
};

const ListMixedCards = ({ list }) => {
  if (!list?.length) return null;
  return list?.map((item) => {
    const Card = CardTypes[item.type] || CardTypes.default;
    return <Card key={`${item.type}${item.id}`} data={item} {...item} />;
  });
};
