import React from "react";
import { useOutletContext } from "react-router-dom";
import { ListCardContributions } from "../../";
import { EmptyStateContributionsAuthor } from "../../2_Molecules";
import {
  ListClientInfiniteScroll,
  ListWithEmptyStatePrimary,
} from "../../3_Organisms";

export const PagePersonContributions = () => {
  const { author } = useOutletContext();

  return (
    <ListWithEmptyStatePrimary
      data={author?.data?.contributions}
      isLoading={author?.isLoading}
      EmptyState={() => <EmptyStateContributionsAuthor author={author?.data} />}
    >
      <ListClientInfiniteScroll
        count={6}
        fullList={author?.data?.contributions}
      >
        {({ list }) => <ListCardContributions data={list} />}
      </ListClientInfiniteScroll>
    </ListWithEmptyStatePrimary>
    // <ListWithEmptyStatePrimary
    //   data={data?.contributions}
    //   isLoading={isLoading}
    //   EmptyState={() => <EmptyStateContributionsAuthor author={data} />}
    // >
    //   <ListCardContributions data={data?.contributions} />
    // </ListWithEmptyStatePrimary>
  );
};
