import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { ButtonTab } from "../../../../1_Atoms";
import { LoaderSection, PageTitle } from "../../../../2_Molecules";
import { useProfile } from "../../../../_Hooks";
import { PageProfileHero } from "./components";

export const PageProfileMainSection = () => {
  const profile = useProfile();
  return (
    <LoaderSection loading={profile.isFetching} error={profile.error}>
      <PageTitle title="My Profile" />
      <PageProfileHero profile={profile.data} />
      <Main {...{ profile }} />
    </LoaderSection>
  );
};

const Main = ({ profile }) => (
  <>
    <Flex>
      <ButtonTab to={profile?.data?.to}>
        Contributions ({profile.data?.contributionCount})
      </ButtonTab>
      <ButtonTab to="calls">Calls ({profile.data?.callCount})</ButtonTab>
    </Flex>
    <Outlet context={{ profile }} />
  </>
);
