import {
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Box,
  Button,
} from "@chakra-ui/react";

import { ButtonHelpfulOptionsWrapper } from "./ui";
import { HELPFUL_OPTIONS } from "./ui/constants";
import { ButtonHelpfulTrigger } from "./components";
import { memo } from "react";

export const ButtonHelpful = memo(({ helpfulType, handleHelpfulClick, ...props }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Popover isOpen={isOpen} placement="top-end">
      <PopoverTrigger>
        <ButtonHelpfulTrigger onClick={onToggle} {...{ helpfulType }} {...props} />
      </PopoverTrigger>
      <PopoverContent
        borderRadius="full"
        w="auto"
        _focus={{}}
        boxShadow="none"
        border="solid 1px"
        borderColor="themegray.500"
      >
        <ButtonHelpfulOptionsWrapper {...{ isOpen }}>
          {HELPFUL_OPTIONS.filter((item) => item.title).map((option) => (
            <Box key={option.id}>
              <Button
                borderRadius="full"
                boxSize="50px"
                p="0"
                onClick={() => {
                  onClose();
                  handleHelpfulClick({ id: option.id, title: option.title });
                }}
              >
                {/* {option.title} */}
                <Icon fontSize="2.5rem" as={option.Icon} />
              </Button>
            </Box>
          ))}
        </ButtonHelpfulOptionsWrapper>
      </PopoverContent>
    </Popover>
  );
});
