import { useCallback, useEffect, useState } from "react";

const getBatch = (list, page, perPage) => {
  const arrPage = page - 1;
  return list.slice(arrPage * perPage, arrPage * perPage + perPage);
};

export const ListClientInfiniteScroll = ({
  count = 10,
  fullList,
  children,
}) => {
  const pages = Math.ceil(fullList?.length / count);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setCurrentList] = useState();

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
    setCurrentList((prev) => [
      ...prev,
      ...getBatch(fullList, currentPage + 1, count),
    ]);
  };

  const handleScroll = useCallback(() => {
    const fullHeight = document.body.scrollHeight;
    const scroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const visibleHeight = window.innerHeight;
    console.table({ fullHeight, scroll, visibleHeight });
    if (scroll + visibleHeight > fullHeight - 500) {
      nextPage();
    }
  }, [currentPage, fullList]);

  useEffect(() => {
    if (fullList) setCurrentList(getBatch(fullList, 1, currentPage * count));
  }, [fullList, count]);

  useEffect(() => {
    if (fullList && currentPage < pages) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fullList, handleScroll]);

  useEffect(() => {
    if (currentPage >= pages) {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [currentPage]);

  const renderProps = { pages, currentPage, list };
  if (!children) return null;
  return typeof children === "function" ? children(renderProps) : children;
};
