import {
  FormSignInPasswordHelper,
  FormSignInPasswordInput,
  FormSignInPasswordWrapper,
} from "./ui";

export const FormSignInPassword = ({ register, errors }) => (
  <FormSignInPasswordWrapper>
    <FormSignInPasswordInput {...{ register, errors }} />
    <FormSignInPasswordHelper />
  </FormSignInPasswordWrapper>
);
