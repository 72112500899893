import { AspectRatio, Box, Image } from "@chakra-ui/react";

export const CardThumbnail = ({
  src,
  alt,
  bg = "accentone.base",
  ratio = 4 / 3,
  isCompany,
  ...props
}) => (
  <AspectRatio
    border={isCompany && "solid 1px"}
    borderColor={isCompany && "themegray.400"}
    borderRadius="md"
    overflow="hidden"
    isolation="isolate"
    flexShrink={0}
    role="group"
    {...{ ratio }}
    {...props}
  >
    <>
      <Image
        _groupHover={{ transform: "scale(1.05)" }}
        _hover={{ transform: "scale(1.05)" }}
        transition="all 0.2s ease"
        {...{ src, alt }}
      />
      {!isCompany && (
        <Box pos="absolute" boxSize="100%" opacity="0.3" {...{ bg }} />
      )}
    </>
  </AspectRatio>
);
