import { Stack, Text, Textarea } from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { modalClose } from "../../_Redux/modalsSlice";
import {
  useMakeTextContribution,
  ButtonAccentTwo,
  ModalContribution,
  CardPrimary,
  CardBody,
} from "../../";

export const ModalTextContribution = () => {
  const modal = useSelector((state) => state.modals).modalTextContribution;
  const dispatch = useDispatch();

  const close = () => {
    dispatch(modalClose({ modal: "modalTextContribution" }));
  };

  return (
    <ModalContribution
      title={modal?.call?.title}
      isOpen={modal.isOpen}
      onClose={() => close()}
    >
      <TextContribution call={modal?.call} onComplete={() => close()} />
    </ModalContribution>
  );
};

const TextContribution = ({ call, onComplete }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const text = watch("text");
  const makeContribution = useMakeTextContribution();

  useEffect(() => {
    if (makeContribution) {
      if (makeContribution?.isSuccess) {
        onComplete();
      }
    }
  }, [makeContribution?.isSuccess]);

  return (
    <CardPrimary w="100%" maxW="500px" flexGrow="1" h="100%" maxH="510px">
      <CardBody d="flex" flexGrow="1">
        <form
          style={{ width: "100%", display: "flex" }}
          onSubmit={handleSubmit((data) =>
            makeContribution.createTextContribution(data.text, call)
          )}
        >
          <Stack flexGrow="1" alignItems="center" spacing="1rem">
            <Textarea
              placeholder="Contribute here..."
              bg="themegray.200"
              h="100%"
              p="1.5rem"
              {...register("text", {
                required: "Message is required",
              })}
            />
            <Text
              fontSize="0.75rem"
              textTransform="uppercase"
              fontWeight="body.black"
              color="red.500"
            >
              {errors.text && errors.text.message}
            </Text>
            <Text
              fontSize="0.75rem"
              textTransform="uppercase"
              fontWeight="body.black"
              color={text?.length > 650 && "red.500"}
            >
              {text?.length || 0} / 650 characters
            </Text>
            <ButtonAccentTwo flexShrink="0" type="submit" minW="200px">
              {makeContribution.isLoading ? "Posting..." : "Submit"}
            </ButtonAccentTwo>
          </Stack>
        </form>
      </CardBody>
    </CardPrimary>
  );
};
