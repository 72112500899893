import { useEffect, useState } from "react";
import { useGetKeyValuesQuery, useGetPartnerQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const usePartner = () => {
  const keyValuePairCategoryId = 5;
  const termsKeyValuePairCategoryId = 6;
  const [footerText, setFooterText] = useState();
  const [termsText, setTermsText] = useState();

  const partner = useBaseApi({
    query: useGetPartnerQuery,
  });

  const copy = useBaseApi({
    query: useGetKeyValuesQuery,
    params: [keyValuePairCategoryId],
  });

  const terms = useBaseApi({
    query: useGetKeyValuesQuery,
    params: [termsKeyValuePairCategoryId],
  });

  useEffect(() => {
    if (copy.data) {
      const footer = copy.data.filter((item) => item.key === "Footer Text")[0];
      setFooterText(footer?.value);
    }
  }, [copy.data]);

  useEffect(() => {
    if (terms.data) {
      const termsinner = terms.data.filter((item) => item.key === "Data terms")[0];
      setTermsText(termsinner?.value);
    }
  }, [terms.data]);

  return { ...partner, footerText, termsText };
};
