import { Box } from "@chakra-ui/react";

export const HeaderWrapper = ({ children }) => (
  <Box
    as="header"
    bg="white"
    pos="fixed"
    w="100vw"
    zIndex="1000"
    borderBottom="solid 1px"
    borderBottomColor="gray.200"
  >
    {children}
  </Box>
);
