import { Icon, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import { HELPFUL_OPTIONS } from "../../ui/constants";
import { ButtonHelpfulTriggerWrapper, ButtonHelpfulTriggerMotion } from "./ui";

export const ButtonHelpfulTrigger = forwardRef(
  ({ helpfulType, ...props }, ref) => (
    <ButtonHelpfulTriggerWrapper {...{ ref }} {...props}>
      <ButtonHelpfulTriggerMotion {...{ helpfulType }}>
        <Icon fontSize="3xl" as={HELPFUL_OPTIONS[helpfulType?.id || 0].Icon} />
      </ButtonHelpfulTriggerMotion>
      <Text
        fontSize="0.75rem"
        textTransform="uppercase"
        fontWeight="body.black"
        ml={2}
      >
        {helpfulType?.title || 'Like'}
      </Text>
    </ButtonHelpfulTriggerWrapper>
  )
);
