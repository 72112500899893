import { Box } from "@chakra-ui/react";
import { ButtonHelpful, ButtonSavePost } from "../../../../../1_Atoms";

export const CardContributionFooter = ({
  saveIsLoading,
  isSaved,
  handleSavedClick,
  handleHelpfulClick,
  helpfulType,
}) => (
  <Box d="flex" gap={4} p={4}>
    <Box flexGrow={1} flexBasis="50%">
      <ButtonSavePost
        w="full"
        isLoading={saveIsLoading}
        isSaved={isSaved}
        onClick={handleSavedClick}
      />
    </Box>
    <Box flexGrow={1} flexBasis="50%">
      <ButtonHelpful w="full" {...{ handleHelpfulClick, helpfulType }} />
    </Box>
  </Box>
);
