import { Icon } from "@chakra-ui/react";
import { FaVideo } from "react-icons/fa";
import { ButtonContribute } from "../components";

export const ContributeButtonBarButtonVideo = ({
  handleVideoContributionClick,
}) => (
  <ButtonContribute
    flexGrow={1}
    onClick={handleVideoContributionClick}
    leftIcon={<Icon fontSize='xl' as={FaVideo} />}
  >
    Video
  </ButtonContribute>
);
