import { useParams } from "react-router-dom";
import { useGetCallQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const useCall = () => {
  const { callId } = useParams();
  const call = useBaseApi({
    query: useGetCallQuery,
    params: [callId, { refetchOnMountOrArgChange: 5 }],
  });
  return call;
};
