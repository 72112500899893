import { Box } from "@chakra-ui/react";

export const IllustrationEmptyStateFollowing = (props) => (
  <Box as="svg" viewBox="0 0 100 100" fill="none" {...props}>
    <path
      d="M81.6021 79.8992L56.8248 55.4159L51.9186 60.3811L76.6958 84.8644L81.6021 79.8992Z"
      fill="var(--chakra-colors-primary-1500)"
    />
    <path
      d="M91.0671 86.4778L71.7903 67.4298C71.683 67.3237 71.51 67.3247 71.4039 67.4321L64.1078 74.8158C64.0017 74.9231 64.0028 75.0961 64.1101 75.2022L83.3869 94.2502C83.4942 94.3563 83.6672 94.3553 83.7733 94.2479L91.0694 86.8642C91.1755 86.7569 91.1744 86.5839 91.0671 86.4778Z"
      fill="var(--chakra-colors-primary-2200)"
    />
    <path
      d="M34.8646 67.7292C51.9106 67.7292 65.7291 53.9106 65.7291 36.8646C65.7291 19.8185 51.9106 6 34.8646 6C17.8185 6 4 19.8185 4 36.8646C4 53.9106 17.8185 67.7292 34.8646 67.7292Z"
      fill="var(--chakra-colors-primary-2200)"
    />
    <path
      d="M34.8647 62.6931C49.1293 62.6931 60.6931 51.1293 60.6931 36.8647C60.6931 22.6 49.1293 11.0363 34.8647 11.0363C20.6 11.0363 9.03625 22.6 9.03625 36.8647C9.03625 51.1293 20.6 62.6931 34.8647 62.6931Z"
      fill="var(--chakra-colors-primary-400)"
    />
    <path
      d="M87.0448 8.96283C79.8051 6.61615 71.9811 10.5851 69.6249 17.8546C67.2783 25.0944 71.2471 32.9183 78.5168 35.2745C85.7864 37.6306 93.5899 33.6228 95.9371 26.3827C98.2842 19.1426 94.2849 11.3094 87.0448 8.96283ZM84.8603 15.7026C85.6707 15.9653 86.3848 16.4627 86.912 17.1318C87.4393 17.8009 87.756 18.6116 87.8219 19.4609C87.8878 20.3102 87.6999 21.16 87.2822 21.9024C86.8645 22.6448 86.2357 23.2464 85.4755 23.631C84.7154 24.0155 83.8582 24.1657 83.0126 24.0623C82.167 23.959 81.3712 23.6069 80.726 23.0506C80.0808 22.4943 79.6154 21.7589 79.3887 20.9378C79.1621 20.1166 79.1844 19.2466 79.4529 18.4382C80.1779 16.2014 82.6232 14.9777 84.8603 15.7026ZM79.3944 32.5668C76.9891 31.7912 74.9283 30.2034 73.5648 28.0755C76.1162 26.6258 78.7838 26.222 81.1973 27.0043C83.6107 27.7866 85.5732 29.6584 86.7501 32.3491C84.3977 33.2726 81.7973 33.3496 79.3944 32.5668Z"
      fill="var(--chakra-colors-primary-2000)"
    />
    <path
      d="M26.786 74.9356C25.5285 75.5775 24.4896 76.5779 23.8006 77.8102C23.1116 79.0426 22.8036 80.4515 22.9154 81.859C23.0272 83.2664 23.5538 84.6091 24.4287 85.7172C25.3035 86.8253 26.4874 87.6491 27.8304 88.0844C29.1735 88.5197 30.6155 88.547 31.9741 88.1628C33.3327 87.7786 34.5468 87.0001 35.4629 85.9258C36.3791 84.8516 36.9561 83.5298 37.121 82.1276C37.286 80.7254 37.0314 79.3058 36.3895 78.0483C35.5272 76.3635 34.0318 75.0895 32.2314 74.506C30.431 73.9224 28.4727 74.077 26.786 74.9356ZM28.4487 78.1929C28.8404 77.9929 29.2826 77.9138 29.7193 77.9656C30.156 78.0173 30.5674 78.1976 30.9015 78.4836C31.2356 78.7695 31.4772 79.1482 31.5957 79.5717C31.7142 79.9952 31.7043 80.4443 31.5672 80.8621C31.4301 81.28 31.1719 81.6476 30.8256 81.9185C30.4792 82.1895 30.0601 82.3514 29.6216 82.3838C29.183 82.4162 28.7447 82.3176 28.3623 82.1005C27.9799 81.8834 27.6705 81.5577 27.4735 81.1646C27.2124 80.6405 27.1683 80.0346 27.3508 79.4783C27.5334 78.922 27.9279 78.4601 28.4487 78.1926V78.1929ZM32.6089 86.3433C31.4479 86.9381 30.1162 87.1108 28.8419 86.8317C29.244 85.3713 30.0703 84.2503 31.237 83.6549C32.4036 83.0595 33.8028 83.0266 35.2145 83.579C34.6928 84.7746 33.7717 85.7517 32.6089 86.3429V86.3433Z"
      fill="var(--chakra-colors-primary-2000)"
    />
  </Box>
);
