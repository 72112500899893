import {  LoaderSpinner, NotificationError } from "../../../1_Atoms";
import {
  LoadingPrimaryIsLoadingWrapper,
  LoadingPrimaryErrorWrapper,
} from "./ui";

export const LoadingPrimary = ({ isLoading, error, children }) => {
  if (isLoading)
    return (
      <LoadingPrimaryIsLoadingWrapper>
        <LoaderSpinner />
      </LoadingPrimaryIsLoadingWrapper>
    );
  if (error)
    return (
      <LoadingPrimaryErrorWrapper>
        <NotificationError {...{ error }} />
      </LoadingPrimaryErrorWrapper>
    );
  if (children) return children;
  return null;
};
