import { Box, Flex, Stack } from "@chakra-ui/react";
import { useMediaSources } from "../../_Hooks";
import { motion } from "framer-motion";
import { VideoPreviewBar } from "../../1_Atoms";
import { CameraSelect, MicSelect } from "../../2_Molecules";
import { ButtonRecord } from "../../../ui";

export const VideoControls = ({ mediaRecorder, ...props }) => {
  const mediaSources = useMediaSources();

  const RecordClicked = () => {
    mediaRecorder.capturing
      ? mediaRecorder.stopCapture()
      : mediaRecorder.startCapture();
  };
  return (
    <Box
      padding="1rem"
      pos="absolute"
      color="white"
      zIndex="10"
      pointerEvents="none"
      h="100%"
      w="100%"
      {...props}
    >
      <motion.div
        style={{ height: "100%" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.5 } }}
      >
        <Box pointerEvents="all" pos="relative" h="100%">
          <Box pos="absolute" w="100%">
            <Flex justifyContent="space-between" alignItems="flex-start">
              <VideoPreviewBar
                recording={mediaRecorder.capturing}
                maxReached={() => mediaRecorder.stopCapture()}
                max={110}
              />
              {!mediaRecorder.capturing && (
                <Stack>
                  <CameraSelect {...mediaSources} />
                  <MicSelect {...mediaSources} />
                </Stack>
              )}
            </Flex>
          </Box>
          <Box
            pos="absolute"
            w="100%"
            bottom="10px"
            d="flex"
            justifyContent="center"
          >
            <ButtonRecord
              isRecording={mediaRecorder.capturing}
              onClick={RecordClicked}
            />
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};
