import { Box } from "@chakra-ui/react";

export const AudioPlayerThumbnailWrapper = ({ children }) => (
  <Box
    userSelect="none"
    bg="accenttwo.2600"
    boxSize="100%"
    pos="absolute"
    zIndex="1"
    d="flex"
    alignItems="center"
    justifyContent="center"
    {...{ children }}
  />
);
