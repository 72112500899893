import { Modal, ModalBody, ModalContent } from "@chakra-ui/react";
import { ModalOverlay } from "../../";

export const ModalPrimary = ({ isOpen, onClose, children }) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent mx="1rem">
      <ModalBody d="flex" flexDir="column" justifyContent="center" px="2rem" py='3rem'>
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
);
