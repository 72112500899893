import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { FaCheckCircle, FaSortAmountDown } from "react-icons/fa";

export const SortOptions = ({ sortOptions, onSortChange }) => (
  <Menu>
    <MenuButton
      bg="white"
      size="sm"
      border="solid 1px"
      borderColor="themegray.400"
      as={Button}
      leftIcon={<FaSortAmountDown fontSize="1rem" />}
    >
      Sort
    </MenuButton>
    <MenuList>
      <MenuOptionGroup value={sortOptions.value} type="radio">
        {sortOptions.options?.map((item) => (
          <MenuItemOption
            key={item.value}
            icon={<FaCheckCircle fontSize="1.3rem" />}
            onClick={() => onSortChange({ value: item.value, name: item.name })}
            value={item.value}
          >
            {item.name}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </MenuList>
  </Menu>
);
