import { PageSignInWrapper } from "./ui";
import { PageSignInHeader } from "./components";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

export const PageSignIn = () => {
  return (
    <PageSignInWrapper>
      <PageSignInHeader />
      <Box
        w="full"
        alignItems="center"
        justifyContent="center"
        d='flex'
        flex={1}
      >
        <Outlet />
      </Box>
    </PageSignInWrapper>
  );
};
