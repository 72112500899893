import { Icon } from "@chakra-ui/react";
import { FaComment } from "react-icons/fa";
import { ButtonContribute } from "../components";

export const ContributeButtonBarButtonText = ({
  handleTextContributionClick,
}) => (
  <ButtonContribute
    flexGrow={1}
    onClick={handleTextContributionClick}
    leftIcon={<Icon fontSize='xl' as={FaComment} />}
  >
    Text
  </ButtonContribute>
);
