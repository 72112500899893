import { useDispatch } from "react-redux";
import { CardSelector } from "../../../ui";
import { useVerifiedHandle } from "../../_Hooks";
import { modalOpen } from "../../_Redux/modalsSlice";

export const CardCall = ({ data, variant }) => {
  const dispatch = useDispatch();
  const { handleVerifiedClicked } = useVerifiedHandle();
  const handleVideoContributionClick = () =>
    dispatch(modalOpen({ modal: "modalVideoContribution", data }));

  const handleAudioContributionClick = () =>
    dispatch(modalOpen({ modal: "modalAudioContribution", data }));

  const handleTextContributionClick = () =>
    dispatch(modalOpen({ modal: "modalTextContribution", data }));

  return (
    <CardSelector
      type="call"
      handleVerifiedClicked={() => {
        handleVerifiedClicked(data?.author?.verified);
      }}
      {...{
        variant,
        handleVideoContributionClick,
        handleAudioContributionClick,
        handleTextContributionClick,
      }}
      {...data}
    />
  );
};
