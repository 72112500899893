import { Image } from "@chakra-ui/react";

export const FooterPartnerLogo = ({ invertedLogoURL, title }) => (
  <Image
    w={{ base: "200px", sm: "250px" }}
    maxW="full"
    mx="auto"
    mb={2}
    src={invertedLogoURL}
    alt={title}
  />
);
