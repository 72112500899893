import { ListSortAndFilterWrapper } from "./ui";
import { FilterDropdowns, SortOptions } from "./components";
import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

export const ListSortAndFilter = ({
  filterDropdowns,
  sortOptions,
  onChange,
  onSortChange,
  onSearchChange = () => {},
  search,
}) => (
  <ListSortAndFilterWrapper>
    <Box d="flex" justifyContent="space-between" gap={4}>
      <Box w="full" maxW="200px">
        <InputGroup size="sm">
          <InputLeftElement children={<FaSearch color="gray.300" />} />
          <Input
            bg="white"
            color="gray.600"
            borderRadius="md"
            value={search}
            placeholder='Search title...'
            onChange={(e) =>
              onSearchChange({ name: "search", value: e.target.value })
            }
          />
        </InputGroup>
      </Box>
      <Box d="flex" gap={2}>
        {filterDropdowns && (
          <FilterDropdowns {...{ filterDropdowns, onChange }} />
        )}
        {sortOptions && <SortOptions {...{ sortOptions, onSortChange }} />}
      </Box>
    </Box>
  </ListSortAndFilterWrapper>
);
