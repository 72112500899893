import { motion } from "framer-motion";
import { Heading } from "@chakra-ui/react";
import { introchild } from "./framerVariants";

export const AudioContributionIntroTitle = () => (
  <motion.div variants={introchild}>
    <Heading as="h2" fontSize="1.1rem" textAlign="center">
      How it works
    </Heading>
  </motion.div>
);
