import { LoaderSection, PageTitle } from "../../../../2_Molecules";
import { useCall } from "../../../../_Hooks";
import { PageCallHero, PageCallContributionList } from "./components";

export const PageCallMainSection = () => {
  const { isFetching, error } = useCall();
  return (
    <LoaderSection loading={isFetching} {...{ error }}>
      <PageTitle goBack={true} to="/calls" title="Call" />
      <PageCallHero />
      <PageCallContributionList />
    </LoaderSection>
  );
};
