import { Heading, Link, Stack, Text } from "@chakra-ui/react";
import { CardBody, CardCallToAction } from "../../../../1_Atoms";

export const PageContactMedicalHelp = () => (
  <CardCallToAction>
    <CardBody>
      <Stack spacing="1rem">
        <Heading fontSize="1.2rem">Patient support</Heading>
        <Text>
          If you have any questions about your health, any topics that have been
          discussed on this platform, or if you need any additional support or
          guidance with your MDS journey, please feel free to contact Audrey
          Hassan, Patient Liaison, at the MDS Foundation.
        </Text>
        <Link fontWeight="body.bold" href="ahassan@mds-foundation.org">
          ahassan@mds-foundation.org
        </Link>
      </Stack>
    </CardBody>
  </CardCallToAction>
);
