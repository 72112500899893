import { Box, Icon } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FaCheck } from "react-icons/fa";
import {
  MediaSourceDropdownMenu,
  MediaSourceDropdownMenuButton,
  MediaSourceDropdownMenuItem,
  MediaSourceDropdownMenuList,
} from "./ui";

export const MediaSourceDropdown = ({
  options,
  onChange,
  value,
  iconButton,
}) => (
  <MediaSourceDropdownMenu>
    <MediaSourceDropdownMenuButton {...{ iconButton }} />
    <MediaSourceDropdownMenuList>
      {options?.map((option) => (
        <MediaSourceDropdownMenuItem
          key={option.label}
          {...{ option, onChange }}
        >
          <Box mt="5px" w="25px">
            {option.value === value && <Icon as={FaCheck} />}
          </Box>
          <Box>{option.label}</Box>
        </MediaSourceDropdownMenuItem>
      ))}
    </MediaSourceDropdownMenuList>
  </MediaSourceDropdownMenu>
);

MediaSourceDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  iconButton: PropTypes.element.isRequired,
  value: PropTypes.string,
};
