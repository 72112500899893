import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import {
  Input,
  Box,
  InputGroup,
  Icon,
  InputRightElement,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

export const SearchWithDropdown = ({
  search,
  results,
  loading,
  onChange,
  ListItem,
  onItemClicked,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <Box pos="relative" zIndex="100">
      <InputGroup>
        <Input
          px="1.5rem"
          size="md"
          borderRadius="full"
          border="solid 1px"
          borderColor="gray.200"
          placeholder={loading ? "Loading..." : "Find a person"}
          type="text"
          value={search}
          onChange={onChange}
          disabled={loading}
          bg="gray.100"
          _placeholder={{ color: "gray.600" }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <InputRightElement pointerEvents="none" w="3rem" height="100%">
          <Icon as={BiSearch} color="gray.600" fontSize="1.5rem" />
        </InputRightElement>
      </InputGroup>
      <AnimatePresence>
        {focused && (
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Box
              w="100%"
              bg="white"
              pos="absolute"
              mt="1rem"
              boxShadow="md"
              border="solid 1px"
              borderColor="gray.200"
              borderRadius="lg"
              maxH="300px"
              overflow="auto"
            >
              {results?.map((result, i, arr) => (
                <Box
                  key={i}
                  borderBottom={i < arr.length - 1 && "solid 1px"}
                  borderBottomColor="gray.200"
                  onClick={onItemClicked}
                >
                  <ListItem {...result} />
                </Box>
              ))}
              {results?.length === 0 && <Box p="1rem">No results</Box>}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};
