import { Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import { ButtonDefault } from "../../../ui";

export const VerificationExplanation = ({ onClick, verified }) => (
  <>
    <Icon
      fontSize="80px"
      mx="auto"
      mb="1rem"
      as={MdVerified}
      color="accentone.base"
    />
    <Stack spacing="1.5rem">
      <Stack spacing="1rem">
        <Heading fontSize="1.2rem" textAlign="center">
          Colloquy Ambassador
        </Heading>
        <Text>
          Colloquy is led by a team of ambassadors to ensure the patient
          perspective is brought to the forefront of everything we do. The team
          of ambassadors are made up of patients, loved ones, experts and
          nurses, who facilitate exchanges that support you along your journey.
        </Text>
        <Text>Find out how you can become an ambassador.</Text>
      </Stack>

      <ButtonDefault
        size="sm"
        mt="1rem"
        as={Link}
        onClick={onClick}
        to="/ambassadors"
      >
        Apply here
      </ButtonDefault>
    </Stack>
  </>
);
