import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { wrapper } from "./framerVariants";

export const AudioContributionWrapper = ({ children }) => (
  <Box
    w="100%"
    overflow="hidden"
    alignItems="center"
    justifyContent="center"
    flexGrow='1'
    display="flex"
    as={motion.div}
    variants={wrapper}
    initial="hide"
    animate="show"
    exit="exit"
    {...{ children }}
  />
);
