import { Box, Spinner } from "@chakra-ui/react";

export const LoaderSpinner = (props) => (
  <Box d="flex" alignItems="center" justifyContent="center" {...props}>
    <Spinner
      size="xl"
      color="primary.base"
      emptyColor="primary.400"
      thickness="5px"
    />
  </Box>
);
