import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { SignUpStepOne } from "./SignUpStepOne";
import { SignUpStepTwo } from "./SignUpStepTwo";
import { SignUpStepThree } from "./SignUpStepThree";
import { SignUpStepFour } from "./SignUpStepFour";

export const SignUp = ({ onBackToBoardingClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const steps = [
    SignUpStepOne, 
    SignUpStepTwo, 
    SignUpStepThree, 
    SignUpStepFour
  ];

  return (
    <Box w="100%">
      <Text textAlign="center" color="white" mb="1rem">
        Step {currentIndex + 1} of {steps.length}
      </Text>

      <Carousel
        swipeable={false}
        emulateTouch={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        transitionTime={500}
        selectedItem={currentIndex}
        showThumbs={false}
        onChange={(e) => setCurrentIndex(e)}
      >
        {steps.map((Comp, i) => (
          <Box key={i} pos="relative" maxW="800px" w="100%" mx="auto" px="2rem">
            <Comp
              active={currentIndex === i}
              onPrevClicked={() => setCurrentIndex((prev) => prev - 1)}
              onNextClicked={() => setCurrentIndex((prev) => prev + 1)}
              {...{ onBackToBoardingClick }}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};
