import { Spinner } from "@chakra-ui/react";

export const ButtonBaseSpinner = ({
  spinnerColor,
  spinnerEmptyColor,
  size,
}) => {
  const sizeVariants = { sm: "sm", md: "md", lg: "md" };
  return (
    <Spinner
      size={sizeVariants[size]}
      color={spinnerColor}
      emptyColor={spinnerEmptyColor}
      thickness="3px"
    />
  );
};
