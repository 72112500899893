import { Box } from "@chakra-ui/react";

export const PageContactWrapper = ({ children }) => (
  <Box
    borderLeft="solid 1px"
    borderRight={{ xl: "solid 1px" }}
    borderColor={{ base: "gray.200", xl: "gray.200" }}
    d="flex"
    flexGrow="1"
    flexDir="column"
    {...{ children }}
  />
);
