import { ButtonDefault } from "../../../../../../../../../ui";

export const AudioContributionPlaybackButtonRedo = ({
  handleRedoRecordingClick,
  isLoading
}) => (
  <ButtonDefault size="sm" flexBasis="50%" disabled={isLoading} onClick={handleRedoRecordingClick}>
    Redo
  </ButtonDefault>
);
