import { useParams } from "react-router-dom";
import { useGetAuthorQuery } from "../_Service";
import { useBaseApi } from "./useBaseApi";

export const useAuthor = () => {
  const { id } = useParams();
  const { data, ...author } = useBaseApi({
    query: useGetAuthorQuery,
    params: [id, { refetchOnMountOrArgChange: 5 }],
  });
  return { data, ...author };
};
