import { Text } from "@chakra-ui/react";

export const CardContributionCount = ({ contributionCount }) => (
  <Text
    textTransform="uppercase"
    color="gray.500"
    fontWeight="body.black"
    fontSize="sm"
  >
    {contributionCount} contribution{contributionCount !== 1 ? "s" : ""}
  </Text>
);
