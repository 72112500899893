import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";

export const VideoWebcam = forwardRef(({ style }, ref) => {
  const videoConfig = useSelector((state) => state.videoconfig);
  const [videoConstraints, setVideoConstraints] = useState({
    width: 640,
  });
  const [audioConstraints, setAudioConstraints] = useState();
  const [ready, setReady] = useState();

  useEffect(() => {
    if (videoConfig.videoDeviceId) {
      setVideoConstraints((prev) => ({
        ...prev,
        deviceId: videoConfig.videoDeviceId,
      }));
    }
    if (videoConfig.audioDeviceId) {
      setAudioConstraints((prev) => ({
        ...prev,
        deviceId: videoConfig.audioDeviceId,
      }));
    }
  }, [videoConfig.videoDeviceId, videoConfig.audioDeviceId]);

  return (
    <Webcam
      onUserMedia={() => setReady(true)}
      muted="muted"
      audio={true}
      mirrored={videoConfig?.mirrored}
      videoConstraints={videoConstraints}
      audioConstraints={audioConstraints}
      style={{
        display: ready ? "block" : "none",
        position: "absolute",
        borderRadius: "1rem",
        width: "100%",
        height: "100%",
        ...style,
      }}
      ref={ref}
    />
  );
});
