import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Outlet,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  PageTitle,
  useCountryList,
  useProfile,
  useStateList,
  LoaderSection,
  getBothNames,
  getFormattedAddress,
  config,
  useGenderList,
  getAvatarSrc,
  getFormattedDob,
  Avatar,
} from "../..";
import { VscChevronRight } from "react-icons/vsc";
import { useEthnicityList, useGroup } from "../../_Hooks";
import { getRole } from "../../_Utils";

export const PageAccount = () => {
  const navigate = useNavigate();
  const profile = useProfile();
  const { pathname } = useLocation();
  const countries = useCountryList();
  const states = useStateList();
  const genders = useGenderList();
  const ethnicities = useEthnicityList();
  const group = useGroup();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  useEffect(() => {
    if (variant === "desktop" && pathname.split("/").length === 2)
      navigate("name", { replace: true });
  }, [variant, pathname, navigate]);

  return (
    <LoaderSection
      loading={
        ethnicities.isLoading ||
        profile.isLoading ||
        countries.isLoading ||
        states.isLoading ||
        genders.isLoading ||
        group.isLoading
      }
    >
      <Box
        d="flex"
        borderLeft="solid 1px"
        borderRight={{ xl: "solid 1px" }}
        borderColor={{ base: "gray.200", xl: "gray.200" }}
        flexGrow="1"
      >
        <Routes>
          <Route
            path={variant === "desktop" ? "*" : "/"}
            element={
              <NavItems {...{ profile, countries, states, genders, ethnicities, group }} />
            }
          ></Route>
        </Routes>
        <Outlet />
      </Box>
    </LoaderSection>
  );
};

const NavItems = ({ profile, states, countries, genders, ethnicities, group }) => (
  <Box
    w={{ base: "100%", lg: "45%" }}
    borderRight="solid 1px"
    borderColor="gray.200"
  >
    <PageTitle title="My account" />
    <Header>Personal details</Header>
    <Item to="name" title="Name" subtitle={getBothNames(profile.data)} />
    <Item
      to="location"
      title="Location"
      subtitle={getFormattedAddress(
        states.getStateById(profile.data?.stateId),
        countries.getCountryById(profile.data?.countryId)
      )}
    />
    <Item
      to="dob"
      title="Date of birth"
      subtitle={getFormattedDob(profile.data?.dob)}
    />
    <Item to="ethnicity" title="Ethnicity" subtitle={ethnicities.getEthnicityById(profile.data?.ethnicityId)?.title} />
    <Item
      to="gender"
      title="Gender"
      subtitle={genders.getGenderById(profile.data?.genderId)?.title}
    />
    <Item to="avatar" title="Profile picture">
      <Avatar
        src={getAvatarSrc(profile.data?.avatar)}
        name={getBothNames(profile.data)}
      />
    </Item>

    <Hr />

    <Header>Account details </Header>
    <Item
      to="email"
      title="Change email address"
      subtitle={profile.data?.username}
    />
    <Item to="password" title="Change password">
      Min. {config.minimumPasswordLength} characters
    </Item>

    <Hr />

    <Header>{group.data?.title} details</Header>
    <Item
      to="role"
      title="Role"
      subtitle={`I am a ${getRole(profile.data?.role)?.title}`}
    />
  </Box>
);

const Header = (props) => (
  <Heading px="1.5rem" fontSize="1.2rem" mt="1.7rem" mb="0.6rem" {...props} />
);

const Hr = (props) => (
  <Box height="1px" my="2rem" bg="gray.200" mx="1rem" {...props} />
);

const Item = ({ to, title, subtitle, children }) => (
  <Box
    _hover={{ bg: "primary.100" }}
    py="1rem"
    fontSize="0.9rem"
    as={NavLink}
    to={to}
    px="1.5rem"
    d="flex"
    flexDir="column"
    sx={{
      "&.active": {
        bg: "primary.200",
      },
    }}
  >
    <Flex justifyContent="space-between" alignItems="center">
      <div>
        <Text mb="0.2rem" fontWeight="heading.bold">
          {title}
        </Text>
        <Text>{subtitle}</Text>
        {children}
      </div>
      <Icon fontSize="1.4rem" as={VscChevronRight} />
    </Flex>
  </Box>
);
