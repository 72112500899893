import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { modalOpen } from "../_Redux/modalsSlice";

export const useVerifiedHandle = () => {
  const dispatch = useDispatch();

  const handleVerifiedClicked = useCallback((verified) => {
    dispatch(modalOpen({ modal: "modalVerificationExplanation", verified }));
  },[]);

  return { handleVerifiedClicked };
};
