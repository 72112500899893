import { FormControlInputPassword } from "../../../../../../../../ui/2_Molecules/Form/FormControlInput/FormControlInputPassword";
import { validationDictionary } from "../../../../../../../_Utils";

export const FormSignInPasswordInput = ({ register, errors }) => (
  <FormControlInputPassword
    {...register("password", {
      required: validationDictionary.PASSWORD_REQUIRED,
    })}
    error={errors.password?.message}
    isInvalid={errors.password?.message}
    placeholder="Enter your password"
    label="Password"
    reverse
  />
);
