import { Box } from "@chakra-ui/react";

export const VideoPlayerWrapper = ({
  children,
  overlayColor = "accentone.base",
}) => (
  <Box
    sx={{
      ".vjs-theme-fantasy": {
        "--vjs-theme-fantasy--secondary": "#fff",
      },
      ".vjs-play-progress": {
        backgroundColor: overlayColor,
        ":before": {
          backgroundColor: overlayColor,
        },
      },
      ".vjs-poster:after": {
        position: "absolute",
        content: '""',
        boxSize: "full",
        bg: overlayColor,
        opacity: 0.3,
      },
      ".vjs-poster": {
        backgroundSize: "cover",
        filter: "grayscale(40%)",
        transition: "all 0.2s ease",
      },
      ".video-js": {
        _hover: {
          ".vjs-poster": {
            transform: "scale(1.05)",
          },
        },
      },
      ".vjs-big-play-button": {
        display: "flex",
        boxSize: "full",
        pos: "absolute",
        inset: 0,
        margin: 0,
        borderRadius: "none",
        alignItems: "center",
        justifyContent: "center",
        _hover: {
          ".vjs-icon-placeholder": {
            transform: "scale(1.2)",
          },
        },
        ".vjs-icon-placeholder": {
          transition: "all 0.2s ease",
          pos: "relative",
          margin: 0,
          boxSize: "100px",
          borderRadius: "full",
          border: "solid 3px white",
          alignItems: "center",
          justifyContent: "center",
          d: "flex",

          "&:before": {
            m: 0,
            inset: 0,
            position: "relative",
            color: "white",
            boxSize: "auto",
          },
        },
      },
    }}
  >
    {children}
  </Box>
);
