import { useCallback } from "react";
import {
  useDeleteUserHelpfulContributionMutation,
  usePostUserHelpfulContributionMutation,
} from "../_Service";
import { useNotifications } from "./useNotifications";
import { useProfile } from "./useProfile";

export const useHelpfulContributions = () => {
  const {
    addToast,
    GENERIC_ERROR,
    CONTRIBUTION_HELPED,
    CONTRIBUTION_UNHELPED,
  } = useNotifications();
  const profile = useProfile();
  const data = profile?.data?.helpfulContributions;
  const [postUserHelpfulContribution, postHelpfulStatus] =
    usePostUserHelpfulContributionMutation();
  const [deleteUserHelpfulContribution, deleteHelpfulStatus] =
    useDeleteUserHelpfulContributionMutation();
  const isLoading =
    postHelpfulStatus.isLoading || deleteHelpfulStatus.isLoading;

  const getHelpfulTypeByContributionId = useCallback((cid) => {
    return data?.filter((item) => parseInt(item.id) === parseInt(cid))[0]
      ?.userHelpfulType;
  },[data]);

  const addHelpfulToContribution = async (contribution, helpful) => {
    const exists = getHelpfulTypeByContributionId(contribution?.id)
      ? true
      : false;
    try {
      await postUserHelpfulContribution({
        contribution,
        helpful,
        exists,
      }).unwrap();
      addToast({
        action: CONTRIBUTION_HELPED,
        payload: { contribution, helpful },
      });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  const deleteHelpfulFromContribution = async (contribution, helpful) => {
    try {
      await deleteUserHelpfulContribution({ contribution }).unwrap();
      addToast({
        action: CONTRIBUTION_UNHELPED,
        payload: { contribution, helpful },
      });
    } catch (error) {
      addToast({ action: GENERIC_ERROR, payload: error });
    }
  };

  const toggleHelpfulContribution = (contribution, helpful) => {
    const helpfulType = getHelpfulTypeByContributionId(contribution?.id);
    if (!helpfulType || helpfulType?.id !== helpful.id)
      addHelpfulToContribution(contribution, helpful);
    if (helpfulType?.id === helpful.id)
      deleteHelpfulFromContribution(contribution, helpful);
  };

  return {
    data,
    isLoading,
    getHelpfulTypeByContributionId,
    addHelpfulToContribution,
    deleteHelpfulFromContribution,
    toggleHelpfulContribution,
  };
};
