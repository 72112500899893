import { Button } from "@chakra-ui/react";

export const AvatarEditorButtonCancel = ({
  isLoading,
  handleCancelClick,
  file,
}) => (
  <Button
    disabled={isLoading || !file}
    variant="link"
    onClick={handleCancelClick}
  >
    Cancel
  </Button>
);
