import PropTypes from "prop-types";
import { ButtonBase } from "../ButtonBase/ButtonBase";

export const ButtonTertiary = (props) => (
  <ButtonBase
    bg="accenttwo.2100"
    color="white"
    spinnerColor="white"
    spinnerEmptyColor="rgba(255,255,255,0.5)"
    _hover={{ bg: "accenttwo.2000" }}
    _active={{ bg: "accenttwo.2200" }}
    {...props}
  />
);

ButtonTertiary.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingLabel: PropTypes.string,
  hasLoadingLabel: PropTypes.bool,
};
