import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import { LoaderSection, PageTitle, useProfile, useEthnicityList } from "../../";
import { FormControlSelect } from "../../../ui";

export const PageAccountEthnicity = () => {
  const { data, error, updateProfile, patchStatus } = useProfile();
  const ethnicities = useEthnicityList();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  useEffect(() => {
    if (data) {
      const { ethnicityId } = data;
      reset({
        ethnicityId: ethnicityId.toString(),
      });
    }
  }, [data, ethnicities.data, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Gender" />
      <LoaderSection
        loading={!data || !ethnicities.data}
        error={error || ethnicities.error}
      >
        <Box p="1.5rem">
          {data && data?.ethnicityId && (
            <form onSubmit={handleSubmit((data) => updateProfile(data))}>
              <Stack spacing="2rem">
                <Stack>
                  <FormControlSelect
                    {...register("ethnicityId", {
                      required: "Ethnicity is required",
                    })}
                    loading={ethnicities.isLoading}
                    error={errors.ethnicityId?.message}
                    isInvalid={errors.ethnicityId?.message}
                    label="Ethnicity"
                    defaultValue={data.ethnicityId}
                  >
                    <option value="" disabled>
                      Select ethnicity
                    </option>
                    {ethnicities.data?.map(({ id, title }, i) => (
                      <option key={i} value={id}>
                        {title}
                      </option>
                    ))}
                  </FormControlSelect>
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || patchStatus.isLoading}
                >
                  {patchStatus.isLoading && "Loading"}
                  {!isDirty && !patchStatus.isLoading && "Up to date"}
                  {isDirty && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
