import { Box } from "@chakra-ui/react";

export const LoadingPrimaryErrorWrapper = ({ children }) => (
  <Box
    flexGrow="1"
    w="full"
    d="flex"
    alignItems="center"
    justifyContent="center"
    px={4}
  >
    <Box maxW="md" mx="auto">
      {children}
    </Box>
  </Box>
);
