import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { CardContribution } from "../../Cards/CardContribution/CardContribution";

export const ListCardContributions = ({ data }) => (
  <Box flexGrow="1" bg="themegray.200">
    <Stack
      p="1.5rem"
      px="2rem"
      d="flex"
      flexDir="column"
      alignItems="center"
      spacing={6}
    >
      {data?.map((contribution) => (
        <CardContribution
          variant="nested"
          key={contribution?.id}
          data={contribution}
        />
      ))}
    </Stack>
  </Box>
);
