import React, { useEffect } from "react";
import { Box, Button, Stack, useBreakpointValue } from "@chakra-ui/react";
import {
  PageTitle,
  useProfile,
  LoaderSection,
  config,
  formValidation,
  validationDictionary,
} from "../../";
import { useForm } from "react-hook-form";
import { FormControlInputPassword } from "../../../ui";

export const PageAccountPassword = () => {
  const { data, isLoading, updateProfile, error, patchStatus } = useProfile();

  const variant = useBreakpointValue({
    lg: "desktop",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  const update = (data) => {
    updateProfile(data);
  };

  useEffect(() => {
    if (patchStatus.isSuccess) reset();
  }, [patchStatus.isSuccess, reset]);

  return (
    <Box d="flex" flexGrow="1" flexDir="column">
      <PageTitle goBack={variant !== "desktop"} title="Change password" />
      <LoaderSection loading={!data} error={error}>
        <Box p="1.5rem">
          {data && (
            <form onSubmit={handleSubmit(update)}>
              <Stack spacing="2rem">
                <Stack spacing={6}>
                  <FormControlInputPassword
                    {...register("oldpassword", {
                      required: validationDictionary.PASSWORD_REQUIRED,
                      validate: formValidation.isValidPassword,
                    })}
                    error={errors.oldpassword?.message}
                    isInvalid={errors.oldpassword?.message}
                    placeholder={`Min. ${config.minimumPasswordLength} characters`}
                    label="Old Password"
                  />
                  <FormControlInputPassword
                    {...register("password", {
                      required: validationDictionary.PASSWORD_REQUIRED,
                      validate: formValidation.isValidPassword,
                    })}
                    error={errors.password?.message}
                    isInvalid={errors.password?.message}
                    placeholder={`Min. ${config.minimumPasswordLength} characters`}
                    label="New Password"
                  />
                </Stack>
                <Button
                  alignSelf="center"
                  type="submit"
                  disabled={!isDirty || isLoading || patchStatus.isLoading}
                >
                  {(isLoading || patchStatus.isLoading) && "Loading"}
                  {!isDirty && "Up to date"}
                  {isDirty && !isLoading && !patchStatus.isLoading && "Save"}
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </LoaderSection>
    </Box>
  );
};
