import { useDispatch } from "react-redux";
import { HeroCall as HeroCallUi } from "../../../../../../../ui";
import { useCall, useVerifiedHandle } from "../../../../../../_Hooks";
import { modalOpen } from "../../../../../../_Redux/modalsSlice";

export const PageCallHero = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useCall();
  const { handleVerifiedClicked } = useVerifiedHandle();
  const handleVideoContributionClick = () =>
    dispatch(modalOpen({ modal: "modalVideoContribution", call: data }));

  const handleAudioContributionClick = () =>
    dispatch(modalOpen({ modal: "modalAudioContribution", call: data }));

  const handleTextContributionClick = () =>
    dispatch(modalOpen({ modal: "modalTextContribution", call: data }));

  if (isLoading) return null;

  return (
    <HeroCallUi
      {...data}
      {...{
        handleVerifiedClicked,
        handleVideoContributionClick,
        handleAudioContributionClick,
        handleTextContributionClick,
      }}
    />
  );
};
