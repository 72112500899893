import { HStack } from "@chakra-ui/react";
import {
  ContributeButtonBarButtonAudio,
  ContributeButtonBarButtonText,
  ContributeButtonBarButtonVideo,
  ContributeButtonBarTitle,
  ContributeButtonBarWrapper,
} from "./ui";

export const ContributeButtonBar = ({
  handleVideoContributionClick = () => {},
  handleAudioContributionClick = () => {},
  handleTextContributionClick = () => {},
}) => (
  <ContributeButtonBarWrapper>
    <ContributeButtonBarTitle />
    <HStack>
      <ContributeButtonBarButtonVideo {...{ handleVideoContributionClick }} />
      <ContributeButtonBarButtonText {...{ handleTextContributionClick }} />
      <ContributeButtonBarButtonAudio {...{ handleAudioContributionClick }} />
    </HStack>
  </ContributeButtonBarWrapper>
);
