import React, { useEffect, useState } from "react";
import { Box, Collapse } from "@chakra-ui/react";
import { SearchWithDropdown, NotificationGenericError } from "../..";
import { ListItemPersonSmall } from "../../../ui";
import { useLocation } from "react-router-dom";

export const AuthorSearchUi = ({ data, error, loading }) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState();
  const location = useLocation();

  const searchPeople = (data, str) => {
    return (
      data &&
      data.filter((person) => {
        const name = person.firstName + person.lastName;
        const formattedName = name.toLowerCase().replace(/ /g, "");
        const formattedString = str.toLowerCase().replace(/ /g, "");
        return formattedName.includes(formattedString);
      })
    );
  };

  useEffect(() => {
    if (data) {
      const d = [...data];
      const dataSortedAz = d?.sort((a, b) => {
        const x = a?.firstName.toLowerCase();
        const y = b?.firstName.toLowerCase();
        return x < y ? -1 : y < x ? 1 : 0;
      });
      if (search !== "") {
        const result = searchPeople(dataSortedAz, search);
        setResults(result);
      } else {
        setResults(dataSortedAz);
      }
    }
  }, [search, data]);

  useEffect(() => {
    setSearch("");
  }, [location]);

  return (
    <Box>
      <Collapse in={error && !loading}>
        <NotificationGenericError {...error} description={"Search bar"} />
      </Collapse>
      {(!error || loading) && (
        <SearchWithDropdown
          onChange={(e) => setSearch(e.target.value)}
          ListItem={(props) => <ListItemPersonSmall {...props} />}
          {...{ search, results, loading }}
        />
      )}
    </Box>
  );
};
