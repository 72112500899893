import { Box } from "@chakra-ui/react";

export const PageSignInWrapper = ({ children }) => (
  <Box
    flexGrow={1}
    d="flex"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
  >
    {children}
  </Box>
);
