import { Input, InputGroup } from "@chakra-ui/react";
import { forwardRef } from "react";

export const FormInput = forwardRef(
  ({ reverse, children, size, LeftAddon, ...props }, ref) => (
    <InputGroup {...{ size }}>
      {LeftAddon && <LeftAddon />}
      <Input
        bg="white"
        color="gray.600"
        // borderRadius="md"
        _invalid={{ borderColor: reverse ? "accentone.1500" : "red.500" }}
        {...props}
        {...{ ref }}
      />
      {children}
    </InputGroup>
  )
);
