import React from "react";
import { LayoutTwoColumn } from "../..";
import { PageProfileSidebar, PageProfileMainSection } from "./components";

export const PageProfile = () => (
  <LayoutTwoColumn>
    <PageProfileMainSection />
    <PageProfileSidebar />
  </LayoutTwoColumn>
);
