import {
  getUserFromSessionOrLocalStorage,
  transformAuthor,
  transformCall,
  transformContributionLight,
} from "../../_Utils";
import { postloginBaseParams } from "./config";

export const getAuthors = {
  query: () => ({
    url: "/Author",
    params: {
      ...postloginBaseParams,
      currentUserId: getUserFromSessionOrLocalStorage().id,
    },
  }),
  transformResponse: (res) => {
    console.log(res)
    return res
      ?.filter(
        (item) => !item?.role?.filter((role) => role.title === "Hidden")[0]
      )
      .map(transformAuthor);
  },
};

export const getAuthor = {
  query: (id) => ({
    url: `/Author/full/${id}`,
    params: {
      ...postloginBaseParams,
      currentUserId: getUserFromSessionOrLocalStorage().id,
    },
  }),
  transformResponse: (response) => {
    const author = transformAuthor(response[0]);
    return {
      ...author,
      calls: response[0]?.myCall?.map((item) => ({
        ...transformCall(item),
        author,
      })),
      contributions: response[0]?.myContribution?.map((item) => ({
        ...transformContributionLight(item),
        author,
      })),
    };
  },
};
