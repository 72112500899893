import { motion } from "framer-motion";

export const ButtonHelpfulTriggerMotion = ({ children, helpfulType }) => (
  <motion.div
    initial={{ opacity: 0, transform: "scale(0)" }}
    animate={{
      opacity: 1,
      transform: "scale(1)",
      transition: {
        type: "spring",
        damping: 4,
        mass: 0.2,
        stiffness: 100,
      },
    }}
    key={helpfulType?.id || 0}
  >
    {children}
  </motion.div>
);
