import { RootBase, store, theme } from "./components";
import { Provider } from "react-redux";
import { ChakraProvider } from "@chakra-ui/react";

import "@fontsource/raleway/300.css";
import "@fontsource/raleway/500.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/800.css";

import "video.js/dist/video-js.css";
import "@videojs/themes/dist/fantasy/index.css";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <RootBase />
        </Router>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
