import { FormControl, FormLabel } from "@chakra-ui/react";
import { forwardRef } from "react";
import { FormError, FormInputPassword } from "../../../1_Atoms";

export const FormControlInputPassword = forwardRef(
  ({ label, reverse, error, size, isInvalid, ...props }, ref) => {
    const errorColor = reverse ? "accentone.1500" : "red.500";
    return (
      <FormControl textAlign="left" {...{ isInvalid }}>
        <FormLabel
          mb={1}
          fontSize="sm"
          fontWeight="body.bold"
          color={reverse ? "white" : "inherit"}
        >
          {label}
        </FormLabel>
        <FormInputPassword {...props} {...{ ref, reverse, size }} />
        {error && <FormError color={errorColor} {...{ error }} />}
      </FormControl>
    );
  }
);
