import { Stack, Grid } from "@chakra-ui/react";
import { CardAuthorPrimary } from "../../../../../ui";
import { useVerifiedHandle } from "../../../../_Hooks";
import { AmbassadorListText, AmbassadorListTitle } from "./ui";

export const AmbassadorsList = ({ groupTitle, ambassadors }) => {
  const { handleVerifiedClicked } = useVerifiedHandle();
  return (
    <Stack spacing={5}>
      <Stack>
        <AmbassadorListTitle {...{ groupTitle }} />
        <AmbassadorListText />
      </Stack>
      <Grid
        templateColumns={{ sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        gap="1rem"
      >
        {ambassadors.map((author) => (
          <CardAuthorPrimary
            key={author.id}
            {...author}
            handleVerifiedClicked={(e) => {
              e.preventDefault();
              handleVerifiedClicked(author?.verified);
            }}
          />
        ))}
      </Grid>
    </Stack>
  );
};
