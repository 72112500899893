import React from "react";
import { Box } from "@chakra-ui/react";

export const IllustrationHeart = (props) => (
  <Box as="svg" viewBox="0 0 56.9 56.9" {...props}>
    <g id="b">
        <g id="c">
            <g>
                <g>
                    <path className="f" fill='#bc1822' d="M28.91,7.34c-.5,0-.9-.4-.9-.9V3.12c0-.5,.4-.9,.9-.9s.9,.4,.9,.9v3.31c0,.5-.4,.9-.9,.9Z" />
                    <path className="f" fill='#bc1822' d="M13.21,13.46c-.23,0-.46-.09-.64-.26l-2.34-2.34c-.35-.35-.35-.92,0-1.28,.35-.35,.92-.35,1.28,0l2.34,2.34c.35,.35,.35,.92,0,1.28-.18,.18-.41,.26-.64,.26Z" />
                    <path className="f" fill='#bc1822' d="M10.21,46.93c-.23,0-.46-.09-.64-.26-.35-.35-.35-.92,0-1.28l2.34-2.34c.35-.35,.92-.35,1.28,0,.35,.35,.35,.92,0,1.28l-2.34,2.34c-.18,.18-.41,.26-.64,.26Z" />
                    <path className="f" fill='#bc1822' d="M27.99,54.68c-.5,0-.9-.4-.9-.9v-3.31c0-.5,.4-.9,.9-.9s.9,.4,.9,.9v3.31c0,.5-.4,.9-.9,.9Z" />
                    <path className="f" fill='#bc1822' d="M46.03,47.59c-.23,0-.46-.09-.64-.26l-2.34-2.34c-.35-.35-.35-.92,0-1.28,.35-.35,.92-.35,1.28,0l2.34,2.34c.35,.35,.35,.92,0,1.28-.18,.18-.41,.26-.64,.26Z" />
                    <path className="f" fill='#bc1822' d="M44.35,14.11c-.23,0-.46-.09-.64-.26-.35-.35-.35-.92,0-1.28l2.34-2.34c.35-.35,.92-.35,1.28,0,.35,.35,.35,.92,0,1.28l-2.34,2.34c-.18,.18-.41,.26-.64,.26Z" />
                    <path className="e" fill='#ff7171' d="M44.57,25c-.25-4.25-3.68-7.72-7.93-8.01-3.23-.22-6.1,1.35-7.73,3.82-1.63-2.47-4.5-4.04-7.73-3.82-4.24,.29-7.68,3.76-7.93,8.01-.16,2.71,.96,5.16,2.79,6.83h0l12.43,11.95c.24,.24,.63,.24,.88,0l12.43-11.95h0c1.84-1.67,2.95-4.12,2.79-6.84" />
                    <path className="f" fill='#bc1822' d="M28.91,44.87c-.38,0-.77-.14-1.06-.43l-13.01-12.5h.01c-1.75-1.9-2.65-4.4-2.5-6.99,.28-4.73,4.05-8.54,8.77-8.86,2.98-.2,5.86,1.02,7.79,3.23,1.93-2.22,4.81-3.44,7.79-3.23,4.72,.32,8.49,4.13,8.77,8.86h0c.15,2.57-.74,5.05-2.46,6.93h.01l-.62,.61-12.43,11.95c-.3,.29-.68,.43-1.06,.43Zm-11.67-13.12l11.67,11.21,11.62-11.17-.02-.02,.65-.6c1.72-1.57,2.64-3.79,2.5-6.11h0c-.23-3.82-3.27-6.9-7.09-7.16-2.75-.21-5.4,1.12-6.92,3.42l-.75,1.14-.75-1.14c-1.52-2.3-4.17-3.62-6.92-3.42-3.82,.26-6.86,3.34-7.09,7.16-.14,2.32,.78,4.54,2.5,6.11l.61,.55-.02,.02Z" />
                </g>
                <rect className="d" fill='none' width="56.9" height="56.9" />
            </g>
        </g>
    </g>
  </Box>
);
