import { useGetStateListQuery } from "../_Service";
import { getItemFromArrayById } from "../_Utils";
import { useBaseApi } from "./useBaseApi";

export const useStateList = () => {
  const { data, ...rest } = useBaseApi({ query: useGetStateListQuery });
  const getStateById = (id) => getItemFromArrayById(id, data);

  return {
    data,
    ...rest,
    getStateById,
  };
};
