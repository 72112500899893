import { setCredentials } from "../../_Redux/authSlice";
import { preloginBaseParams } from "./config";

export const getUserLogin = {
  query: ({ username, password }) => ({
    url: "User",
    params: preloginBaseParams,
    headers: { username, password },
  }),
  async onQueryStarted(_, { dispatch, queryFulfilled }) {
    try {
      const { data } = await queryFulfilled;
      dispatch(setCredentials(data));
    } catch (error) {
      return error;
    }
  },
};

export const postUserSignUp = {
  query: (values) => ({
    url: "/User",
    params: preloginBaseParams,
    body: values,
    method: "POST",
  }),
};
