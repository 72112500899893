import { Box } from "@chakra-ui/react";

export const VideoContainer = ({ children, ...props }) => (
  <Box
    bg="themegray.3600"
    borderRadius="1rem"
    pos="relative"
    flexGrow="1"
    w="100%"
    d="flex"
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
    flexDir="column"
    {...props}
  >
    {children}
  </Box>
);
