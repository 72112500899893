import React from "react";
import { Box, Heading, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

export const PageTitle = ({ title, goBack, to = ".." }) => (
  <Box
    p="0.8rem 1.5rem"
    bg="white"
    borderBottom="solid 1px"
    borderBottomColor="gray.200"
    alignItems="center"
    d="flex"
  >
    {goBack && (
      <Box as={Link} {...{ to }} d="flex" mr="0.5rem">
        <Icon as={FaArrowLeft} />
      </Box>
    )}
    <Heading fontSize="1.4rem">{title}</Heading>
  </Box>
);
