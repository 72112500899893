import { CardAuthor, ContributeButtonBar } from "../../../2_Molecules";
import { HeroCallVideoAndBadge } from "./components";
import { HeroCallBody, HeroCallTitle, HeroCallWrapper } from "./ui";

export const HeroCall = ({
  title,
  author,
  video,
  text,
  handleVerifiedClicked = () => {},
  handleVideoContributionClick = () => {},
  handleAudioContributionClick = () => {},
  handleTextContributionClick = () => {},
}) => (
  <HeroCallWrapper>
    <HeroCallVideoAndBadge {...{ video }} />
    <HeroCallBody>
      <CardAuthor {...author} {...{ handleVerifiedClicked }} />
      <HeroCallTitle {...{ title }} />
      {text && <div>{text}</div>}
      <ContributeButtonBar
        {...{
          handleVideoContributionClick,
          handleAudioContributionClick,
          handleTextContributionClick,
        }}
      />
    </HeroCallBody>
  </HeroCallWrapper>
);
