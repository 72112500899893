import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormCheckbox, FormControlInput } from "../../../ui";
import { useSignUp } from "../../_Hooks";
import { ButtonBack } from "./ButtonBack";
import { ButtonNext } from "./ButtonNext";

export const SignUpStepTwo = ({ onPrevClicked, onNextClicked, active }) => {
  const signUp = useSignUp();

  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    signUp.updateValues(data);
    onNextClicked();
  };

  useEffect(() => {
    if (signUp.values) {
      const { firstName, lastName, onlyDisplayInitials, dob } = signUp.values;
      reset({
        firstName,
        lastName,
        onlyDisplayInitials,
        dob,
      });
    }
  }, [signUp.values, reset]);

  return (
    <Box color="white">
      <Heading mb="2rem">Personal details</Heading>
      <form style={{ textAlign: "left" }} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <FormControlInput
            {...register("firstName", {
              required: "First name is required",
            })}
            disabled={!active}
            error={errors.firstName?.message}
            isInvalid={errors.firstName?.message ? true : false}
            placeholder="First Name"
            label="First Name"
            reverse
          />
          <FormControlInput
            {...register("lastName", {
              required: "Last name is required",
            })}
            disabled={!active}
            error={errors.lastName?.message}
            isInvalid={errors.firstName?.message ? true : false}
            placeholder="Last Name"
            label="Last Name"
            reverse
          />
          <div>
            <Text
              mb={2}
              textAlign="left"
              fontSize="0.8rem"
              fontWeight="body.bold"
            >
              Hide my name
            </Text>

            <Controller
              name="onlyDisplayInitials"
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  isDisabled={!active}
                  {...field}
                  textAlign="left"
                  checked={field.value}
                >
                  Only display my initials
                </FormCheckbox>
              )}
            />
          </div>

          <Flex pt="1rem" justifyContent="center">
            <ButtonBack disabled={!active} onClick={onPrevClicked} />
            <ButtonNext disabled={!active} type="submit" />
          </Flex>
        </Stack>
      </form>
    </Box>
  );
};
