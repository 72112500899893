import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  audioDeviceId: "",
  videoDeviceId: "",
  recordReady: false,
  mirrored: true,
  devices: null,
};

export const videoConfigSlice = createSlice({
  name: "videoconfig",
  initialState,
  reducers: {
    setAudioDeviceId: (state, action) => {
      return { ...state, audioDeviceId: action.payload };
    },
    setVideoDeviceId: (state, action) => {
      return { ...state, videoDeviceId: action.payload };
    },
    setDevices: (state, action) => {
      return { ...state, devices: action.payload };
    },
    setMirrored: (state, action) => {
      return { ...state, mirrored: action.payload };
    },
    setRecordReady: (state, action) => {
      return { ...state, recordReady: action.payload };
    },
  },
});

export const {
  setAudioDeviceId,
  setVideoDeviceId,
  setDevices,
  setMirrored,
  setRecordReady,
} = videoConfigSlice.actions;

export default videoConfigSlice.reducer;
