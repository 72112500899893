import PropTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import {
  ButtonBaseSpinnerWrapper,
  ButtonBaseChildrenWrapper,
  ButtonBaseSpinner,
  ButtonBaseWrapper,
} from "./ui";
import { Box } from "@chakra-ui/react";

export const ButtonBase = ({
  size,
  children,
  isLoading,
  spinnerColor,
  spinnerEmptyColor,
  hasLoadingLabel,
  loadingLabel,
  ...props
}) => {
  return (
    <ButtonBaseWrapper {...{ size, isLoading }} {...props}>
      <ButtonBaseChildrenWrapper {...{ isLoading }}>
        {children}
      </ButtonBaseChildrenWrapper>
      <AnimatePresence>
        {isLoading && (
          <ButtonBaseSpinnerWrapper>
            <ButtonBaseSpinner {...{ spinnerColor, spinnerEmptyColor, size }} />
            {hasLoadingLabel && <Box ml={2}>{loadingLabel}</Box>}
          </ButtonBaseSpinnerWrapper>
        )}
      </AnimatePresence>
    </ButtonBaseWrapper>
  );
};

ButtonBase.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]).isRequired,
  spinnerColor: PropTypes.string.isRequired,
  spinnerEmptyColor: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hasLoadingLabel: PropTypes.bool,
  loadingLabel: PropTypes.string,
};

ButtonBase.defaultProps = {
  size: "md",
  spinnerColor: "gray.500",
  spinnerEmptyColor: "gray.300",
  hasLoadingLabel: true,
  loadingLabel: "Loading...",
};
