import { IllustrationEmptyStateFollowing } from "../../";
import { Heading, Stack, Text } from "@chakra-ui/react";

export const EmptyStatePeopleFollowing = () => (
  <>
    <IllustrationEmptyStateFollowing
      w="90%"
      maxW={{ base: "150px", sm: "200px", lg: "280px" }}
    />
    <Stack textAlign="center" alignItems="center" spacing="0.2rem">
      <Heading fontSize={{ base: "1rem", md: "1.2rem" }}>
        You&#8217;re not following anyone
      </Heading>
      <Text fontSize={{ base: "0.9rem", md: "1rem" }}>
        Follow people to stay up to date!
      </Text>
    </Stack>
  </>
);
