export const navData = [
  {
    type: "internalLink",
    name: "My Account",
    description: "Edit and update your account",
    to: "/my-account",
  },
  {
    type: "internalLink",
    name: "Contact Us",
    description: "Contact us to raise any techinical issues",
    to: "/contact",
  },
  {
    type: "externalLink",
    name: "About Colloquy",
    description: "More about who we are and what we do",
    href: "https://www.colloquy.health",
    target: "blank",
  },
  {
    type: "internalLink",
    name: "Our Ambassadors",
    description: "Facilitate exchanges that support you",
    to: "/ambassadors",
  },
];
