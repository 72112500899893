import { Button } from "@chakra-ui/react";
import { forwardRef } from "react";

export const ButtonHelpfulTriggerWrapper = forwardRef(
  ({ children, ...props }, ref) => (
    <Button
      d="flex"
      alignItems="center"
      justifyContent="center"
      bg="themegray.200"
      h="35px"
      size='sm'
      _hover={{ bg: "themegray.500" }}
      _active={{ bg: "themegray.600" }}
      _focus={{}}
      {...props}
      {...{ ref }}
    >
      {children}
    </Button>
  )
);
